<script setup>
import {  ref  } from 'vue';
import { useTriadStore }    from '@/user/triad-store';
import { useRoute, useRouter } from 'vue-router';

const route           = useRoute();
const router          = useRouter();
const triadStore      = useTriadStore();
const viewByFlights           = ref(0);



const getAdgroupTotalBudget = (adgroupIndex) =>{
  let totalBudget = 0;
  for(let i= 0; i < triadStore.currentCampaign.flights.length; i++ ){
    if(triadStore.currentCampaign.flights[i].budget[adgroupIndex] >0 ){
      totalBudget = totalBudget*1 + triadStore.currentCampaign.flights[i].budget[adgroupIndex]*1;
    }
  }
  return totalBudget;
}

const back = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_three' });
  }, 1000);
}

const saveStepFour  = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_five' });
  }, 1000);
}


</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->

 

    <!-- STEP 4: Approve Your Campaign -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Approve Your Campaign</h2>
      </div>
      <div class="ml-auto flex items-start gap-10">
        <div class="relative">
          <input type="text" placeholder="Campaign Name" class="w-96" v-model="triadStore.currentCampaign.name">
          <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
            <i class="fa-sharp fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
      <div class="flex mt-6 gap-5">
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Avg Frequency
          </div>
          <div class="px-5 text-center grow">8</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Est. Impressions
          </div>
          <div class="px-5 text-center grow">545,420</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Campaign Dates
          </div>
          <div class="px-5 text-center grow">{{ triadStore.currentCampaign.startDate }} - {{ triadStore.currentCampaign.endDate }} </div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Total Campaign Budget
          </div>
          <div class="px-5 text-center grow">{{ triadStore.currentCampaign.budget }}</div>
        </div>
      </div>
        <!-- Botones para cambiar de vistas en las tablas de abajo. Lo que cambian son las clases "bg-white" / "bg-light" cuando estén activos -->
        <div class="flex mt-6 text-sm ">
        <button class="button-secondary "  :class="{'bg-light': !viewByFlights}" @click.prevent="viewByFlights=0">
           View Ad Groups
        </button>
        <button class="button-secondary " :class="{'bg-light': viewByFlights}"  @click.prevent="viewByFlights=1" >
           View Flights
        </button> 
      
      </div>
      
      <!--view Adgroups BY Flights in ROWS-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="viewByFlights" >
        <div  v-for="(flight, index) in triadStore.currentCampaign.flights" :key="index" >
          <div class="table-container" style="margin-top:30px;">
            <table>
            <tr class="thead-tr">
              <th></th>
            <th></th>
              <th class="">Start Date</th>
              <th class="">End Date</th>
              <th class="">Budget</th>
            </tr>
            <tbody >
              <tr class="tbody-tr" >
                <td>
                  <span v-if="index> 0">
                    
                  </span>
                </td>
                <td><button @click.prevent="triadStore.currentCampaign.flightIndex = index">View Ad Groups</button></td>
                <td>
                  {{ flight.startDate }}
                </td>
                <td>
                  {{ flight.endDate }}
                </td>
                <td>
                  {{ flight.totalBudget }}
               
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <div class="table-container" style="padding:10px 30px;" :class="{hidden:index != triadStore.currentCampaign.flightIndex}">
                    <table>
                      <tr class="thead-tr">
                        <th></th>
                        <th class="w-1/12">Ad Group </th>
                        <th class="w-3/12">Audience</th>
                        <th class="w-4/12">Medium</th>
                        <th class="w-1/12">Impressions</th>
                        <th class="w-1/12">Budget <sup>
                          <i class="fa-solid fa-circle-question fa-xs"></i></sup>
                        </th>
                      </tr>
                      <tbody  v-for="(adgroup, adgroupIndex) in triadStore.currentCampaign.adgroups" :key="adgroupIndex" >
                        <tr class="tbody-tr" v-if="flight.adgroups.includes(adgroupIndex)" >
                          <td> 
                           
                          </td>
                          <td>
                            {{ adgroup.name }}
                          </td>
                          <td>
                            {{ adgroup.audience }}
                          </td>
                          
                          <td>{{adgroup.medium}}</td>
                          <td>
                            {{adgroup.impressions}}
                          </td>
                          <td>
                              <span>
                                {{ flight.budget[adgroupIndex] }}
                              </span>
                          </td>
                        </tr>
                        
                      </tbody>
                      
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
        </div>
        
      </div>

      <!-- View by Adgroups-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="!viewByFlights">

        <!-- Tabalas de abajo -->
        <div class="table-container">
          <table>
          <tr class="thead-tr">
            <th></th>
            <th class="w-1/12">Ad Group</th>
            <th class="w-3/12">Audience</th>
            <th class="w-2/12">Flights</th>
            <th class="w-4/12">Medium</th>
            <th class="w-4/12">Creative(s)</th>
            <th class="w-1/12">Impressions</th>
            <th class="w-1/12">Budget <sup>
              <i class="fa-solid fa-circle-question fa-xs"></i></sup>
            </th>
          </tr>
          <tr class="tbody-tr" v-for="(adgroup, adgroupIndex) in triadStore.currentCampaign.adgroups" :key="adgroupIndex">
            <td> 
               
            </td>
            <td>
             {{ adgroup.name }}
            </td>
            <td>
              {{ adgroup.audience }}
            </td>
            <td>
                <div v-for="(flight, findex) in triadStore.currentCampaign.flights" :key="findex">
                    <div v-if="flight.adgroups.includes(adgroupIndex)">
                        {{ flight.startDate  }} - {{ flight.endDate }}
                    </div>
                </div>
            </td>
            
            <td>
              {{ adgroup.medium }}
            </td>
            <td>
              <div class="mb-1 last-of-type:mb-2 " v-for="(creative, cindex) in adgroup.creatives" :key="creative.id">
               
                  {{ creative.name }}
               
              </div>
             
            
            </td>
            <td>
            650,000
            </td>
            <td>
             
                {{ getAdgroupTotalBudget(adgroupIndex) }}
              
            </td>
          </tr>
        </table>
        </div>
       
      </div>
      

      <button class="button-primary fixed bottom-0 left-0 " @click.prevent="back">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepFour">Next <i class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 4 -->


  </div>

  <!-- MODAL EXIT BUILDER -->
  <div id="modal-delete-user" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center justify-center mt-10 mb-4 gap-5 text-2xl">
        <i class="fa-solid fa-triangle-exclamation fa-2xl"></i>
        <div>
          <h2 class="text-xl lg:text-2xl font-bold">Are you sure you want to exit the builder?</h2>
          <p class="text-base">Don't forget to save your changes before you exit so you can come back to your draft later.
          </p>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Back to Builder
        </button>
        <button class="button-primary">
          Save Draft
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EXIT BUILDER -->


</template>

<style></style>
