<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/user/stores-user';

const emit = defineEmits(['message', 'loading']);
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

const newPassword = ref('');
const newPasswordConfirm = ref('');

const isValidURL = computed(() => route.query.username && route.query.key);

/**
 * Submit reset password request
 */
const resetPassword = async () => {
  emit('loading', true);
  const response = await userStore.resetPassword(
    newPassword.value,
    newPasswordConfirm.value,
  );
  emit('loading', false);

  switch (response.status) {
    // if working
    case 200:
      newPassword.value = '';
      newPasswordConfirm.value = '';

      router.push({
        name: 'login',
        query: {
          message: response.message,
          messageType: 'good',
        },
      });
      break;

    // if error
    case 403:
    case 500:
    default:
      emit('message', response.message);
  }
};
</script>

<template>
  <section v-if="isValidURL">
    <form
      class="user-form"
      @submit.prevent="resetPassword"
    >
      <p>
        Enter a new password for your account:
      </p>
      <label class="user-form__field">
        <span>New Password</span>
        <input
          v-model="newPassword"
          type="password"
        >
      </label>
      <label class="user-form__field">
        <span>New Password Confirm</span>
        <input
          v-model="newPasswordConfirm"
          type="password"
        >
      </label>

      <div class="user-form__submit">
        <button type="submit">
          Reset Password
        </button>
      </div>
    </form>

    <form @submit.prevent="login" class="w-1/2 h-full flex flex-col gap-6 justify-center items-center relative">
      <div class="max-w-xs flex flex-col gap-8 -mt-4">
        <div class="">
          <h2 class="text-3xl font-blold mb-1">Reset Your Password</h2>
          <p>Found your password? <a class="font-bold text-primary">Login</a></p>
        </div>
        <label>
          <span>Username or Email Address</span>
          <input type="text" v-model="email" /></label>
        <button type="submit" class="button-primary max-w-fit mt-2">Reset password</button>
      </div>
      <div class="absolute left-0 bottom-0 px-6 py-6 text-xs">
        Copyright &#169; 2023 TriAD CTV<sup>&#174;</sup>, Inc. All rights reserved. <br>
        <a href="" class="font-bold text-primary">Terms & Conditions</a> | <a href="" class="font-bold text-primary">Privacy Policy</a>
      </div>
    </form>
    <footer class="form-links">
      <router-link :to="{ name: 'passwordForgot' }">
        Back to Forgot Password
      </router-link>
    </footer>
  </section>
  <section v-else>
    <div class="user-form has-text-align-center">
      <p>
        The link you followed has already expired. Please fill the Forgot Password form again.
      </p>
      <p>
        <router-link class="button" :to="{ name: 'passwordForgot' }">
          Back to Forgot Password
        </router-link>
      </p>
    </div>
  </section>
</template>

<style scoped>

</style>
