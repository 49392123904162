<script setup>

</script>

<template>
  <div id="billing-tabs" class="tabs">
    <div class="tab-triggers-container">
      <nav class="tab-triggers-nav">
        <RouterLink to="/billing" class="tab-trigger">
          Transaction History
        </RouterLink>
        <a href="#"  aria-selected="true" class="tab-trigger">
          Payment Methods
        </a>
        <RouterLink to="/billing-profiles" class="tab-trigger">
          Billing Profiles
        </RouterLink>
      </nav>
    </div>
  
    <div id="payment-methods" aria-expanded="true" class="">
      <div class="table-container">
        <table>
          <tr class="thead-tr">
            <th class="w-10/12">
              Payment Method
            </th>
            <th class="w-2/12 text-center">
              Expires
            </th>
          </tr>
          <tr class="tbody-tr">
            <td>
              <span class="block">American Express (XXXXXXX1059)</span>
              <a href="#" class="text-xs font-semibold">Default</a>
              <span class="px-2 text-xs inline-block">|</span>
              <a href="#" class="uppercase text-primary text-xs font-bold"
                onclick="event.preventDefault(); getElementById('modal-delete-payment-method').classList.toggle('hidden');document.body.classList.toggle('overflow-hidden');">Delete</a>
            </td>
            <td class="text-center">
              11/25
            </td>
          </tr>
          <tr class="tbody-tr">
            <td>
              <span class="block">American Express (XXXXXXX1099)</span>
              <a href="#" class="uppercase text-primary text-xs font-bold">Make Default</a>
              <span class="px-2 text-xs inline-block align-text-top opacity-50">|</span>
              <a href="#" class="uppercase text-primary text-xs font-bold"
                onclick="event.preventDefault(); getElementById('modal-delete-payment-method').classList.toggle('hidden');document.body.classList.toggle('overflow-hidden');">Delete</a>
            </td>
            <td class="text-center">
              12/26
            </td>
          </tr>
        </table>
      </div>
      <button class="bg-primary uppercase text-white px-2 lg:px-8 py-3 font-bold mx-auto block mt-10">Add New Payment
        Method</button>
    </div>

  </div>
  <div id="modal-delete-payment-method" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal"
        onclick="this.closest('.modal').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-2xl mt-10 font-bold">
        <i class="fa-solid fa-triangle-exclamation fa-2xl mr-4"></i>
        Are you sure you want to delete this payment method?
      </h2>
      <div class="modal-actions">
        <button class="button-secondary"
          onclick="this.closest('.modal').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
          Nevermind
        </button>
        <button class="button-primary">
          Delete
        </button>
      </div>
    </div>
  </div>

</template>
<style></style>