<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { api } from '../helpers';
import { useTriadStore }    from '@/user/triad-store';

const triadStore      = useTriadStore();
const showSavedModal  = ref(0);
const hideCreateUser  = ref(true);
const hideEditUser    = ref(true);
const newUser         = ref({advertisers:[]});
const editUser        = ref({});
const formChanged     = ref(0);
const editFormChanged = ref(0);
const search          = ref('');
const accountUsers    = ref([]);

const userId          = localStorage.getItem('userId');
const currentUser     = ref([]);

const filteredUsers   = computed(() => {
  return accountUsers.value.filter((val) => { 
    return val.first_name.toString().toLowerCase().includes(search.value) || val.email.toString().toLowerCase().includes(search.value)
  })
}); 

const filteredAdvertisers   = computed(() => {
  return triadStore.advertisers.sort((a,b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1);
}); 

const selectAll   = () =>{
  newUser.value.advertisers = [];
  triadStore.advertisers.forEach(element => {
    newUser.value.advertisers.push(element.id )
  });
};

const unselectAll   = () =>{
  newUser.value.advertisers = [];
};

const getAdvertiserNameById = (advertiser) =>{
  let name = '';
  triadStore.advertisers.forEach(element => {
    if(element.id == advertiser){
      name = element.name 
    }
  });
  return name+' | ';
};

const deleteUser      = async () => {
  try {
    document.body.classList.add("loading");
      const response  = await api.get('/remove_user_from_account?account_id=' + triadStore.currentAccount.id + '&user_id=' + currentUser.value.id);
      const response3 = await api.get('/get_account_users?account_id=' + triadStore.currentAccount.id + '&user_id=' + userId);
    document.body.classList.remove("loading");

    accountUsers.value = response3.data.data;
    clearCurrentUser();
    showSavedModal.value = 0;
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const createUser = async  () => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/save_new_user', { 
      'first_name'  : newUser.value.first_name, 
      'last_name'   : newUser.value.last_name, 
      'email'       : newUser.value.email, 
      'phone'       : newUser.value.phone, 
      'advertisers' : newUser.value.advertisers, 
      'account_id'  : triadStore.currentAccount.id 
    });
    document.body.classList.remove("loading");
    hideCreateUser.value = true;

    document.body.classList.add("loading");
      const response2       = await api.get('/get_account_users?account_id=' + triadStore.currentAccount.id + '&user_id=' + userId);
      accountUsers.value    = response2.data.data;
    document.body.classList.remove("loading");
    
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
      };
  }
}

const saveEditUser = async  () => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/save_edit_user', { 
      'id'          : editUser.value.id, 
      'first_name'  : editUser.value.first_name, 
      'last_name'   : editUser.value.last_name, 
      'email'       : editUser.value.email, 
      'phone'       : editUser.value.phone, 
      'advertisers' : editUser.value.advertisers, 
      'account_id'  : triadStore.currentAccount.id
    });
    document.body.classList.remove("loading");
    hideEditUser.value = true;
    
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
      };
  }
}
const clearCurrentUser = () => { currentUser.value = []; editUser.value = {}; }

onMounted(async () => {
  document.body.classList.add("loading");
    const response      = await api.get('/get_account_users?account_id=' + triadStore.currentAccount.id + '&user_id=' + userId);
    accountUsers.value  = response.data.data;
  document.body.classList.remove("loading");
 

  watch(newUser, (value) => {
      formChanged.value = 1;
    }, { deep: true }
  );
  watch(editUser, (value) => {
      editFormChanged.value = 1;
    }, { deep: true }
  );


});

</script>
<template>
  <div class="tabs profile">
    <div class="tab-triggers-container">
      <nav class="tab-triggers-nav">
        <a href="/profile" aria-selected="false" class="tab-trigger">My Profile</a>
        <a href="#" aria-selected="true" class="tab-trigger">User Management</a>
        <a href="/account-settings" aria-selected="false" class="tab-trigger">Account
          Settings</a>
      </nav>
    </div>
    <div>
      <form class="w-full flex flex-col md:flex-row gap-4 lg:gap-5 mb-6 md:mb-5">
        <!--
        <label class="shrink-0 w-2/12">
          <select>
            <option value="value1">Role: All</option>
            <option value="value2">Value 2</option>
            <option value="value3">Value 3</option>
          </select>
        </label>
        <label class="shrink-0 w-2/12">
          <select>
            <option value="value1">Advertisers: All</option>
            <option value="value2">Value 2</option>
            <option value="value3">Value 3</option>
          </select>
        </label>
        -->
        <label class="relative grow">
          <input placeholder="Search" type="text" v-model="search" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            class="absolute w-4 h-full top-0 right-3 object-contain object-center fill-primary">
            <path
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
            </path>
          </svg>
        </label>
        <button class="button-primary" @click.prevent="hideCreateUser = false">
          Add New User
        </button>
      </form>
      <div class="table-container"  :class="{ hidden: !hideCreateUser || !hideEditUser  }">
        <table>
          <tr class="thead-tr">
            <th class="w-2/12">
              First Name 
            </th>
            <th class="w-2/12">
              Last Name 
            </th>
            <th class="w-3/12">
              Email
            </th>
            <th class="w-2/12">
              User Role
            </th>
            <th class="w-3/12">
              Advertisers
            </th>
          </tr>
          <tr class="tbody-tr" v-for="user in filteredUsers" :key="user.id" >
            <td>
              <span class="block">{{ user.first_name }}</span>
              <button class="table-action" @click.prevent="editUser = user; hideEditUser = false">Edit</button>
              <button class="table-action" @click.prevent="currentUser = user; showSavedModal = 1">Delete</button>
            </td>
            <td>
              <span class="block">{{ user.last_name }}</span>
            </td>
            <td>
              <span>{{ user.email }}</span>
            </td>
            <td>
              <span>Admin</span>
            </td>
            <td>
              <span v-for="advertiser in user.advertisers" :key="advertiser.id"> 
                {{ getAdvertiserNameById(advertiser) }}  
              </span> 
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div id="edit-user"  class="overflow-x-auto pb-6" :class="{ hidden: hideEditUser }">
    <div class="grid lg:grid-cols-2 gap-6 lg:gap-x-5 lg:gap-y-4 max-w-3xl mx-auto mt-2">
      <h2 class="text-center text-lg lg:text-3xl font-bold mb-7 col-span-2">Edit User</h2>
      <label class="font-bold col-span-2 -mb-2">Name <sup class="text-primary font-normal">*</sup></label>
      <label>
        <input type="text" v-model="editUser.first_name">
        <small class="mt-1 text-xs">First Name</small>
      </label>
      <label>
        <input type="text" v-model="editUser.last_name">
        <small class="mt-1 text-xs">Last Name</small>
      </label>
      <label>
        <span>Email <sup>*</sup></span>
        <input type="text" class="shadow-md w-full p-3 mt-2" v-model="editUser.email">
      </label>
      <label>
        <span>Phone <sup>*</sup></span>
        <input type="text" class="shadow-md w-full p-3 mt-2"  v-model="editUser.phone">
      </label>
      <fieldset class="col-span-2">
        <legend class="mb-3 font-bold pt-3">
          Which advertisers would you like to allow this user to access? <sup class="text-primary font-normal">*</sup>
        </legend>
        <div class="grid grid-cols-3 gap-1">
          <label v-for="advertiser in filteredAdvertisers">
            <input class="checkbox-sm" type="checkbox" id="" name="" :value="advertiser.id" v-model="editUser.advertisers">
           {{advertiser.name}}
          </label>
        
        </div>
      </fieldset>
      <div class="lg:col-span-2 text-center mt-16">
        <button class="button-secondary" @click="editUser = []; hideEditUser= true;">
          Discard
        </button>
        <button class="button-primary" @click.prevent="saveEditUser" :disabled="!editFormChanged">
          Save
        </button>
      </div>
    </div>
  </div>


  <div id="new-user"  class="overflow-x-auto pb-6" :class="{ hidden: hideCreateUser }">
    <div class="grid lg:grid-cols-2 gap-6 lg:gap-x-5 lg:gap-y-4 max-w-3xl mx-auto mt-2">
      <h2 class="text-center text-lg lg:text-3xl font-bold mb-7 col-span-2">Create User</h2>
      <label class="font-bold col-span-2 -mb-2">Name <sup class="text-primary font-normal">*</sup></label>
      <label>
        <input type="text" v-model="newUser.first_name">
        <small class="mt-1 text-xs">First Name</small>
      </label>
      <label>
        <input type="text" v-model="newUser.last_name">
        <small class="mt-1 text-xs">Last Name</small>
      </label>
      <label>
        <span>Email <sup>*</sup></span>
        <input type="text" class="shadow-md w-full p-3 mt-2" v-model="newUser.email">
      </label>
      <label>
        <span>Phone <sup>*</sup></span>
        <input type="text" class="shadow-md w-full p-3 mt-2"  v-model="newUser.phone">
      </label>
      <fieldset class="col-span-2">
        <legend class="mb-3 font-bold pt-3">
          Which advertisers would you like to allow this user to access? <sup class="text-primary font-normal">*</sup>
        </legend>
        <button @click.prevent="selectAll();" class="button-primary">Select all</button>
        <button  @click.prevent="unselectAll();" class="button-primary" style="margin-left:5px;">Un-Select all</button>
          
        <div class="grid grid-cols-3 gap-1">
         
          <label v-for="advertiser in filteredAdvertisers ">
            <input class="checkbox-sm" type="checkbox" id="" name="" :value="advertiser.id" v-model="newUser.advertisers">
           {{advertiser.name}}
          </label>
        
        </div>
      </fieldset>
      <div class="lg:col-span-2 text-center mt-16">
        <button class="button-secondary" @click="newUser = []; hideCreateUser= true;">
          Discard
        </button>
        <button class="button-primary" @click.prevent="createUser" :disabled="!formChanged">
          Create User
        </button>
      </div>
    </div>
  </div>

  <!-- MODAL DELETE USER -->
  <div id="modal-delete-user" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "
    :class="{ hidden: !showSavedModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow"
        @click.prevent="clearCurrentUser; showSavedModal = 0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-3xl mt-10 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-14 inline-block mr-4">
          <path
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
        </svg>
        Are you sure you want to remove this user?
      </h2>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto" @click.prevent="clearCurrentUser; showSavedModal = 0">
          Nevermind
        </button>
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="deleteUser">
          Delete
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL DELETE USER -->
</template>


<style >
.profile #helpinghand {
  display: none !important;
}
button:disabled {
  background-color: rgb(229 229 229) !important;
}
</style>