<script setup>
import { onMounted, ref, computed } from 'vue';
import { api } from '../../helpers';
import VueMultiselect from 'vue-multiselect'

const campaigns             = ref([]);
const statusFilter          = ref('all');
const channelFilter         = ref([]);
const filteredCampaigns     = computed(() => {  return campaigns.value.filter(isBigEnough) });
const currentAdvertiserObj  = JSON.parse(localStorage.getItem('currentAdvertiser'));

const mediaChannels = [
  { name: 'ctv', label: 'CTV' },
  { name: 'ctvx', label: 'CTVx' },
  { name: 'display', label: 'Display' }, 
];

function isBigEnough(value) {
  if (statusFilter.value == 'all') {
    return true;
  } else {
    return statusFilter.value == value.meta.new_status
  }
}

const filterCampaigns = () => { };

let sortByNameFlag = false;
const sortByName = () => {
  if (sortByNameFlag) {
    campaigns.value.sort((a, b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1);
  } else {
    campaigns.value.sort((a, b) => a['name'].toLowerCase() > b['name'].toLowerCase() ? -1 : 1);
  }
  sortByNameFlag = !sortByNameFlag;
}

onMounted(async () => {
  if(currentAdvertiserObj){
    document.body.classList.add("loading");
      const response = await api.get('/get_campaigns?advertiser_id=' + currentAdvertiserObj.id);
    document.body.classList.remove("loading");
  campaigns.value = response.data.data;
  }
 
});
</script>
<template>
  <div class="campaigns px-4 lg:px-10">
    <form action="" class="w-full flex flex-col md:flex-row gap-4 lg:gap-5 mb-6 md:mb-5">
      <label class="grow shrink-0">
        <span class="font-bold">Campaign Status</span>
        <select name="select" class="w-full shadow-md p-3 bg-white pr-9" @change="filterCampaigns" v-model="statusFilter">
          <option value="all"> All</option>
          <option value="draft">Draft</option> <!-- Status is not started and hasnt been purchased-->
          <option value="ready">Ready</option>
          <option value="running">Running</option>
          <option value="completed">Completed</option>
        </select>
      </label>
      <label class="grow shrink-0">
        <span class="font-bold">Campaign Media</span>
        <VueMultiselect v-model="channelFilter" :options="mediaChannels" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select media channel" label="name" track-by="name" :preselect-first="false">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
        </VueMultiselect>
      </label>
      <RouterLink to="/campaign-builder" active-class="is-active"
        class="bg-primary uppercase text-white px-2 lg:px-8 py-3 font-bold" style="height: 50px;
    margin-top: 20px;">
        Build New Campaign
      </RouterLink>
    </form>
    <div class="table-container">
      <table class="w-full min-w-max lg:min-w-min">

        <tr class="thead-tr">
          <th class="w-4/12">Campaign <button class="sort" @click.prevent="sortByName()"
              onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button></th>
          <th class="w-2/12">Flight Dates <button class="sort"
              onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button></th>
          <th class="w-6/12">Campaign Status <button class="sort"
              onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button></th>
        </tr>
        <!--        <tbody v-if="campaigns.length==0">
          <tr class="tbody-tr">
            <td colspan="3">
              No campaigns found under the current advertiser
            </td>
              
          </tr>
        </tbody>
        -->

        <tbody v-for="campaign in filteredCampaigns" :key="campaign.id">
          <tr class="tbody-tr">
            <td><span class="block">{{ campaign.name }}</span>
              <span class="block">
                <span class="font-bold uppercase">Media:</span>
                CTV, Display
              </span>
              <a href="#" class="table-action" onclick="
  event.preventDefault();
  this.closest('tr').classList.toggle('border-0');
  this.closest('tr').nextElementSibling.classList.toggle('hidden');
  if (this.querySelector('span').innerHTML === '+') {
    this.querySelector('span').innerHTML = '-';
  } else {
    this.querySelector('span').innerHTML = '+';
  }
  ">Audience Details <span>+</span></a>
            </td>
            <td>
              <span>{{ campaign.meta.new_flights_0_start_date }}</span> -
              <span>{{ campaign.meta.new_flights_0_end_date }}</span>
            </td>
            <td>
              <form class="fieldset"><label><input type="checkbox" id="audience" name="audience" value="audience">
                  Audience </label><label><input type="checkbox" id="ctv" name="ctv" value="ctv"> CTV <sup
                    class="text-[.45rem]">TM</sup></label><label><input type="checkbox" id="creative" name="drone"
                    value="creative"> Creative </label><label><input type="checkbox" id="review" name="drone"
                    value="review"> Review </label><label><input type="checkbox" id="purchase" name="drone"
                    value="purchase"> Purchase </label></form><button href="#"
                class="uppercase text-primary text-xs font-bold mt-1">Edit Campaign</button>
            </td>
          </tr>
          <tr class="tbody-tr sub-tr hidden">
            <td colspan="3" class="">
              <div class="flex">
                <div class="w-1/5">
                  <strong>Audience Type:</strong>
                </div>
                <div class="w-1/5">
                  <strong>Audience Size:</strong>
                </div>
                <div class="w-3/5">
                  <strong>Audience Segment:</strong>
                </div>
              </div>
              <div class="flex">
                <div class="w-1/5">3rd Party</div>
                <div class="w-1/5">267,300</div>
                <div class="w-3/5">
                  Chicago Wolves - Hockey Fans/Purchases 25ml
                </div>
              </div>
              <div class="flex">
                <div class="w-1/5">3rd Party</div>
                <div class="w-1/5">267,300</div>
                <div class="w-3/5">
                  Chicago Wolves - Hockey Fans/Purchases 25ml
                </div>
              </div>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
  <div id="modal-delete-user" class="modal hidden">
    <div class="modal-body max-w-3xl">
      <button class="close-modal"
        onclick="this.closest('.modal').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-2xl mt-10 font-bold">
        <i class="fa-solid fa-triangle-exclamation fa-2xl mr-4"></i>
        Are you sure you want to delete this campaign?
      </h2>
      <div class="modal-actions">
        <button class="button-secondary"
          onclick="this.closest('.modal').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
          Nevermind
        </button>
        <button class="button-primary">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>