<script setup>
import {  ref  } from 'vue';
import { useTriadStore }    from '@/user/triad-store';
import { useRoute, useRouter } from 'vue-router';

const route           = useRoute();
const router          = useRouter();
const triadStore      = useTriadStore();


const back = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_four' });
  }, 1000);
}

const saveStepSix  = () => {

}


</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->



 <!-- SUCCESS -->
 <div id="" class="campaign-builder-step-container justify-center items-center gap-3" :aria-expanded="true">
     
      <h2 class="text-4xl font-bold">Success!</h2>
      <p class="text-2xl mb-5">Your Your campaign is built & ready for launch.</p>
      <button class="button-primary">All Campaigns</button>
      <button class="button-secondary text-sm uppercase">Account Home</button>
    </div>
    <!-- SUCCESS -->


  </div>


</template>

<style></style>
