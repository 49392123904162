import { defineStore } from 'pinia';
import {ref} from "vue";

export const useTriadStore = defineStore('triad', () => {
const advertisers           = ref([]);
const accounts              = ref([]);
const currentCampaign       = ref({});
const currentAdvertiser     = ref([]);
const currentAccount        = ref([]);
const profile               = ref([]);
const userId                = ref(0);
const role                  = ref('');

  return {
    advertisers,
    accounts,
    profile,
    userId,
    currentCampaign,
    currentAdvertiser,
    currentAccount,
    role

  }
}, {persist: true});

export default useTriadStore;
