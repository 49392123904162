<script setup>
import { useTriadStore }    from '@/user/triad-store';
import { onMounted }        from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route         = useRoute();
const router        = useRouter();
const triadStore    = useTriadStore();
const saveStepOne   = () => {
  if(!triadStore.currentCampaign.name){
    alert('You need to input a campaign name');
    return false;
  }
  if(!triadStore.currentCampaign.startDate){
    alert('You need to input a campaign start date');
    return false;
  }
  if(!triadStore.currentCampaign.endDate){
    alert('You need to input a campaign end date');
    return false;
  }
  if(!triadStore.currentCampaign.advertiser){
    alert('You need to input a campaign advertiser');
    return false;
  }

    triadStore.currentCampaign.flights.push({
      startDate  : triadStore.currentCampaign.startDate,
      endDate    : triadStore.currentCampaign.endDate,
      budget     : [],
      adgroups   : [],
      lockedBudget: 0,
      totalBudget: 0,
    });
   
    const args = {
      name      : triadStore.currentCampaign.name,
      advertiser: triadStore.currentCampaign.advertiser,
    };
    document.body.classList.add("loading");
   // const response = await api.post('/save_step_one', args);
    //currentCampaign.value.id            = response.data;
    triadStore.currentCampaign.current_step  = 2;
    document.body.classList.remove("loading");
    triadStore.advertisers.forEach(function(item){
      if(item.id == triadStore.currentCampaign.advertiser){
        triadStore.currentCampaign.advertiserName = item.name;
      }
    });
    setTimeout(() => {
        router.push(route.query.redirectTo || { name: 'step_two' });
    }, 1000);

}

onMounted( () => {
    if(!triadStore.currentCampaign.name){
        triadStore.currentCampaign  = {
            name: '',
            advertiser: '',
            advertiserName: '',
            audiences: [],
            audiences_change: [],
            current_step:1,
            startDate:'',
            endDate:'',
            flightIndex: 0,
            adgroups: [],
            flights: [],
            currentAdgroupFlights:{},
            budget:0,
        }
    }
    if(triadStore.currentAdvertiser.id){
      triadStore.currentCampaign.advertiser = triadStore.currentAdvertiser.id;
    }
});

</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->

    <!-- STEP 0: Create Your Campaign -->
    <div id="" class="campaign-builder-step-container justify-center" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold mb-auto">Create Your Campaign</h2>
      </div>
      <div class="max-w-xl mx-auto flex flex-col gap-4 -mt-14">
        <label for="" class="lg:flex items-center justify-between gap-4">
          <span>Select an Advertiser</span>
          <select name="select" class="w-96" v-model="triadStore.currentCampaign.advertiser">
            <option v-for="advertiser in triadStore.advertisers" :value="advertiser.id">
              {{ advertiser.name }}
            </option>
          </select>
        </label>
        <label class="flex items-center justify-between gap-4">
          <span>Name Your Campaign</span>
          <input type="text" class="w-96" placeholder="Campaign Name" v-model="triadStore.currentCampaign.name">
        </label>
       

          <div class="flex items-center gap-3"><!-- Agregar "invisible" cuando sea necesario -->
            <span class="font-bold text-right mb-6">Campaign Dates</span>
            <label><input type="date" v-model="triadStore.currentCampaign.startDate" />
              <span class="uppercase block text-xs text-center mt-2">Start Date</span></label>
            <label><input type="date"  v-model="triadStore.currentCampaign.endDate"/>
              <span class="uppercase block text-xs text-center mt-2">End Date</span></label>
          </div>
        <button class="uppercase text-primary p-0 font-bold text-xs ml-auto hidden">
          Create New Advertiser
        </button>
      </div>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepOne">Next <i
            class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 0 -->
</div>

</template>

<style></style>
