<script setup>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/user/stores-auth';
import topNav from "./components/topNav.vue"
import sideNav from "./components/sideNav.vue"
import { RouterView } from "vue-router";

const isLoading = ref(true);

onMounted(async () => {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {

    isLoading.value = false;
  }
});

</script>

<template>
  <topNav />
  <div id="main-container" class="flex overflow-x-hidden flex-nowrap">
    <sideNav />
    <div id="main-content" class="py-16 grow relative">
      <router-view />
    </div>
  </div>
</template>

<style scoped></style>
