<script setup>
import { ref, onMounted, watch } from "vue"
import { api } from '../helpers'
import { useTriadStore }    from '@/user/triad-store'

const triadStore      = useTriadStore();

const profile         = ref({});
const formChanged     = ref(0);
const showSavedModal  = ref(0);
const files           = ref([]);
const upload          = ref(null);

const save = async () => {
  document.body.classList.add("loading");
  try {
    const response = await api.post('/update_profile', {
      'user_id'       : profile.value.id,
      'first_name'    : profile.value.user_first_name,
      'last_name'     : profile.value.user_last_name,
      'display_name'  : profile.value.user_display_name,
      'phone'         : profile.value.user_phone,
      'profile_picture_url' : profile.value.profile_picture_url,
    });
    
    document.body.classList.remove("loading");
    showSavedModal.value = 1;
    formChanged.value = 0;

    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
};

const   customAction = async (file, component)=> {
  /*
  let formData = new FormData();
  formData.append('filepond', file.file);
  const response = await api.post('/file_upload', 
  formData
  , {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });
  console.log(response);
  // return await component.uploadPut(file)
  */
  return
}


onMounted(() => {
  profile.value = triadStore.profile;
  watch(profile, (value) => {
      formChanged.value = 1;
    }, { deep: true }
  );
});

const inputFile =  async (newFile, oldFile)=> {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }

      }
      // Thumbnails
      newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
         
          newFile.thumb = newFile.blob
        }

        let formData = new FormData();
        formData.append('filepond', newFile.file);
        const response = await api.post('/file_upload', 
          formData
          , {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        );

        profile.value.profile_picture_url = response.data.data;
    };
 
    const inputFilter =  function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
     
    };

</script>
<template>
  <div class="tabs profile">

    <!-- TAB TRIGGERS -->
    <div class="tab-triggers-container">
      <nav class="tab-triggers-nav">
        <a href="#" data-target="my-profile" aria-selected="true" class="tab-trigger">My Profile</a>
        <a href="/user-management" data-target="account-settings" aria-selected="false" class="tab-trigger">User
          Management</a>
        <a href="/account-settings" data-target="account-settings" aria-selected="false" class="tab-trigger">Account
          Settings</a>
      </nav>
    </div>
    <!-- END OF TAB TRIGGERS -->
    <!-- TAB PANELS -->
    <div id="my-profile" aria-expanded="true" class="aria-expanded:block">
      <form action="" class="max-w-2xl mx-auto mt-2 flex flex-col gap-4 lg:gap-6">
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-8">
          <div class="">
            <div class="w-[100px] h-[100px] rounded-full overflow-hidden relative border-primary border-2 mx-auto">
              <img  v-if="files.length ==0" :src="profile.profile_picture_url" alt="" class="object-cover object-center w-full h-full" />
              <img class="object-cover object-center w-full h-full" v-if="files.length >0" :src="files[0].thumb" />
              <div class="bg-black/60 w-full h-full absolute left-0 top-0"></div>
              <file-upload
                  class=" absolutex top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white "
                    ref="upload"
                    v-model="files"
                    :custom-action="customAction"
                    @input-file="inputFile"
                    @input-filter="inputFilter"
              >
                <i class="fa-light fa-camera fa-2x absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"></i>
              </file-upload>
            </div>
            <small class="text-neutral-500 text-center w-full block mt-2">Profile Photo </small>
          </div>
          <div class="grow">
            <span class="block mb-[10px]"><b>User Role:</b> Administrator
              <sup
                class="rounded-full bg-black text-white w-[9px] h-[9px] inline-flex justify-center items-center text-[8px] hidden">i</sup></span>
            <span class="font-bold">Name <sup class="text-primary">*</sup></span>
            <div class="flex flex-col lg:flex-row gap-4">
              <div class="lg:w-1/2">
                <input type="text" class="mt-1" v-model="profile.user_first_name">
                <small class="text-neutral-500">First Name</small>
              </div>
              <div class="lg:w-1/2">
                <input type="text" class="mt-1" v-model="profile.user_last_name">
                <small class="text-neutral-500">Last Name</small>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <label>
            <span class="font-bold">Display Name <sup class="text-primary">*</sup></span>
            <input type="text" v-model="profile.user_display_name" /></label>
        </div>
        <div class="flex flex-col lg:flex-row gap-6">
          <label class="w-1/2">
            <span class="font-bold">Email Address <sup class="text-primary">*</sup></span>
            <input type="email" v-model="profile.user_email" /></label>
          <label class="w-1/2">
            <span class="font-bold">Phone <sup class="text-primary">*</sup></span>
            <input type="tel" class="min-w-full" v-model="profile.user_phone" /></label>
        </div>
        <button type="submit" class="button-primary mx-auto mt-5" @click.prevent="save" :disabled="!formChanged">
          Save Changes
        </button>
        <div class="text-center mt-6 hidden">
          <span class="text-xl lg:text-3xl font-medium block mb-4">Forgot Your Password?</span>
          <a class="button-primary">
            Reset Password
          </a>
        </div>
      </form>
    </div>

    <!-- END OF TAB PANELS -->
  </div>

    <!-- MODAL Profile Saved -->
    <div id="modal-delete-user" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 " :class="{ hidden: !showSavedModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow"  @click.prevent="showSavedModal=0"
        onclick=" document.body.classList.toggle('overflow-hidden');">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-3xl mt-10 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-14 inline-block mr-4">
          <path
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
        </svg>
      Your changes have been saved
      </h2>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
        
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="showSavedModal=0">
          Okay
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL  -->
</template>
<style >
.profile #helpinghand {
  display: none !important;
}
.absolutex{
  position:absolute !Important;
  width:50px;
  height:30px;
}
</style>