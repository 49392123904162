<script setup>
import { useTriadStore }    from '@/user/triad-store';
import { api } from '../../helpers';
import { onMounted, ref }        from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route                   = useRoute();
const router                  = useRouter();
const triadStore              = useTriadStore();
const audiences               = ref([]);
const hideAudienceRequest     = ref(1);
const audienceRequest         = ref({
  change: '',
  ids: '',
});

const submitAudieneRequest = () => {
  hideAudienceRequest.value= 1;
  alert('Audience Request Change Submitted');
};

const saveStepTwo  = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_three' });
  }, 1000);
}

const back = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_one' });
  }, 1000);
}
const isIncluded = (audience) => {
  let included = false;
  for( let i=0; i< triadStore.currentCampaign.audiences.length; i++){
      if(triadStore.currentCampaign.audiences[i].id == audience.id){
        included = true;
        i==triadStore.currentCampaign.audiences.length;
      }
  }

  return included;
}
const removeAudienceFromCampaign = (audience)=>{
  for( let i=0; i< triadStore.currentCampaign.audiences.length; i++){
      if(triadStore.currentCampaign.audiences[i].id == audience.id){
        triadStore.currentCampaign.audiences.splice(i, 1);
      }
  }
}

onMounted(async () => {
  const response2 = await api.get('/get_audiences?advertiser_id=' + triadStore.currentCampaign.advertiser);
  audiences.value = response2.data.data;
});
</script>

<template>
  <div id="campaign-builder" class=" top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->

    <!-- STEP 1: Review Your Target Audience -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Review Your Target Audience</h2>
      </div>

      <label class="ml-auto relative mt-4">
        Campaign Name: 
        <input type="text" class="w-96" v-model="triadStore.currentCampaign.name" />
        <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
          <i class="fa-sharp fa-solid fa-pen-to-square"></i>
        </button>
      </label>

      <div id="select-audience" class="table-container mt-10">
        <table>
          <tr class="thead-tr">
            <th class="w-5/12">Audience </th>
            <th class="w-7/12">Audience Logic</th>
          </tr>

         
          <tr class="tbody-tr" v-for="audience in triadStore.currentCampaign.audiences " :key="audience.id">
            <td>
             
              <input type="checkbox" id="" name="" :value="audience.id" class="text-primary focus:ring-primary"
                v-model="triadStore.currentCampaign.audiences_change" />
              <span>{{ audience.name }} </span>
         
            </td>
            <td>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.include" :key="index">
                 <span class="font-bold"  v-if="index>0"> + </span>{{ segmentGroup.name }}
              </span>
              <span class="font-bold" v-if="audience.segmentGroups.exclude.length >0"> ≠ </span>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.exclude" :key="index">
                 <span class="font-bold"  v-if="index>0"> OR </span>{{ segmentGroup.name }}
              </span>
            </td>
          </tr>
          <!-- !!!

          <tr class="tbody-tr">
            <td>
              <input type="checkbox" id="" name=""
                class="checkbox-sm checked:bg-primary checked:text-primary focus:ring-0 mr-2" />
              <span class="with-separator">Women</span>
              <span class="with-separator">25-44</span>
              <span class="with-separator"><a href="#" class="text-primary font-bold"
                  onclick="event.preventDefault();">Play Sports</a></span>
              <span class="with-separator">Online Shoppers</span>
            </td>
            <td>
              <span class="with-space">Women</span>
              <span class="with-space">+</span>
              <span class="with-space">Diagnosed with Heavy Periods</span>
              <span class="with-space">+</span>
              <span class="with-space">≠</span>
              <span class="with-space">Current Customers</span>
              <span class="with-space">OR</span>
              <span class="with-space">Low-Income</span>
            </td>
          </tr>
           -->
        </table>
      </div>

      <div class="my-10 text-center">
        <h2 class="text-bold text-3xl">Need to make a change?</h2>
        <p>Select the audience/s you would you like to change and then click the button to access the change request
          form.
        </p>
        <button class="button-primary mt-4" @click.prevent="hideAudienceRequest = 0; audienceRequest.ids = triadStore.currentCampaign.audiences_change.toString() ">
          Request Audience Change
        </button>
      </div>

      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepTwo">
          Next 
          <i class="fa-solid fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
    <!-- END OF STEP 1 -->


    <!-- STEP 1: Admin View -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <h2 class="text-3xl font-semibold">Add Audience to Campaign </h2>
      </div>

    
      <div id="select-audience" class="table-container mt-10">
        
        <table>
          <tr class="thead-tr">
            <th >Action </th>
            <th class="w-5/12">Audience </th>
            <th class="w-7/12">Audience Logic  </th>
          </tr>

          <tr class="tbody-tr" v-for="audience in audiences " :key="audience.id">
            <td>
              <button class="button-primary" @click.prevent="triadStore.currentCampaign.audiences.push(audience)" v-if="!isIncluded(audience)">Add</button>
              <button class="button-primary" @click.prevent="removeAudienceFromCampaign(audience)" v-if="isIncluded(audience)">Remove</button>
            </td>
            <td>
              <span>{{ audience.name }} </span>
            </td>
            <td>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.include" :key="index">
                 <span class="font-bold"  v-if="index>0"> + </span>{{ segmentGroup.name }}
              </span>
              <span class="font-bold" v-if="audience.segmentGroups.exclude.length >0"> ≠ </span>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.exclude" :key="index">
                 <span class="font-bold"  v-if="index>0"> OR </span>{{ segmentGroup.name }}
              </span>
            </td>
          </tr>
    
        </table>
      </div>


      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepTwo">
          Next 
          <i class="fa-solid fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
    <!-- END OF STEP 1 -->



  </div>

  <!-- MODAL EXIT BUILDER -->
  <div id="modal-delete-user" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center justify-center mt-10 mb-4 gap-5 text-2xl">
        <i class="fa-solid fa-triangle-exclamation fa-2xl"></i>
        <div>
          <h2 class="text-xl lg:text-2xl font-bold">Are you sure you want to exit the builder?</h2>
          <p class="text-base">Don't forget to save your changes before you exit so you can come back to your draft later.
          </p>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Back to Builder
        </button>
        <button class="button-primary">
          Save Draft
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EXIT BUILDER -->

  <!-- MODAL REQUEST AUDIENCE CHANGE -->
  <div id="" class="modal " :class="{hidden:hideAudienceRequest}">
    <div class="modal-body max-w-3xl">
      <button class="close-modal" @click.prevent="hideAudienceRequest = 1">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-2">Request Audience Change</h2>
      <p class="text-center mb-8">Submit the form below and your account rep will be in touch to help with this change.
      </p>
      <form action="" class="flex flex-col gap-4">
        <label>
          <span>Audience ID(s) <sup>*</sup></span>
          <input type="text" v-model="audienceRequest.ids" />
        </label>
        <label>
          <span>What is the change that needs to be made? <sup>*</sup></span>
          <textarea name="" id="" rows="4" v-model="audienceRequest.content"></textarea>
        </label>
      </form>
      <div class="modal-actions">
        <button class="button-secondary" @click.prevent="hideAudienceRequest = 1">
          Discard
        </button>
        <button class="button-primary" @click.prevent="submitAudieneRequest">
          Submit Request
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL REQUEST AUDIENCE CHANGE -->


</template>

<style></style>
