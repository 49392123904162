<script setup>
import { ref, onMounted, watch } from "vue";
import { api } from '../helpers';
import { useRoute, useRouter } from 'vue-router';
import { useTriadStore }    from '@/user/triad-store';

const triadStore      = useTriadStore();
const route           = useRoute();
console.log(34);
console.log(route.params.id);

const formChanged     = ref(0);
const account         = ref({});

const showSavedModal  = ref(0);
const upload          = ref(null);
const files           = ref([]);

const save = async () => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/update_account', { 
        'account_id'        : account.value.id, 
        'name'              : account.value.name, 
        'type'              : account.value.type,
        'account_logo_url'  : account.value.account_logo_url
      }
    );
    document.body.classList.remove("loading");
    showSavedModal.value = 1;
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}
onMounted(async () => {
  document.body.classList.add("loading");
  const response = await api.get('/get_account?account_id=' + triadStore.currentAccount.id);
  document.body.classList.remove("loading");
  
  account.value = response.data.data;
  account.value.id = triadStore.currentAccount.id;
  watch(account, (value) => {
    formChanged.value = 1;
  }, { deep: true }
  );

});

const inputFile =  async (newFile, oldFile)=> {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }

      }
      // Thumbnails
      newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
         
          newFile.thumb = newFile.blob
        }

        let formData = new FormData();
        formData.append('filepond', newFile.file);
        const response = await api.post('/file_upload', 
          formData
          , {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        );

      account.value.account_logo_url = response.data.data;
    };
 
    const inputFilter =  function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
     
    };
</script>
<template>
  <div class="tabs">
    <div class="tab-triggers-container">
      <nav class="tab-triggers-nav">
        <a href="/profile" aria-selected="false" class="tab-trigger">My Profile</a>
        <a href="/user-management" aria-selected="false" class="tab-trigger">User Management</a>
        <a href="#" aria-selected="true" class="tab-trigger">Account Settings</a>
      </nav>
    </div>
    <div id="account-settings" aria-expanded="true" class="hidden aria-expanded:block overflow-x-auto pb-6">
      <form action="" class="max-w-2xl mx-auto mt-2 flex flex-col gap-4 lg:gap-6">
        <div class="">
          <div class="w-[100px] h-[100px] rounded-full overflow-hidden relative border-primary border-2 mx-auto">
            <img  v-if="files.length ==0" :src="account.account_logo_url" alt="" class="object-cover object-center w-full h-full" />
            <img class="object-cover object-center w-full h-full" v-if="files.length >0" :src="files[0].thumb" />
            
            <div class="bg-black/60 w-full h-full absolute left-0 top-0"></div>
            <file-upload
                  class=" absolutex top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white "
                    ref="upload"
                    v-model="files"
                   
                    @input-file="inputFile"
                    @input-filter="inputFilter"
            >
              <i class="fa-light fa-camera fa-2x absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"></i>
            </file-upload>

          </div>
          <small class="text-neutral-500 text-center w-full block mt-3">Organization Logo</small>
        </div>
        <div class="flex flex-col lg:flex-row gap-4 lg:gap-6">
          <div class="lg:w-2/3">
            <label>
              <span class="font-bold">Organization Name <sup class="text-primary">*</sup></span>
              <input type="text" v-model="account.name"></label>
          </div>
          <div class="lg:w-1/3">
            <label>
              <span class="font-bold">Organization Type <sup class="text-primary">*</sup></span>
              <select name="select" v-model="account.type">
                <option value="agency">Agency</option>
                <option value="brand">Brand</option>
           
              </select>
            </label>
          </div>
        </div>
        <button type="submit" class="button-primary mx-auto mt-4" @click.prevent="save" :disabled="!formChanged">
          Save Changes
        </button>
      </form>
    </div>

    <!-- END OF TAB PANELS -->
  </div>

    <!-- MODAL Profile Saved -->
    <div id="modal-delete-user" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 " :class="{ hidden: !showSavedModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow"  @click.prevent="showSavedModal=0"
        onclick=" document.body.classList.toggle('overflow-hidden');">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-3xl mt-10 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-14 inline-block mr-4">
          <path
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
        </svg>
      Your changes have been saved
      </h2>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
        
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="showSavedModal=0">
          Okay
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL  -->
</template>


<style >
.profile #helpinghand {
  display: none !important;
}

button:disabled {
  background-color: rgb(229 229 229) !important;
}
.absolutex{
  position:absolute !Important;
  width:50px;
  height:30px;
}
</style>