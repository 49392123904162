<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/user/stores-auth';
import { api } from '/src/helpers';
import { useTriadStore } from '@/user/triad-store';

const triadStore        = useTriadStore();

const route             = useRoute();
const router            = useRouter();
const showRoleModal     = ref(0);
const showAccountModal  = ref(0);
const authStore         = useAuthStore();
const emit              = defineEmits(['message', 'loading']);

const email     = ref('');
const password  = ref('');

/**
 * Do login and handle error / success
 */
const multipleAccountCheck = () =>{
  if( triadStore.accounts.length == 1 ){
    triadStore.currentAccount = triadStore.accounts[0];
 
    setTimeout(() => {
        router.push(route.query.redirectTo || { name: 'home' });
      }, 1000);
  }else{
    showAccountModal.value  = 1;
  }
}
const setRole = (role) => {
  triadStore.role       = role;
  showRoleModal.value   = 0;
  multipleAccountCheck();
}

const login = async () => {
 
  const response = await authStore.login(email.value, password.value);
  if(triadStore.profile.role == 1 ){
    triadStore.role      = 'admin';
    showRoleModal.value  = 1;
  }else{
    triadStore.role = 'user';
    multipleAccountCheck();
  }

  switch (response.status) {
    // if working
    case 200:
      
      break;

    // if error
    case 403:
    case 500:
    default:
  }
};

const setAccount = async (account) =>{
  triadStore.currentAccount = account;
 
  document.body.classList.add("loading");
    const token = localStorage.getItem('userToken');
    if (token) {
      const addTokenHeader = (config) => {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
      };
      api.interceptors.request.use(addTokenHeader);
    }
    const response          = await api.get('/get_advertisers?account_id='+account.id);
    triadStore.advertisers  = response.data.data;
  document.body.classList.remove("loading");
  
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'home' });
  }, 1000);
}
</script>

<template>
<form @submit.prevent="login" class="w-1/2 h-full flex flex-col gap-6 justify-center items-center">
      <div class="max-w-md flex flex-col gap-6 -mt-4">
        <h2 class="text-3xl font-blold mb-2">Login</h2>
        <label>
          <span>Email Address <sup class="text-primary font-normal">*</sup></span>
          <input type="email" v-model="email" /></label>
        <label>
          <span>Password <sup class="text-primary font-normal">*</sup></span>
          <input type="password" v-model="password" />
        </label>
        <button type="submit" class="button-primary max-w-fit mt-2">Login</button>
        <div class="text-center mt-6  ">
          <span class="text-xl lg:text-3xl font-medium block mb-4">Forgot Your Password?</span>
          <router-link :to="{ name: 'passwordForgot' }"  class="bg-primary text-white uppercase px-8 py-3 transition-all inline-block">
            Reset Password
        </router-link>
        </div>
      </div>
    </form>
<!-- END OF MODAL  -->

     <!-- MODAL Select Role -->
     <div id="modal-confirm-delete" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "
    :class="{ hidden: !showRoleModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow" @click.prevent="showRoleModal=0"
       >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2>Please select a role</h2>
   
      <div class="table-container">

      <table>
        <tr class="thead-tr">
          <th>Role Name</th>
          <th>Action</th>
        </tr>
        <tr class="tbody-tr" >
          <td>Admin Dev</td>
          <td><button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="setRole('adminDev')">Select Role</button></td>
        </tr>
        <tr class="tbody-tr" >
          <td>Admin</td>
          <td><button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="setRole('admin')">Select Role</button></td>
        </tr>
        <tr class="tbody-tr" >
          <td>User</td>
          <td><button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="setRole('user')">Select Role</button></td>
        </tr>
      </table>
    </div>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
       
      </div>
    </div>
  </div>
  <!-- END OF MODAL  -->


  <!-- MODAL Select Account -->
  <div id="modal-confirm-delete" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "
    :class="{ hidden: !showAccountModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow" @click.prevent="showAccountModal=0"
       >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2>Please select an account</h2>
   
      <div class="table-container">

      <table>
        <tr class="thead-tr">
          <th>Account Name</th>
          <th>Action</th>
        </tr>
        <tr class="tbody-tr" v-for="account in triadStore.accounts" :key="account.id">
          <td>{{ account.name }}</td>
          <td>
            <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="setAccount(account)">
              Select Account
            </button>
          </td>
        </tr>

      </table>
    </div>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
       
      </div>
    </div>
  </div>
  <!-- END OF MODAL  -->
</template>

<style scoped>

</style>
