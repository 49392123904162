<script setup>
import { ref, onMounted, computed } from "vue";
import { api } from '../helpers';
import VueMultiselect from 'vue-multiselect'
import { useTriadStore }    from '@/user/triad-store'
const triadStore      = useTriadStore();

const audiences           = ref([]);
const segments            = ref([]);
const search              = ref('');
const currentAudience     = ref({'segments':[]});
const showDeleteConfirm   = ref(0);
const showAddSegment      = ref(0);
const newSegment          = ref({});

const get_audiences = async () => {
  document.body.classList.add("loading");
  if(triadStore.currentAdvertiser){
      const response  = await api.get('/get_audiences?advertiser_id=' + triadStore.currentAdvertiser.id);
      audiences.value = response.data.data;
  }
  document.body.classList.remove("loading");
};

const get_segments = async () => {
  document.body.classList.add("loading");
  if(triadStore.currentAdvertiser){
      const response  = await api.get('/get_audience_segments?advertiser_id=' + triadStore.currentAdvertiser.id);
      segments.value = response.data.data;
  }
  document.body.classList.remove("loading");
};

const filteredAudiences = computed(() => {
  return audiences.value.filter((val) => {
    return val.name.toString().toLowerCase().includes(search.value)
  });
});

const deleteAudience = async (audience) => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/delete_audiences', { audiences: [audience] });
    get_audiences();
    document.body.classList.remove("loading");
    showDeleteConfirm.value = 0;

    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const saveAudience = async () =>{
  try {
    document.body.classList.add("loading");
    currentAudience.value.advertiser = triadStore.currentAdvertiser.id;
      const response = await api.post('/save_audiences', { audiences: [currentAudience.value] });
      get_audiences();
    document.body.classList.remove("loading");

  
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const saveSegment = async () =>{
  try {
    document.body.classList.add("loading");
      newSegment.value.first_party_advertiser = triadStore.currentAdvertiser.id;
      const response = await api.post('/save_segments', { segments: [newSegment.value] });
      get_segments();
    document.body.classList.remove("loading");

    showAddSegment.value  = 0;
    newSegment.value      = {}

    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

onMounted(async () => {
    get_audiences();
    get_segments();
});


const inputFile =  async (newFile, oldFile)=> {
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    // Get response data
    console.log('response', newFile.response)
    if (newFile.xhr) {
      //  Get the response status code
      console.log('status', newFile.xhr.status)
    }

  }

  let formData = new FormData();
  formData.append('filepond', newFile.file);
    
  const response = await api.post('/file_upload', 
    formData
    , {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }
  );
  newFile.file_url = response.data.data;
    
};

const inputFilter =  function (newFile, oldFile, prevent) {
  if (newFile && !oldFile) {
    // Filter non-image file
    if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
      //return prevent()
    }
  }
 
 

};

</script>
<template>
  <div id="audience" class="audience px-10" >
    <div class="w-full flex flex-col md:flex-row gap-5 mb-5">
      <label class="relative grow">
        <input placeholder="Search" type="text" v-model="search">
        <i class="fa-solid fa-magnifying-glass absolute top-1/2 -translate-y-1/2 right-4 text-primary"></i>
      </label>
      <button class="button-primary" @click.prevent="hideRequestForm = 0">New Audience</button>
    </div>
    <div class="table-container">
      <table class="w-full" >
        <tbody>
          <tr class="thead-tr">
            <th class="w-1/3">Audience Name <button class="sort"
                onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-1/3">Audience Logic</th>
            <th class="w-1/3">Assigned Ad Groups</th>
          </tr>
          <tr class="tbody-tr" v-for="audience in filteredAudiences" :key="audience.id">
            <td>
              <span class="block">{{ audience.name }}</span>
              <a href="#" class="table-action" @click.prevent="currentAudience = audience; showDeleteConfirm = 1;">Delete
              </a>
            </td>
            <td>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.include" :key="index">
                 <span class="font-bold"  v-if="index>0"> + </span>{{ segmentGroup.name }}
              </span>
              <span class="font-bold" v-if="audience.segmentGroups.exclude.length >0"> ≠ </span>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.exclude" :key="index">
                 <span class="font-bold"  v-if="index>0"> OR </span>{{ segmentGroup.name }}
              </span>
            </td>
            <td>
              <span class="text-primary block font-bold" v-for="adgroup in audience.adgroups">
                  {{ adgroup.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="table-container">
      <table class="w-full" >
        <tbody>
          <tr class="thead-tr">
            <th >Audience Name  <button class="button-primary" @click.prevent="saveAudience">Save</button></th>
            <th >Est. People</th>
            <th>Est. Households</th>
          </tr>
          <tr >
            <td ><input type="text" v-model="currentAudience.name" /></td>
            <td ><input type="text" v-model="currentAudience.estimated_counts_people" /></td>
            <td ><input type="text" v-model="currentAudience.estimated_counts_households" /></td>
          </tr>
          <tr class="thead-tr">
            <th colspan="3">Segments  <button class="button-primary" @click.prevent="currentAudience.segments.push({})">Add</button></th> 
          </tr>
          <tr>
            <td>
              Name
            </td>
            <td>
              Action
            </td>
            <td>
              Segments
            </td>
          </tr>
          <tr v-for="(segment,index) in currentAudience.segments" :key="index">
            <td>
              <input type="text" v-model="segment.name" />
            </td>
            <td>
              Include:<input type="radio" id="one" value="include" v-model="segment.action" /> <br/>
              Exclude:<input type="radio" id="one" value="exclude" v-model="segment.action" />
            </td>
            <td>
              <button class="button-primary" @click.prevent="showAddSegment=1">New Segment</button>
              <VueMultiselect v-model="segment.segments" :options="segments" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                placeholder="Select segment(s)" label="name" track-by="name" :preselect-first="false">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
            </VueMultiselect>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="modal-delete-audience" class="modal " :class="{ hidden: !showAddSegment }">
      <div class="modal-body max-w-3xl">
        <button class="close-modal" @click.prevent="showAddSegment = 0">
          <i class="fa-solid fa-xmark"></i>
        </button>
        <div class="flex items-center gap-5 pt-5">
         
          <form class="">
            <label class="col-span-4 flex items-center gap-3 relative">
              <span>Name</span>
              <input type="text" v-model="newSegment.name" />  
            </label>
            <label class="relative flex items-center justify-center gap-1 cursor-pointer col-span-1 pt-4">
              <span>First Party</span>
              <input type="radio" id="one" value="first-party" v-model="newSegment.type" />  
            </label>
            <label class="relative flex items-center justify-center gap-1 cursor-pointer col-span-1 pt-4">
              <span>Third Party</span>
              <input type="radio" id="one" value="third-party" v-model="newSegment.type" />  
            </label>
            <div v-if="newSegment.type=='first-party'">
                <table>
                  <td>
                    <strong>Source</strong>
                    <label class="relative flex items-center justify-center gap-1 cursor-pointer col-span-1 pt-4">
                      <span>CRM Data</span>
                      <input type="radio" id="one" value="import" v-model="newSegment.first_party_source" />  
                    </label>
                    <label class="relative flex items-center justify-center gap-1 cursor-pointer col-span-1 pt-4">
                      <span>Pixel</span>
                      <input type="radio" id="one" value="pixel" v-model="newSegment.first_party_source" />  
                    </label>
                  </td>
                  <td>
                    <div v-if="newSegment.first_party_source=='import'">
                      <label class="col-span-4 flex items-center gap-3 relative">
                        <span>File URL</span>
                        <input type="text" v-model="newSegment.first_party_file_url" />  
                      </label>
                      <label class="col-span-4 flex items-center gap-3 relative">
                        <span>Unique Rows</span>
                        <input type="number" v-model="newSegment.first_party_file_unique_rows" />  
                      </label>
                      <label class="col-span-4 -mb-3">
                          <span>Upload File <sup>*</sup></span>
                          <div class="bg-light py-6 text-center text-sm hoverhand" >
                        
                              <file-upload 
                                  class=""
                                    ref="upload"
                                    v-model="newSegment.files"
                                    :drop="true"
                                    @input-file="inputFile"
                                    @input-filter="inputFilter"       
                              >
                            
                              Drag & drop your files or click to select
                            </file-upload>
                            
                          </div>
                        </label>
                    </div>
                    <div v-if="newSegment.first_party_source=='pixel'">
                      <label class="shrink-0 w-3/12">
                        <span class="font-bold">Type</span>
                        <select v-model="newSegment.first_party_pixel_type">
                          <option value=""> Select</option>
                          <option value="url_mapping">URL Mapping</option>
                          <option value="custom_event">Custom Event</option>
                        
                        </select>
                      </label>
                      <div v-if="newSegment.first_party_pixel_type=='url_mapping'">
                        <label class="shrink-0 w-3/12">
                        <span class="font-bold">Rule</span>
                        <select v-model="newSegment.first_party_pixel_mapping_rule">
                          <option value=""> Select</option>
                          <option value="contains">Contains</option>
                          <option value="end">Ends With</option>
                          <option value="start">Starts With</option>
                          <option value="exact">Exact</option>
                        </select>
                      </label>
                      <label class="col-span-4 flex items-center gap-3 relative">
                        <span>Value</span>
                        <input type="text" v-model="newSegment.first_party_pixel_mapping_value" />  
                      </label>
                      <label class="col-span-4 flex items-center gap-3 relative">
                        <span>Label</span>
                        <input type="text" v-model="newSegment.first_party_pixel_mapping_label" />  
                      </label>

                      <label class="col-span-4 flex items-center gap-3 relative">
                        <span>Description</span>
                        <input type="text" v-model="newSegment.first_party_pixel_mapping_description" />  
                      </label>
                      </div>  
                      <div v-if="newSegment.first_party_pixel_type=='custom_event'">
                        <label class="shrink-0 w-3/12">
                          <span class="font-bold">Rule</span>
                          <select v-model="newSegment.first_party_pixel_custom_event_rule">
                            <option value=""> Select</option>
                            <option value="contains">Contains</option>
                            <option value="end">Ends With</option>
                            <option value="start">Starts With</option>
                            <option value="exact">Exact</option>
                            <option value="greater">Greater Than</option>
                            <option value="less">Less Than</option>
                            <option value="between">Between</option>
                          </select>
                        </label>

                        <label class="col-span-4 flex items-center gap-3 relative">
                          <span>Parameter</span>
                          <input type="text" v-model="newSegment.first_party_pixel_custom_event_parameter" />  
                        </label>

                        <label class="col-span-4 flex items-center gap-3 relative">
                          <span>String Value</span>
                          <input type="text" v-model="newSegment.first_party_pixel_custom_event_string_value" />  
                        </label>
                      </div> 
                    </div>
                  </td>
                </table>
            </div>

            <div v-if="newSegment.type=='third-party'">
              <label class="shrink-0 w-3/12">
                  <span class="font-bold">Source</span>
                  <select v-model="newSegment.third_party_source">
                    <option value=""> Select</option>
                    <option value="ttd"> theTradeDesk</option>
                    <option value="liveramp"> LiveRamp</option>
                    <option value="roku"> Roku</option>
                    <option value="other"> Other</option>
                    
                  </select>
                </label>
              <label class="col-span-4 flex items-center gap-3 relative">
                <span>Data Provider</span>
                <input type="text" v-model="newSegment.third_party_data_provider" />  
              </label>

              <label class="col-span-4 flex items-center gap-3 relative">
                <span>Element Path</span>
                <input type="text" v-model="newSegment.third_party_element_path" />  
              </label>
              <label class="col-span-4 flex items-center gap-3 relative">
                <span>Description</span>
                <input type="text" v-model="newSegment.third_party_description" />  
              </label>
            </div>

           
          </form>

        </div>
        <div class="modal-actions">
          <button class="button-secondary" @click.prevent="showAddSegment = 0">
            Nevermind
          </button>
          <button class="button-primary" @click.prevent="saveSegment">
            Save
          </button>
        </div>
      </div>
    </div>

    <div id="modal-delete-audience" class="modal " :class="{ hidden: !showDeleteConfirm }">
      <div class="modal-body max-w-3xl">
        <button class="close-modal" @click.prevent="showDeleteConfirm = 0">
          <i class="fa-solid fa-xmark"></i>
        </button>
        <div class="flex items-center gap-5 pt-5">
          <i class="fa-solid fa-triangle-exclamation text-5xl leading-0"></i>
          <div class="">
            <h2 class="text-center text-xl lg:text-2xl font-bold">
              Are you sure you want to delete this audience?
            </h2>
            <p>You will not be able to retrieve this audience once deleted.</p>
          </div>
        </div>
        <div class="modal-actions">
          <button class="button-secondary" @click.prevent="showDeleteConfirm = 0">
            Nevermind
          </button>
          <button class="button-primary" @click.prevent="deleteAudience(currentAudience);">
            Delete
          </button>
        </div>
      </div>
    </div>
  
</template>


<style></style>