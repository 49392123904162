<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/user/stores-user';


const router = useRouter();
const userStore = useUserStore();
const emit = defineEmits(['message', 'loading']);
const register = ref({});

const fields = ref({
  first_name: '',
  last_name: '',
  user_email: '',
  user_pass: '',
  user_pass_confirm: '',
  acf_gender: 'male',
  acf_job: '',
});

const nonce = ref('');
const timestamp = ref('');

const sendRequest = async () => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/register_user', { 
      request: requestAudience.value, 
      'advertiser_id':triadStore.currentAdvertiser.id 
     });
    document.body.classList.remove("loading");
    
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

/**
 * Do register and handle error / success
 */
const registera = async () => {
  emit('loading', true);

  // clear all red border on error fields
  const $errorFields = document.querySelectorAll('.user-form__field.has-error');
  $errorFields.forEach(($f) => {
    $f.classList.remove('has-error');
  });

  const response = await userStore.register({
    ...fields.value,
    _wpnonce: nonce.value,
    timestamp: timestamp.value,
  });
  
  emit('loading', false);

  switch (response.status) {
    // if working
    case 200:
      sessionStorage.removeItem('registerForm');
      router.push({
        name: 'login',
        query: {
          message: 'You can now login with your new account',
          messageType: 'good',
        },
      });
      break;

    // if error
    case 403:
    case 500:
      emit('message', response.message);

      // Add red border to error field
      document.querySelector(`[name=${response.code}]`).closest('label').classList.add('has-error');
      window.scrollTo(0, 0, { behavior: 'smooth' });
      break;

    default:
      emit('message', response.message);
  }
};

// Backup any filled data so it's not empty if you refresh the page
const backupFilledData = () => {
  sessionStorage.setItem('registerForm', JSON.stringify(fields.value));
};

onMounted(async () => {
  // Get the nonce to validate in backend
  const response = await userStore.getRegisterNonce();
  nonce.value = response.nonce;
  timestamp.value = response.timestamp;

  // Restore filled data
  const cachedData = sessionStorage.getItem('registerForm');
  if (cachedData) {
    fields.value = JSON.parse(cachedData);
  }
});
</script>

<template>
   <form @submit.prevent="sendRequest" class="w-1/2 h-full flex flex-col gap-6 justify-center items-center ">
      <div class="max-w-lg flex flex-col gap-8 -mt-4">
        <div class="">
          <h2 class="text-3xl font-blold mb-1">Create an Account</h2>
          <p>Already have an account? <a class="font-bold text-primary" href="/login">Login</a></p>
        </div>
        <div class="grid grid-cols-2 gap-x-4 gap-y-6">
          <label>
            <span>Name <sup class="text-primary font-normal">*</sup></span>
            <input type="text" placeholder="First Name" v-model="register.name"/></label>
          <label class="self-end">
            <input type="text" placeholder="Last Name" /></label>
          <label class="col-span-2">
            <span>Email <sup class="text-primary font-normal">*</sup></span>
            <input type="text" /></label>
          <label class="col-span-2"><span>Cell Phone <sup class="text-primary font-normal">*</sup></span>
            <input type="text" /></label>
          <label>
            <span>Organization Type: <sup class="text-primary font-normal">*</sup></span>
            <select name="" id="">
              <option value="">Agency</option>
            </select>
          </label>
          <label><span>Organization Name <sup class="text-primary font-normal">*</sup></span>
            <input type="text" /></label>
          <label><span>Password <sup class="text-primary font-normal">*</sup></span>
            <input type="text" /></label>
          <label class="self-end">
            <input type="text" placeholder="Confirm Password" /></label>
          <label class="col-span-2 text-xs flex gap-2 pt-2">
            <input type="checkbox" id="audience" name="audience" value="audience">
            I agree to the Triad CTV <a href="" class="font-bold text-primary">Terms & Conditions</a> and <a href="" class="font-bold text-primary">Ad Policy & Guidelines</a>
          </label>
        </div>
        <button type="submit" class="button-primary max-w-fit mt-2">Create account</button>
      </div>
    </form>
</template>

<style scoped>

</style>
