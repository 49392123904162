import { createRouter, createWebHistory } from 'vue-router'

import { userRoutes, checkUserAuth } from '@/user/router-user';

import home from '../views/home.vue'
import accounts from '../views/accounts/accounts.vue'
import account from '../views/accounts/account.vue'
import advertisers from '../views/advertisers/advertisers.vue'
import advertiser from '../views/advertisers/advertiser.vue'
import reporting from '../views/reporting.vue'
import profile_view from '../views/profile.vue'
import account_settings_view from '../views/accountSettings.vue'
import user_management_view from '../views/userManagement.vue'
import campaigns from '../views/campaigns/campaigns.vue'
import audience from '../views/audience.vue'
import audienceAdmin from '../views/audienceAdmin.vue'
import creatives from '../views/creatives.vue'
import billing from '../views/billing/billing.vue'
import paymentMethods from '../views/billing/paymentMethods.vue'
import billingProfiles from '../views/billing/billingProfiles.vue'
import newCampaign from '../views/campaigns/newCampaign.vue'
import stepOne from '../views/campaigns/stepOne.vue'
import stepTwo from '../views/campaigns/stepTwo.vue'
import stepThree from '../views/campaigns/stepThree.vue'
import stepFour from '../views/campaigns/stepFour.vue'
import stepFive from '../views/campaigns/stepFive.vue'
import stepSix from '../views/campaigns/stepSix.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home',
      allowGuest: false,
    },
    component: home
  },
  {
    path: '/accounts/',
    name: 'accounts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: accounts
  },
  {
    path: '/accounts/:id',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: account
  },
  {
    path: '/advertisers/',
    name: 'advertisers',
    meta: {
      title: 'Advertisers',
      allowGuest: true,
    },
    component: advertisers
  },
  {
    path: '/advertisers/:id',
    name: 'advertiser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: advertiser,
    props:true
  },
  {
    path: '/campaigns/',
    name: 'campaigns',
    meta: {
      title: 'Campaigns',
      allowGuest: false,
    },
    component: campaigns
  },
  {
    path: '/audience/',
    name: 'audience',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: audience
  },
  {
    path: '/audience-admin/',
    name: 'audienceAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: audienceAdmin
  },
  {
    path: '/creatives/',
    name: 'creatives',
    meta: {
      title: 'Creatives',
      allowGuest: true,
    },
    component: creatives
  },
  {
    path: '/campaign-builder/',
    name: 'new_campaign',
    meta: {
      title: 'New Campaign',
      allowGuest: true,
    },
    component: newCampaign
  },
  {
    path: '/step-one',
    name: 'step_one',
    meta: {
      title: 'Step One',
      allowGuest: true,
    },
    component: stepOne
  },
  {
    path: '/step-two',
    name: 'step_two',
    meta: {
      title: 'Step Two',
      allowGuest: true,
    },
    component: stepTwo
  },
  {
    path: '/step-three',
    name: 'step_three',
    meta: {
      title: 'Step Three',
      allowGuest: true,
    },
    component: stepThree
  },
  {
    path: '/step-four',
    name: 'step_four',
    meta: {
      title: 'Step Four',
      allowGuest: true,
    },
    component: stepFour
  },
  {
    path: '/step-five',
    name: 'step_five',
    meta: {
      title: 'Step Five',
      allowGuest: true,
    },
    component: stepFive
  },
  {
    path: '/step-six',
    name: 'step_six',
    meta: {
      title: 'Step Six',
      allowGuest: true,
    },
    component: stepSix
  },
  {
    path: '/billing',
    name: 'billing',
    meta: {
      title: 'Billing',
      allowGuest: true,
    },
    component: billing
  },
  {
    path: '/payment-methods/',
    name: 'payment_methods',
    meta: {
      title: 'Payment Methods',
      allowGuest: true,
    },
    component: paymentMethods
  },
  {
    path: '/billing-profiles/',
    name: 'billing_profiles',
    meta: {
      title: 'Billing Profiles',
      allowGuest: true,
    },
    component: billingProfiles
  },
  {
  path: '/reporting/',
  name: 'reporting',
  meta: {
    title: 'Reporting',
    allowGuest: true,
  },
  component: reporting,
  props:true  
  },
  {
    path: '/profile/',
    name: 'profile_route',
    component: profile_view,
    props:true,
    meta: {
      title: 'Profile',
      allowGuest: false,
    },
  },
  {
    path: '/account-settings/:id?',
    name: 'account_settings_route',
    component: account_settings_view,
    props:true,
    meta: {
      title: 'Account Settings',
      allowGuest: false,
    },
  },

  {
    path: '/user-management/',
    name: 'user_management_route',
    component: user_management_view,
    props:true,
    meta: {
      title: 'User Management',
      allowGuest: false,
    },
  },
    ...userRoutes, // remove this if no login
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  next(checkUserAuth(to)); // remove this if no login
  // next();
});
export default router
