<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from './user/stores-auth'

import Layout from './Layout.vue'; // this is preloaded to make transition smooth
import LayoutLogin from './LayoutLogin.vue';

const route = useRoute();

const getLayout = () => {
  switch (route.meta.layout) {
    case 'LayoutLogin':
      return LayoutLogin;
    default:
      return Layout;
  }
};

// Validate API token for every refresh
onMounted(async () => {
  const authStore = useAuthStore();
  authStore.validateToken();
});

</script>
<template>
  <component :is="getLayout()" />
</template>
<style>
  #loading{
    display:none;
    opacity:0;
  }
  body.loading{
    position:relative;
  }
  #loading{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,.7);
    transition:1s all;
  }
  .loading #loading{
    opacity:1;
    display:block;
    z-index:999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-uploads label{
    cursor:pointer !Important;
  }
  button.bg-primary, a.bg-primary{
    font-weight: bold !important;
  }
</style>