<script setup>
import { RouterLink } from "vue-router"
import { ref, onMounted } from "vue"
import { useAuthStore } from '@/user/stores-auth';
import { useTriadStore } from '@/user/triad-store';

const triadStore    = useTriadStore();
const authStore     = useAuthStore();
const profile       = ref([]);
const hideMenu      = ref(1);




const logout = async () => {
 // emit('loading', true);
  document.body.classList.add("loading");
    await authStore.logout();
  document.body.classList.remove("loading");
  //emit('loading', false);
};
const alex = () =>{
  hideMenu.value =1;
}
onMounted(() => {
  
});
</script>
<template>
  <div style="display:none;">
    <RouterLink active-class="active" to="/">Home</RouterLink>
  </div>
  <!-- TOP BAR -->
  <div id="top-bar"
    class="bg-black text-white flex justify-between items-center h-[60px] lg:h-[70px] px-4 lg:px-8 relative z-10">
    <button class="border-0 p-0">
      <a href="/">
        <img src="/assets/logo.png" alt="" class="w-auto h-[30px] lg:h-[45px]" />
      </a>
    </button>
    <div class="flex items-center gap-4 relative" v-click-outside="alex">
      <span class="inline-block font-extrabold">Welcome, {{ triadStore.profile.user_first_name }}!</span>
      <button @click.prevent="hideMenu= !hideMenu">
        <span style="background:#fff;"
          class="inline-block w-[30px] lg:w-[45px] h-[30px] lg:h-[45px] rounded-full border border-white overflow-hidden align-middle">
          <img
            :src="profile.profile_picture_url" alt="" class="w-full h-full object-cover object-center"  v-if="profile.profile_picture_url"/>
            <img src="/assets/avatar.png" alt="" class="w-full h-full object-cover object-center"  v-if="!profile.profile_picture_url"/>
          
          </span>
      </button>
      <div id="account-menu"
        class="absolute top-full right-0 bg-light rounded shadow-lg px-4 py-4 min-w-max mt-8  z-10" :class="{hidden:hideMenu}">
        <span
          class="absolute -top-[12px] right-8 w-0 h-0 border-t-0 border-r-[10px] border-b-[12px] border-l-[10px] border-t-transparent border-r-transparent border-b-light border-l-transparent"></span>
        <nav class="text-black flex flex-col font-bold">
          <a href="/profile" class="hover:text-primary transition-colors block py-1 px-2">Your Profile</a>
          <a href="/user-management" class="hover:text-primary transition-colors block py-1 px-2">User Management</a>
          <a href="/account-settings" class="hover:text-primary transition-colors block py-1 px-2">Account Settings</a>
          <hr class="border-black my-2" />
          <a href="#" class="hover:text-primary transition-colors block py-1 px-2" @click.prevent="logout">Log out
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
              class="inline-block fill-primary align-middle stroke stroke-primary w-4 h-4 object-contain ml-2 mb-[2px]">
              <path
                d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z" />
            </svg>
          </a>
        </nav>
      </div>
    </div>
    <button id="hamburger"
      class="hamburger hamburger--arrowalt absolute left-4 lg:left-8 top-full mt-4 lg:mt-4 z-10 is-active" type="button"
      onclick="
                  getElementById('side-bar').classList.toggle('w-0'); 
                  getElementById('side-bar').classList.toggle('w-72'); 
                  getElementById('hamburger').classList.toggle('is-active');
                  getElementById('advertisers').classList.add('hidden');
                  ">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
      <span class="text-black ml-3 font-bold mb-1">Collapse</span>
    </button>
  </div>
  <!-- END OF TOP BAR -->
</template>

<style></style>