<template>
  <!-- TWO COLUMNS -->

  <div class="pb-4 mb-4 px-4 lg:px-8">
    <div class="flex items-end mb-4 gap-4">
      <h2 class="font-bold">Draft Campaigns</h2>
      <RouterLink to="campaigns" class="uppercase text-primary text-xs font-bold ml-auto shrink-0 mb-[2px]">All campaigns <i class="fa-sharp fa-regular fa-arrow-right ml-1"></i></RouterLink>
    </div>
    <div class="table-container">
      <table>
        <tr class="thead-tr text-left">
          <th class="w-4/12">
            Campaign Name
            <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button>
          </th>
          <th class="w-2/12">
            Flight Dates
            <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button>
          </th>
          <th class="w-6/12">
            Campaign Status
            <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                class="fa-duotone fa-sort"></i></button>
          </th>
        </tr>
        <tr class="tbody-tr hidden">
          <td>
            <span class="block">Chicago Wolves 4 Day FEB Campaign</span>
            <span class="block"><span class="font-bold uppercase">Media:</span> CTV, Display</span>
          </td>
          <td>
            <span>01/25/23</span> - <span>01/28/2023</span>
          </td>
          <td>
            <form class="fieldset">
              <label>
                <input type="checkbox" id="audience" name="audience" value="audience" checked>
                Audience
              </label>
              <label>
                <input type="checkbox" id="ctv" name="ctv" value="ctv" checked>
                CTV
                <sup class="text-[.45rem]">TM</sup>
              </label>
              <label>
                <input type="checkbox" id="creative" name="drone" value="creative">
                Creative
              </label>
              <label>
                <input type="checkbox" id="review" name="drone" value="review">
                Review
              </label>
              <label>
                <input type="checkbox" id="purchase" name="drone" value="purchase">
                Purchase
              </label>
            </form>
            <button href="#" class="uppercase text-primary text-xs font-bold mt-1">Edit Campaign</button>
          </td>
        </tr>
        <tr class="tbody-tr hidden">
          <td>
            <span class="block">Chicago Wolves 28 Day MAR Campaign</span>
            <span class="block"><span class="font-bold uppercase">Media:</span> CTV, Display</span>
          </td>
          <td>
            <span>01/25/23</span> - <span>01/28/2023</span>
          </td>
          <td>
            <form class="fieldset">
              <label>
                <input type="checkbox" id="audience" name="audience" value="audience" checked>
                Audience
              </label>
              <label>
                <input type="checkbox" id="ctv" name="ctv" value="ctv" checked>
                CTV
                <sup class="text-[.45rem]">TM</sup>
              </label>
              <label>
                <input type="checkbox" id="creative" name="drone" value="creative">
                Creative
              </label>
              <label>
                <input type="checkbox" id="review" name="drone" value="review">
                Review
              </label>
              <label>
                <input type="checkbox" id="purchase" name="drone" value="purchase">
                Purchase
              </label>
            </form>
            <button href="#" class="uppercase text-primary text-xs font-bold mt-1">Edit Campaign</button>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="flex gap-5  px-4 lg:px-8">
    <div class="grow shrink">
      <div class="flex items-end mb-4 gap-4">
        <h2 class="font-bold">Upcoming Payments</h2>
        <RouterLink to="billing" class="uppercase text-primary text-xs font-bold ml-auto shrink-0 mb-[2px]">Billing <i class="fa-sharp fa-regular fa-arrow-right ml-1"></i></RouterLink>
      </div>
      <div class="table-container">
        <table>
          <tr class="thead-tr text-left">
            <th class="w-1/4">
              Date
            </th>
            <th class="w-2/4">
              Campaign
            </th>
            <th class="w-1/4">
              Amount
            </th>
          </tr>
          <tr class="tbody-tr hidden">
            <td>
              <span>02/01/23</span>
            </td>
            <td>
              <span class="text-ellipsis overflow-hidden whitespace-nowrap block">Chicago Wolves 30 Day…</span>
            </td>
            <td>
              <span>$2,500</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="w-[260px] shrink-0">
      <h2 class="font-bold mb-4">CTV News</h2>
      <div class="shadow-[0px_3px_6px_#00000029]">
        <iframe class="w-full aspect-video" src="https://www.youtube.com/embed/h7pFTu_SjE8" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div class="bg-white p-4">
          <p class="font-bold text-sm">Connected TV is Now the #1 Most Consumed Medium in America</p>
          <button href="#" class="uppercase text-primary text-xs font-bold mt-1">Learn More <i
              class="fa-solid fa-arrow-right"></i></button>
        </div>
      </div>
    </div>
    <div id="helpinghand" class="max-w-xs flex flex-row shrink h-fit mt-10">
      <div class="bg-light relative mr-5">
        <span
          class="absolute top-8 left-full ml-0 w-0 h-0 border-t-[16px] border-r-0 border-b-[16px] border-l-[16px] border-t-transparent border-r-transparent border-b-transparent border-l-light"></span>
        <div class="p-5 text-sm">
          <p class="font-bold">
            Hi. I'm Alex Smith! Your dedicated account representative.
          </p>
          <p>
            Questions about the platform? Need help with an upcoming or active campaign? Reach out!
          </p>
        </div>
        <a href="mailto:asmith@triadctv.com" class="bg-primary text-white font-semibold text-sm py-2 text-center w-full block mt-auto">
          <i class="fa-solid fa-envelope mr-1 align-middle"></i>
          asmith@triadctv.com
        </a>
      </div>
      <img src="assets/avatar.png" alt="" class="w-[100px] h-[100px] aspect-square rounded-full order-last" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  setup() {

  }
}
</script>
