<script setup>
import { ref, onMounted } from "vue";
import { api } from '../helpers';

const campaigns         = ref([]);
const campaign          = ref({});
const shareReport       = ref(false);
const showLinkMessage   = ref(0);
const advertiser        = ref({});
const advertisers       = ref([]);
const currentAdvertiser = ref(0);
const user_id           = localStorage.getItem('userId');

const currentAdvertiserObj = JSON.parse(localStorage.getItem('currentAdvertiser'));
if(currentAdvertiserObj){
  currentAdvertiser.value = currentAdvertiserObj.id;
}

const copy_clipboard = () =>{
  try {
    navigator.clipboard.writeText(campaign.value.report);
    showLinkMessage.value = 1;
  } catch($e) {
  
  }
};
const get_campaigns = async () => {
  document.body.classList.add("loading");
    const response = await api.get('/get_campaigns?advertiser_id='+ advertiser.value.id);
  document.body.classList.remove("loading");
  campaigns.value = response.data.data;
};

onMounted( async () => {  
    if(currentAdvertiserObj){
      document.body.classList.add("loading");
        const response  = await api.get('/get_campaigns?advertiser_id='+ currentAdvertiserObj.id);
        campaigns.value = response.data.data;
      document.body.classList.remove("loading");
    }else{
      document.body.classList.add("loading");
        const response    = await api.get('/get_advertisers?user_id=' + user_id);
        advertisers.value = response.data.data;
      document.body.classList.remove("loading");
    }
});

</script>
<template>
  <div class="reporting" style="padding:50px;">
    <div class="w-full px-4 lg:px-8">
        <div class="">
          <label for="" class="lg:flex items-center justify-center max-w-fit mx-auto gap-8" v-if="!currentAdvertiser">
            <span class="font-bold pt-1"> Filter by Advertiser</span>
            <select name="select" class="max-w-full w-[400px] shadow-[0px_4px_8px_rgba(0,0,0,.15)] p-3 mt-2" v-model="advertiser" @change="get_campaigns">
              <option value="0">Select Advertiser</option>
              <option v-for="item in advertisers" :value="item"  :key="item.id">{{ item.name }}</option>
            </select>
          </label>
          <label for="" class="lg:flex items-center justify-center max-w-fit mx-auto gap-8">
            <span class="font-bold pt-1"> Filter by Campaign</span>
            <select name="select" class="max-w-full w-[400px] shadow-[0px_4px_8px_rgba(0,0,0,.15)] p-3 mt-2" v-model="campaign">
              <option value="0">Select a Campaign</option>
              <option v-for="item in campaigns" :value="item"  :key="item.id">{{ item.name }}</option>
            </select>
          </label>
        </div>
        <div class="mx-auto mt-8 lg:mt-12">
       
          <p class="text-center mb-4" v-if="!campaign.name">
            Please select an advertiser and/or campaign to view reporting.

          </p>
          <div v-else >
          
            <div v-if="campaign.report">
              <button class="mx-auto font-semibold uppercase text-center max-w-fit block mb-10 hover:text-primary" @click.prevent="shareReport = !shareReport">
                Share report
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-4 h-4 object-contain inline-block fill-primary align-middle ml-1 mb-[2px]">
                  <path d="M352 224H305.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144h80V34.7C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9L548.1 133.3c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2H384c-17.7 0-32-14.3-32-32V224zM80 96c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V384c0-17.7 14.3-32 32-32s32 14.3 32 32v48c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112C0 67.8 35.8 32 80 32h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H80z"></path>
                </svg>
              </button>
              <div class="" v-if="shareReport">
                <label for="" class="font-medium" @click.prevent="copy_clipboard">
                  <input type="text" class="shadow-[0px_4px_8px_rgba(0,0,0,.15)] w-full p-3 mt-2"  v-model="campaign.report" :disabled="true"  />
                 
                  <small v-if="showLinkMessage">Link copied to clipboard!</small>
                </label>
              </div>
              <iframe  width="600" height="875" :src="campaign.report" frameborder="0" style="border:0; width:100%;" allowfullscreen="" ></iframe>
            </div>
            <div class="text-center " v-else>
              No reports available
            </div>
            
          </div>  
        </div>
      </div>
  </div>

</template>


<style >
.reporting #helpinghand{
  display:none !important;
}</style>
