<script setup>
import { onMounted, ref, watch  } from 'vue';
import { api } from '../../helpers';
import creativeLibrary from "../creatives.vue"
import { useTriadStore }    from '@/user/triad-store';
import { useRoute, useRouter } from 'vue-router';

const route           = useRoute();
const router          = useRouter();
const triadStore      = useTriadStore();
const audiences               = ref([]);
const viewByFlights           = ref(0);
const showCreativeModal       = ref(0); 
const showAdGroupFreqModal    = ref(0); 
const showFlightModal         = ref(0);
const showPreviewModal        = ref(0);
const currentCreative         = ref({});
const showOverwrites          = ref(false);

triadStore.currentCampaign.currentAdgroupIndex = -1;

const setAdgroupCreative = (creative) => {

  if(triadStore.currentCampaign.currentAdgroupIndex >-1){
    if(!Array.isArray(triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].creatives)){
      triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].creatives = [];
    }
    
    triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].creatives.push(creative);
  }
  showCreativeModal.value=0;
};

const previewCreative  = (creative) => {
  currentCreative.value   = creative;
  showPreviewModal.value  = 1;
}

const addFlight = () =>{
  let lastIndex     = triadStore.currentCampaign.flights.length-1;
  let current       = new Date(triadStore.currentCampaign.flights[lastIndex].endDate); 
  let followingDay  = new Date(current.getTime() + 86400000); // + 1 day in ms

  triadStore.currentCampaign.flights.push({
    startDate:followingDay.toISOString().substring(0,10), 
    endDate:'',  
    budget: [],
    adgroups:[],
    lockedBudget: 0,
    totalBudget:0,
  });
}

const addAdgroup = () => {
  triadStore.currentCampaign.adgroups.push({creatives:[]});
  let lastIndex = triadStore.currentCampaign.adgroups.length - 1;
  if(triadStore.currentCampaign.flights.length == 1){
    triadStore.currentCampaign.flights[0].adgroups.push(lastIndex);
  }
}

const formatDate = (date) =>{
  if(date){
    return moment(date).format('MMMM DD, YYYY');
  }else{
    return ''
  }
}

const formatDateSimple = (date)=> {
  if(date){
    return moment(date).format('MM/DD/YYYY');
  }else{
    return ''
  }
}

const getAudienceName = (audienceId) => {
  let name = '';
  for(let i=0; i<  triadStore.currentCampaign.audiences.length; i++){
    if(audienceId == triadStore.currentCampaign.audiences[i].id){
      name =  triadStore.currentCampaign.audiences[i].name
    }
  }
  return name;
}

const showAdgroupFreqModalFunction = (adgroupIndex) =>{
  showAdGroupFreqModal.value  = 1; 
  triadStore.currentCampaign.currentAdgroupIndex = adgroupIndex;
  if(!triadStore.currentCampaign.adgroups[adgroupIndex].caps){
    triadStore.currentCampaign.adgroups[adgroupIndex].caps = [];
  }
}

const getCapTotalInMinutes = (cap) => {
  let value = 0;
  if(cap.grain == 'minute'){
    value = cap.target_value / cap.interval_value;
  }
  if(cap.grain == 'hour'){
    value =  cap.target_value*1 /(cap.interval_value*60 );
  }
  if(cap.grain == 'day'){
    value =  cap.target_value*1 /(cap.interval_value*1440 );
  }
  if(cap.grain == 'week'){
    value =  cap.target_value*1 /(cap.interval_value*10080 );
  }
  if(cap.grain == 'month'){
    value =  cap.target_value*1 /(cap.interval_value*43800 );
  }
  if(cap.grain == 'lifetime'){
    value =  cap.target_value;
  }
  cap.value = value;
  return value;
}

const validateFlights = () => {
  let lastEndDate     = '';
  let valid           = true;
  
  for(let i=0; i <  triadStore.currentCampaign.flights.length; i++ ){
    triadStore.currentCampaign.flights[i].message ='';
    //first validate start Date 
     
      if(lastEndDate){
        if(triadStore.currentCampaign.flights[i].startDate <= lastEndDate){
          triadStore.currentCampaign.flights[i].message = 'Start Date should be after the previus flight end date';
          valid= false;
        }
      }

      lastEndDate   = triadStore.currentCampaign.flights[i].endDate;
    }
}

const getAdgroupTotalBudget = (adgroupIndex) =>{
  let totalBudget = 0;
  for(let i= 0; i < triadStore.currentCampaign.flights.length; i++ ){
    if(triadStore.currentCampaign.flights[i].budget[adgroupIndex] >0 ){
      totalBudget = totalBudget*1 + triadStore.currentCampaign.flights[i].budget[adgroupIndex]*1;
    }
  }

  if(totalBudget < triadStore.currentCampaign.appliedSettings.minPrice){
    //alert('Ad Group budget should be at least '+triadStore.currentCampaign.appliedSettings.minPrice);
    //return false;
  }
  return totalBudget;
}

const checkFlightBudget = (flight, event) => {
 let lockedValue    = 0;
 let flightBudget   = flight.totalBudget *1;

 if(event.target.checked){
  let flightTotalAdgroupsBudget = getFlightTotalAdgroupsBudget(flight);

  if(flightBudget >= flightTotalAdgroupsBudget){
    lockedValue    = 1;
  }else{
    alert('Can not lock budget because the total budget of the Ad Groups is greater than the flight budget');
    lockedValue    = 0;
  }
 }
 event.target.checked = lockedValue;
 flight.lockedBudget = lockedValue;
}

const changeBudget  = (adgroupIndex, event, flight) =>{
  /*
  let oldValue      = 0;
  let newValue      = event.target.value *1;
  let flightBudget  = flight.totalBudget *1;
  let setValue      = 0;

  if(flight.budget[adgroupIndex]){
    oldValue = flight.budget[adgroupIndex]*1;
  }

  if(flight.lockedBudget*1 > 0){
    let flightTotalAdgroupsBudget = getFlightTotalAdgroupsBudget(flight);
    let newTotalAdgroupsBudget    = flightTotalAdgroupsBudget + newValue - oldValue;

    if(flightBudget >= newTotalAdgroupsBudget){
      setValue = newValue;
    }else{
      alert('Flight budget locked, please pick a lesser amount or increase flight budget');
      setValue = 0;
    }

  }else{
    setValue = newValue;
  }
  */
  let setValue                = event.target.value;


  flight.budget[adgroupIndex] = setValue;
  event.target.value          = setValue;
  flight.totalBudget          = getFlightTotalAdgroupsBudget(flight);

}

const getFlightTotalAdgroupsBudget = (flight) =>{
  let totalBudget = 0;
  for(let i= 0; i < flight.budget.length; i++ ){
    if(flight.budget[i] >0 ){
      totalBudget = totalBudget*1 + flight.budget[i]*1;
    }
  }
  return totalBudget*1;
}

const back = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_two' });
  }, 1000);
}

const saveStepThree  = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_four' });
  }, 1000);
}

const deleteFlight  = (index) => {
  triadStore.currentCampaign.flights.splice(index, 1); 
  
}
const removeAdgroupFromFlight = (adgroupIndex, index) => {

  triadStore.currentCampaign.flights[index].budget.splice(adgroupIndex, 1); 

  for( let i=0; i< triadStore.currentCampaign.flights[index].adgroups.length; i++){

    if(adgroupIndex == triadStore.currentCampaign.flights[index].adgroups[i]){
      triadStore.currentCampaign.flights[index].adgroups.splice(i, 1); 
    }

  }
 
}

const deleteAdgroup = (adgroupIndex) => {
  for( let j=0; j< triadStore.currentCampaign.flights.length; j++){
    for( let i=0; i< triadStore.currentCampaign.flights[j].adgroups.length; i++){

      if(adgroupIndex == triadStore.currentCampaign.flights[j].adgroups[i]){
        triadStore.currentCampaign.flights[j].adgroups.splice(i, 1); 
      }

    }
  }
  triadStore.currentCampaign.adgroups.splice(adgroupIndex, 1); 

}

const getSettings = async() =>{
  //if(!triadStore.currentCampaign.settings){
    const response = await api.get('/get_settings?account_id='+triadStore.currentAccount.id+'&advertiser_id='+triadStore.currentAdvertiser.id+'&campaign_id=0');
    triadStore.currentCampaign.settings = response.data.data;

    triadStore.currentCampaign.defaultSettings = {
      'identityRate'          : triadStore.currentCampaign.settings.default.options_new_identity_resolution_fee_cpm,
      'margin'                : triadStore.currentCampaign.settings.default.options_new_gross_profit_margin,
      'privateMarketCPM'         : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_private_market_projected_media_cpm,
      'privateMarketSpendShare'  : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_private_market_default_spend_share,

      'openMarketCPM'         : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_open_market_projected_media_cpm,
      'openMarketSpendShare'  : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_open_market_default_spend_share,

      'thirdPartyDataCpm'     : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_1_estimated_3p_data_cpm,
      'ccFee'                 : triadStore.currentCampaign.settings.default.options_new_credit_card_processing_fee_percent,
      'minPrice'              : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_minimum_price,
      'minFirstPartyDataCoef' : triadStore.currentCampaign.settings.default.options_new_first_party_minimum_coefficient,
    };

    triadStore.currentCampaign.accountSettings = {
      //'identityRate'          : triadStore.currentCampaign.settings.account.options_new_identity_resolution_fee_cpm,
      'margin'                  : triadStore.currentCampaign.settings.account.overrides_0_custom_margin,
      //'privateMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_private_market_projected_media_cpm,
      'privateMarketSpendShare'  : triadStore.currentCampaign.settings.account.overrides_0_market_share_settings_private_market_spend_share,

      //'openMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_open_market_projected_media_cpm,
      'openMarketSpendShare'    : triadStore.currentCampaign.settings.account.overrides_0_market_share_settings_open_market_spend_share,
      'minPrice'                : triadStore.currentCampaign.settings.account.overrides_0_minimum_price,
      //'thirdPartyDataCpm'     : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_1_estimated_3p_data_cpm,
      //'ccFee'                 : triadStore.currentCampaign.settings.default.options_new_credit_card_processing_fee_percent
    };

    triadStore.currentCampaign.advertiserSettings = {
      //'identityRate'          : triadStore.currentCampaign.settings.account.options_new_identity_resolution_fee_cpm,
      'margin'                  : triadStore.currentCampaign.settings.advertiser.overrides_0_custom_margin,
      //'privateMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_private_market_projected_media_cpm,
      'privateMarketSpendShare'  : triadStore.currentCampaign.settings.advertiser.overrides_0_market_share_settings_private_market_spend_share,

      //'openMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_open_market_projected_media_cpm,
      'openMarketSpendShare'    : triadStore.currentCampaign.settings.advertiser.overrides_0_market_share_settings_open_market_spend_share,
      'minPrice'                : triadStore.currentCampaign.settings.advertiser.overrides_0_minimum_price,
      //'thirdPartyDataCpm'     : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_1_estimated_3p_data_cpm,
      //'ccFee'                 : triadStore.currentCampaign.settings.default.options_new_credit_card_processing_fee_percent
    };

    triadStore.currentCampaign.campaignSettings = {
      //'identityRate'          : triadStore.currentCampaign.settings.account.options_new_identity_resolution_fee_cpm,
      'margin'                  : triadStore.currentCampaign.settings.campaign.overrides_0_custom_margin,
      //'privateMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_private_market_projected_media_cpm,
      'privateMarketSpendShare'  : triadStore.currentCampaign.settings.campaign.overrides_0_market_share_settings_private_market_spend_share,

      //'openMarketCPM'         : triadStore.currentCampaign.settings.account.options_new_settings_by_medium_0_open_market_projected_media_cpm,
      'openMarketSpendShare'    : triadStore.currentCampaign.settings.campaign.overrides_0_market_share_settings_open_market_spend_share,
      'minPrice'                : triadStore.currentCampaign.settings.campaign.overrides_0_minimum_price,
      //'thirdPartyDataCpm'     : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_1_estimated_3p_data_cpm,
      //'ccFee'                 : triadStore.currentCampaign.settings.default.options_new_credit_card_processing_fee_percent
    };

    triadStore.currentCampaign.appliedSettings = {
      'identityRate'              : triadStore.currentCampaign.settings.default.options_new_identity_resolution_fee_cpm,
      'margin'                    : triadStore.currentCampaign.settings.default.options_new_gross_profit_margin,
      'privateMarketCPM'          : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_private_market_projected_media_cpm,
      'privateMarketSpendShare'   : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_private_market_default_spend_share,

      'openMarketCPM'             : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_open_market_projected_media_cpm,
      'openMarketSpendShare'      : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_open_market_default_spend_share,

      'thirdPartyDataCpm'         : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_1_estimated_3p_data_cpm,
      'ccFee'                     : triadStore.currentCampaign.settings.default.options_new_credit_card_processing_fee_percent,
      'minPrice'                  : triadStore.currentCampaign.settings.default.options_new_settings_by_medium_0_minimum_price
    };

    // get margin overwrites  first account then advertiser then campaign 
    if(triadStore.currentCampaign.accountSettings.margin >0 ){
      triadStore.currentCampaign.appliedSettings.margin = triadStore.currentCampaign.accountSettings.margin;
    }
    if(triadStore.currentCampaign.advertiserSettings.margin >0 ){
      triadStore.currentCampaign.appliedSettings.margin = triadStore.currentCampaign.advertiserSettings.margin;
    }
    if(triadStore.currentCampaign.campaignSettings.margin >0 ){
      triadStore.currentCampaign.appliedSettings.margin = triadStore.currentCampaign.campaignSettings.margin;
    }

    // get minPrice overwrites  first account then advertiser then campaign 
    if(triadStore.currentCampaign.accountSettings.minPrice >0 ){
      triadStore.currentCampaign.appliedSettings.minPrice = triadStore.currentCampaign.accountSettings.minPrice;
    }
    if(triadStore.currentCampaign.advertiserSettings.minPrice >0 ){
      triadStore.currentCampaign.appliedSettings.minPrice = triadStore.currentCampaign.advertiserSettings.minPrice;
    }
    if(triadStore.currentCampaign.campaignSettings.minPrice >0 ){
      triadStore.currentCampaign.appliedSettings.minPrice = triadStore.currentCampaign.campaignSettings.minPrice;
    }

    // get openMarketSpendShare overwrites  first account then advertiser then campaign 
    if(triadStore.currentCampaign.accountSettings.openMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.openMarketSpendShare = triadStore.currentCampaign.accountSettings.openMarketSpendShare;
    }
    if(triadStore.currentCampaign.advertiserSettings.openMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.openMarketSpendShare = triadStore.currentCampaign.advertiserSettings.openMarketSpendShare;
    }
    if(triadStore.currentCampaign.campaignSettings.openMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.openMarketSpendShare = triadStore.currentCampaign.campaignSettings.openMarketSpendShare;
    }

// get privateMarketSpendShare overwrites  first account then advertiser then campaign 
if(triadStore.currentCampaign.accountSettings.privateMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.privateMarketSpendShare = triadStore.currentCampaign.accountSettings.privateMarketSpendShare;
    }
    if(triadStore.currentCampaign.advertiserSettings.privateMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.privateMarketSpendShare = triadStore.currentCampaign.advertiserSettings.privateMarketSpendShare;
    }
    if(triadStore.currentCampaign.campaignSettings.privateMarketSpendShare >0 ){
      triadStore.currentCampaign.appliedSettings.privateMarketSpendShare = triadStore.currentCampaign.campaignSettings.privateMarketSpendShare;
    }


 // }

}
 const getAdGroupImpressions = (adGroup, index) =>{
  let identityRate            = triadStore.currentCampaign.appliedSettings.identityRate;
  let margin                  = triadStore.currentCampaign.appliedSettings.margin;
  let privateMarketCPM        = triadStore.currentCampaign.appliedSettings.privateMarketCPM;
  let openMarketCPM           = triadStore.currentCampaign.appliedSettings.openMarketCPM;
  let thirdPartyDataCpm       = triadStore.currentCampaign.appliedSettings.thirdPartyDataCpm;
  let privateMarketSpendShare = triadStore.currentCampaign.appliedSettings.privateMarketSpendShare;
  let openMarketSpendShare    = triadStore.currentCampaign.appliedSettings.openMarketSpendShare;
  let minPrice                = triadStore.currentCampaign.appliedSettings.minPrice;
  let identityRateFee         = 0;
  let uniquerFirstpartyRows   = 0;
  let thirdPartyGroupsCount   = 0;
  let privateImpressions      = 0;
  let openImpressions         = 0;
  let totalImpressions        = 0;
  let budget                  = 0;
  

  if(adGroup.overwrite ==1){
    if(adGroup.margin >0 ){
      margin = adGroup.margin;
    }
    if(adGroup.minPrice >0 ){
      minPrice = adGroup.minPrice;
    }
    if(adGroup.openMarketSpendShare >0 ){
      openMarketSpendShare = adGroup.openMarketSpendShare;
    }
    if(adGroup.privateMarketSpendShare >0 ){
      privateMarketSpendShare = adGroup.privateMarketSpendShare;
    }
  }

  for(let i=0; i< audiences.value.length; i++){
    if(audiences.value[i].id ==adGroup.audience ){
      uniquerFirstpartyRows   = audiences.value[i].uniquerFirstpartyRows;
      thirdPartyGroupsCount   = audiences.value[i].thirdPartyGroupsCount;
    }
  }
  budget          = getAdgroupTotalBudget(index);
  identityRateFee = identityRate * uniquerFirstpartyRows  /  1000;


 

  privateImpressions = ( (privateMarketSpendShare/100) * ( ( budget ) * (1-(margin/100) )) / (privateMarketCPM/1000 + thirdPartyDataCpm/1000*thirdPartyGroupsCount ) );
  openImpressions    = ( (openMarketSpendShare/100) * ( ( budget ) * (1-(margin/100) )) / (openMarketCPM/1000 + thirdPartyDataCpm/1000*thirdPartyGroupsCount ) );
  totalImpressions   = privateImpressions + openImpressions;

  return totalImpressions;
 }

onMounted(async () => {
  getSettings();
  watch(triadStore.currentCampaign.flights, (value) => {

    let lastIndex                          = triadStore.currentCampaign.flights.length-1;
    triadStore.currentCampaign.startDate   = triadStore.currentCampaign.flights[0].startDate;
    triadStore.currentCampaign.endDate     = triadStore.currentCampaign.flights[lastIndex].endDate;
    let totalCampaignBudget                = 0;

    for(let i= 0; i < triadStore.currentCampaign.flights.length; i++ ){
      totalCampaignBudget = totalCampaignBudget + getFlightTotalAdgroupsBudget(triadStore.currentCampaign.flights[i])*1;
    }
    triadStore.currentCampaign.budget = totalCampaignBudget;
    }, { deep: true }
  );

  const response2 = await api.get('/get_audiences?advertiser_id=' + triadStore.currentCampaign.advertiser);
  audiences.value = response2.data.data;



});
</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->

    <!-- STEP 3: Build Your Ad Groups -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Build Your Ad Groups</h2>
      </div>
      <div class="ml-auto flex items-start gap-10">
        <div>
          
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-full ">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Campaign Dates
            </div>
            <div class="px-5 text-center grow">
              {{ formatDate(triadStore.currentCampaign.startDate) }} - {{ formatDate(triadStore.currentCampaign.endDate) }}
            </div>
          </div>
          <button class="table-action hidden"  @click.prevent="showFlightModal =1">
            Manage Flights
          </button>
        </div>
        <div>
          <div class="relative">
            <input type="text" placeholder="Campaign Name" class="w-96" v-model="triadStore.currentCampaign.name">
            <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
              <i class="fa-sharp fa-solid fa-pen-to-square"></i>
            </button>
          </div>
          <div class="text-xs mt-2"><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-96">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Total Campaign Budget
            </div>
            <div class="px-5 text-center grow">
              {{ triadStore.currentCampaign.budget }}
            </div>
          </div>
        </div>
      </div>
      <!-- Tabalas de abajo -->
      <button class="table-action" @click.prevent="showOverwrites = !showOverwrites">
        Toggle Overwrites 
      </button>
      
      <div class="table-container" style="overflow-x: clip;" v-if="triadStore.currentCampaign.accountSettings" v-show="showOverwrites">
        ADMIN ONLY Default Settings
          <table>
            <tr class="thead-tr">
              <th>Medium</th>
              <th>Identity Rate</th>
              <th >Margin</th>
              <th >Open Market CPM</th>
              <th >Open Market Share</th>
              <th >Private Market CPM</th>
              <th >Private Market Share</th>
              <th >Third Party Data CPM</th>
              <th >First Party Data Coef.</th>
            </tr>
            <tr >
              <td>CTV</td>
              <td><input disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.identityRate"/></td>
              <td><input disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.margin"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.openMarketCPM"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.openMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.privateMarketCPM"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.privateMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.thirdPartyDataCpm"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.defaultSettings.minFirstPartyDataCoef"/></td>
            </tr>
          </table>
          <hr/>
          ADMIN ONLY Account Overwrites:
          <table>
            <tr class="thead-tr">
              <th>Medium</th>
              <th >Margin</th>
              <th >Min Price</th>
              <th >Open Market Share</th>
              <th >Private Market Share</th>
            </tr>
            <tr >
              <td>CTV</td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.accountSettings.margin"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.accountSettings.minPrice"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.accountSettings.openMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.accountSettings.privateMarketSpendShare"/></td>
            </tr>
          </table>
          <hr/>
          ADMIN ONLY Advertiser Overwrites:
          <table>
            <tr class="thead-tr">
              <th>Medium</th>
              <th >Margin</th>
              <th >Min Price</th>
              <th >Open Market Share</th>
              <th >Private Market Share</th>
            </tr>
            <tr >
              <td>CTV</td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.advertiserSettings.margin"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.advertiserSettings.minPrice"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.advertiserSettings.openMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.advertiserSettings.privateMarketSpendShare"/></td>
            </tr>
          </table>
          <hr/>
          ADMIN ONLY Campaign Overwrites:
          <table>
            <tr class="thead-tr">
              <th>Medium</th>
              <th >Margin</th>
              <th >Min Price</th>
              <th >Open Market Share</th>
              <th >Private Market Share</th>
            </tr>
            <tr >
              <td>CTV</td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.campaignSettings.margin"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.campaignSettings.minPrice"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.campaignSettings.openMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.campaignSettings.privateMarketSpendShare"/></td>
            </tr>
          </table>

          ADMIN ONLY Applied Settings
          <table>
            <tr class="thead-tr">
              <th>Medium</th>
              <th>Identity Rate</th>
              <th >Margin</th>
              <th >Open Market CPM</th>
              <th >Open Market Share</th>
              <th >Private Market CPM</th>
              <th >Private Market Share</th>
              <th >Third Party Data CPM</th>
            </tr>
            <tr >
              <td>CTV</td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.identityRate"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.margin"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.openMarketCPM"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.openMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.privateMarketCPM"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.privateMarketSpendShare"/></td>
              <td><input  disabled type="number" v-model="triadStore.currentCampaign.appliedSettings.thirdPartyDataCpm"/></td>
            </tr>
          </table>
          </div>

      <!-- Botones para cambiar de vistas en las tablas de abajo. Lo que cambian son las clases "bg-white" / "bg-light" cuando estén activos -->
      <div class="flex mt-6 text-sm ">
        <button class="button-secondary "  :class="{'bg-light': !viewByFlights}" @click.prevent="viewByFlights=0">
           Manage Ad Groups
        </button>
        <button class="button-secondary " :class="{'bg-light': viewByFlights}"  @click.prevent="viewByFlights=1" v-if="triadStore.currentCampaign.flights.length >1">
           Manage Flights
        </button> 
        <button class="text-primary uppercase text-xs px-5 py-2 font-bold" :class="{'bg-light': viewByFlights}" v-if="triadStore.currentCampaign.flights.length <=1" @click.prevent="viewByFlights=1" >
          New Flight <i
            class="fa-sharp fa-solid fa-plus fa-xs ml-1"></i>
        </button>
      </div>
      
      <!--view Adgroups BY Flights in ROWS-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="viewByFlights" >
        <div  v-for="(flight, index) in triadStore.currentCampaign.flights" :key="index" >
          <div class="table-container" style="margin-top:30px;">
            <table>
            <tr class="thead-tr">
              <th></th>
            <th></th>
              <th class="">Start Date</th>
              <th class="">End Date</th>
              <th class="">Budget</th>
            </tr>
            <tbody >
              <tr class="tbody-tr" >
                <td>
                  <span v-if="index> 0">
                    <button @click.prevent="deleteFlight(index)">Delete</button>
                  </span>
                </td>
                <td><button @click.prevent="triadStore.currentCampaign.flightIndex = index">View Ad Groups</button></td>
                <td><input type="date" v-model="flight.startDate"/></td>
                <td><input type="date" v-model="flight.endDate"/></td>
                <td>
                  {{ flight.totalBudget }}
                 <!-- Hiding the locking budget for now-->
                  <div v-if="0">
                    <input type="number" v-model="flight.totalBudget" /><br/>
                    <input type="checkbox" name="" id="" class="peer" value:="flight.lockedBudget" @change="checkFlightBudget(flight, $event)" true-value="1"
                      false-value="0" >
                    <i class="fa-sharp fa-solid fa-lock fa-sm peer-checked:hidden"></i>
                    <i class="fa-sharp fa-solid fa-lock-open fa-sm hidden peer-checked:inline"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <div class="table-container" style="padding:10px 30px;" :class="{hidden:index != triadStore.currentCampaign.flightIndex}">
                    <table>
                      <tr class="thead-tr">
                        <th></th>
                        <th class="w-1/12">Ad Group </th>
                        <th class="w-3/12">Audience</th>
                        <th class="w-4/12">Medium</th>
                        <th class="w-1/12">Impressions</th>
                        <th class="w-1/12">Budget <sup>
                          <i class="fa-solid fa-circle-question fa-xs"></i></sup>
                        </th>
                      </tr>
                      <tbody  v-for="(adgroup, adgroupIndex) in triadStore.currentCampaign.adgroups" :key="adgroupIndex" >
                        <tr class="tbody-tr" v-if="flight.adgroups.includes(adgroupIndex)" >
                          <td> 
                            <span>
                              <button @click.prevent="removeAdgroupFromFlight(adgroupIndex, index)">Delete</button>
                            </span>
                          </td>
                          <td>
                            {{ adgroup.name }}
                          </td>
                          <td>
                            {{ getAudienceName(adgroup.audience) }}
                          </td>
                          
                          <td>{{adgroup.medium}}</td>
                          <td>
                            {{adgroup.impressions}}
                          </td>
                          <td>
                              <span>
                                <input type="number" :value="flight.budget[adgroupIndex]"  @change="changeBudget(adgroupIndex, $event, flight)" />
                              </span>
                          </td>
                        </tr>
                        
                      </tbody>
                      <tr>
                          <td colspan="6"> 
                            <div style="width:30%; float:right; margin-top:15px;">
                              <select v-model="triadStore.currentCampaign.adGroupToAddIndex">
                                  <option disabled value="-1">Add Ad Group To Flight</option>
                                  <optgroup label=""  v-for="(adgroup, aindex)  in triadStore.currentCampaign.adgroups" :key="aindex"  >
                                    <option   v-if="(!flight.adgroups.includes(aindex))" :value="aindex">{{ adgroup.name }}</option>
                                  </optgroup>
                              </select>
                              <button class="button-primary ml-auto block mt-4" :disabled="!(triadStore.currentCampaign.adGroupToAddIndex >-1)" @click.prevent="flight.adgroups.push(triadStore.currentCampaign.adGroupToAddIndex); adGroupToAddIndex=-1 ">
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
        </div>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addFlight">
          New Flight
        </button>
      </div>

      <!-- View by Adgroups-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="!viewByFlights">

        <!-- Tabalas de abajo -->
        <div class="table-container">
          <table>
          <tr class="thead-tr">
            <th></th>
            <th class="w-1/12">Ad Group</th>
            <th class="w-3/12">Audience</th>
            <th class="w-2/12">Flights</th>
            <th class="w-4/12">Medium</th>
            <th class="w-4/12">Creative(s)</th>
            <th class="w-1/12">Impressions</th>
            <th class="w-1/12">HH Freq</th>
            <th class="w-1/12">Budget <sup>
              <i class="fa-solid fa-circle-question fa-xs"></i></sup>
            </th>
          </tr>
          <tbody v-for="(adgroup, adgroupIndex) in triadStore.currentCampaign.adgroups" :key="adgroupIndex">
          <tr class="tbody-tr" >
            <td> 
                <span>
                  <button @click.prevent="deleteAdgroup(adgroupIndex)">Delete</button>
                </span>
                <span>
                  <button @click.prevent="adgroup.overwrite=1">Overwrite</button>
                </span>
              </td>
            <td>
              <input type=text v-model="adgroup.name"/> 
            </td>
            <td>
              <select v-model="adgroup.audience">
                <option disabled value="">Please select one</option>
                <option v-for="audience in triadStore.currentCampaign.audiences" :value="audience.id">
                  {{ audience.name }}
                </option>
              </select>
              
            </td>
            <td>
                <div v-for="(flight, findex) in triadStore.currentCampaign.flights" :key="findex">
                    <div v-if="flight.adgroups.includes(adgroupIndex)">
                        {{ formatDateSimple(flight.startDate)  }} - {{ formatDateSimple(flight.endDate) }}
                    </div>
                </div>
            </td>
            
            <td>
              <label class="flex gap-3">
                <select v-model="adgroup.medium">
                  <option value="Large-Format CTV">Large-Format CTV</option>
                  <option value="CTVx">CTVx</option>
                  <option value="Display">Display</option>
                  <option value="Display Video">Display Video</option>
                </select>
                <button class="text-xs text-primary font-bold uppercase">Specs & Details</button>
              </label>
              <div class="text-xs mt-2 font-bold flex gap-3 hidden">
                <span>Add On:</span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> CTVx<sup>TM</sup></span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> Display</span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> Display Video</span>
              </div>
            </td>
            <td>
              <div class="mb-1 last-of-type:mb-2 " v-for="(creative, cindex) in adgroup.creatives" :key="creative.id">
                <button class="text-primary -ml-1 pl-1 pr-2"  @click.prevent=" adgroup.creatives.splice(cindex, 1);">
                  <i class="fa-solid fa-xmark"></i>
                </button>
                  {{ creative.name }}
                <div>
                  <button class="table-action" @click.prevent="previewCreative(creative)">
                    Preview
                  </button>
                  
                </div>
              </div>
             
              <button class="button-primary text-xs" @click.prevent="triadStore.currentCampaign.currentAdgroupIndex=adgroupIndex; showCreativeModal=1;" >
                Add Creative
              </button>
            </td>
            <td>
              {{ getAdGroupImpressions(adgroup,adgroupIndex ) }}
            </td>
            <td>
              <button class="table-action" @click.prevent="showAdgroupFreqModalFunction(adgroupIndex);">
                Edit 
              </button>
            </td>
            <td>
              <span v-if="triadStore.currentCampaign.flights.length ==1">
                <input type="number" :value="triadStore.currentCampaign.flights[0].budget[adgroupIndex]"  @change="changeBudget(adgroupIndex, $event, triadStore.currentCampaign.flights[0])" />
              </span>
              <span v-else >
                {{ getAdgroupTotalBudget(adgroupIndex) }}
              </span>
            </td>
          </tr>
          <tr v-if="adgroup.overwrite==1">
            <td></td>
            <td colspan="7">
              <table>
                <tr class="thead-tr">
                  <th>Medium</th>
                  <th >Margin</th>
                  <th >Min Price</th>
                  <th >Open Market Share</th>
                  <th >Private Market Share</th>
                </tr>
                <tr >
                  <td>CTV</td>
                  <td><input   type="number" v-model="adgroup.margin"/></td>
                  <td><input   type="number" v-model="adgroup.minPrice"/></td>
                  <td><input   type="number" v-model="adgroup.openMarketSpendShare"/></td>
                  <td><input   type="number" v-model="adgroup.privateMarketSpendShare"/></td>
                </tr>
              </table>
          </td>
        </tr>
        </tbody>
        </table>
        </div>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addAdgroup">
          Add Ad Group
        </button>
      </div>
      <button class="button-primary fixed bottom-0 left-0" @click.prevent="back(2);">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepThree">Next <i class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 3 -->


  </div>

  <!-- MODAL EXIT BUILDER -->
  <div id="modal-delete-user" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center justify-center mt-10 mb-4 gap-5 text-2xl">
        <i class="fa-solid fa-triangle-exclamation fa-2xl"></i>
        <div>
          <h2 class="text-xl lg:text-2xl font-bold">Are you sure you want to exit the builder?</h2>
          <p class="text-base">Don't forget to save your changes before you exit so you can come back to your draft later.
          </p>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Back to Builder
        </button>
        <button class="button-primary">
          Save Draft
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EXIT BUILDER -->



  <!-- MODAL CREATIVE LIBRARY -->
  <div id="" class="modal " :class="{hidden:!showCreativeModal}">
    <div class="modal-body max-w-6xl">
      <button class="close-modal" @click.prevent="showCreativeModal =0">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-4">Creative Library</h2>
      <creativeLibrary mode="builder"  @setCreative="setAdgroupCreative" />
    </div>
  </div>
  <!-- END OF MODAL CREATIVE LIBRARY -->


  <!-- MODAL VIEW FLIGHTS -->
  <div id="" class="modal "  :class="{hidden: !showFlightModal}"  v-if="showFlightModal">
    <div class="modal-body max-w-3xl">
      <button class="close-modal"  @click.prevent="showFlightModal =0">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-4">View Flights</h2>
      <div class="border-2 border-black flex items-center h-[42px] mt-4 max-w-fit mx-auto">
        <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow font-bold">
          Campaign Dates</div>
        <div class="px-5 text-center grow"> 
          {{triadStore.currentCampaign.startDate }} - {{triadStore.currentCampaign.endDate }}
        </div>
      </div>
      <div class="table-container mt-9">
        <table>
          
          <tr class="thead-tr">
            <th class="min-w-[17%]">Start Date</th>
            <th class="min-w-[17%]">End Date</th>
          </tr>
          <tbody v-for="(flight, index) in flights" :key="index">
          <tr class="tbody-tr" >
            <td><input type="date" v-model="flight.startDate"/></td>
            <td><input type="date" v-model="flight.endDate"/></td>
          </tr>
          <tr>
            <td colspan="2">
              <span class="text-red">{{ flight.message }}</span>
            </td>
          </tr>
        </tbody>
        </table>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addFlight">
          New Flight
        </button>
        <button class="button-primary ml-auto block mt-4" @click.prevent="saveFlights">
          Save Flights
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL VIEW FLIGHTS -->


 <!-- MODAL EXIT BUILDER -->
 <div id="modal-delete-user" class="modal " :class="{hidden: !showPreviewModal}" v-if="currentCreative">
    <div class="modal-body max-w-4xl">
      <button class="close-modal" @click.prevent="showPreviewModal = 0;">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center justify-center mt-10 mb-4 gap-5 text-2xl">
      
        <div>
          <iframe  v-if="currentCreative.meta && currentCreative.meta.file_url" style="width:500px; height:500px;" class=" aspect-video rounded-lg shadow-lg"
        :src="currentCreative.meta.file_url" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""></iframe>
        </div>
      </div>
      <div class="modal-actions">
       
      </div>
    </div>
  </div>
  <!-- END OF MODAL Freq AdGroup -->

    <!-- MODAL Select Role -->
    <div id="modal-confirm-delete" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "
    :class="{ hidden: !showAdGroupFreqModal }" v-if="triadStore.currentCampaign.currentAdgroupIndex>-1">
    <div style="max-width: 72rem;"
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow" @click.prevent="showAdGroupFreqModal=0"
       >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2>
        For each user, show a maximum of 
      </h2>
   
      <div class="grid grid-cols-6 col-span-4 gap-x-5 text-center" style="margin-top: 15px; margin-bottom:20px;"  v-for="(cap, capindex) in triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].caps">
        <label>
          <button class="table-action" @click.prevent="triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].caps.splice(capindex, 1)">
                    Delete
          </button>
          
        </label>
        <label>
          <input type="number" v-model="cap.target_value" placeholder="Ex: 10"/>
          
        </label>
        <label>Ads Per</label>
        <label><input type="number" v-model="cap.interval_value" placeholder="Ex: 60"/>
        </label>
        <label><select v-model="cap.grain">
              <option value="">Select Unit of measure ex: minutes, hours etc. </option>
              <option value="minute">Minutes</option>
              <option value="hour">Hours</option>
              <option value="day">Days</option>
              <option value="week">Weeks</option>
              <option value="lifetime">Lifetime</option>
            </select>
        </label>
        <label>{{ getCapTotalInMinutes(cap) }}</label>
        </div>
      
      <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="triadStore.currentCampaign.adgroups[triadStore.currentCampaign.currentAdgroupIndex].caps.push({})">Add Another Cap</button>
 
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
       
      </div>
    </div>
  </div>
  <!-- END Freq AdGroup  -->
</template>

<style></style>
