<template>
  <div class="advertiser">
    <h1>CHuchulukin  {{ id }}</h1>
  </div>
</template>
<script>
export default{
  name:'advertiser',
  props: ['id'],
  setup(){
   
  }
}

</script>
<style>
</style>