<script setup>
import { ref, onMounted, computed } from "vue";
import { api } from '../helpers';
import VueMultiselect from 'vue-multiselect'
import { useTriadStore }    from '@/user/triad-store'
const triadStore        = useTriadStore();
const props = defineProps({
     mode: {
         type: String,
         required: false,
         default: 'normal'
     },
});
const emit = defineEmits(['setCreative']);

const setCreative = (creative) =>{
  emit('setCreative', creative);
}
const creatives         = ref([]);
const search            = ref('');
const typeFilter        = ref('all');
const currentCreative   = ref({});
const showPreview       = ref(0);
const editCreative      = ref({});
const hideEditCreative  = ref(1);
const newCreative       = ref({});
const hideNewCreative   = ref(1);
const showDeleteConfirm = ref(0);
const upload            = ref(null);

const mediaChannels = [
  { name: 'ctv', label: 'CTV' },
  { name: 'ctvx', label: 'CTVx' },
  { name: 'display', label: 'Display' }, 
  { name: 'display_video', label: 'Display Video' }, 
];

//const currentAdvertiserObj = JSON.parse(localStorage.getItem('currentAdvertiser'));

const get_creatives = async () => {
  document.body.classList.add("loading");
    const response  = await api.get('/get_creatives?advertiser_id=' + triadStore.currentAdvertiser.id);
    creatives.value = response.data.data;
  document.body.classList.remove("loading");
};

const isImage = (url) => {
  return url.toString().toLowerCase().includes('png');
}

const filteredCreatives = computed(() => {
  return creatives.value.filter((val) => {
    let foundType   = true;
    let foundSearch = true;
    if(typeFilter.value != 'all'){
      foundType = val.creative_type.toString().toLowerCase() == typeFilter.value 
    }
    if(search.value){
      foundSearch = val.name.toString().toLowerCase().includes(search.value);
    }
  
    return foundSearch && foundType
  });
});

const deleteCreative  = async (creative) => {
  try {
    document.body.classList.add("loading");
      const response  = await api.post('/delete_creatives',{creatives:[creative]});
      get_creatives();
    document.body.classList.remove("loading");
    showDeleteConfirm.value = 0;
   
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const saveCreative  = async (creative) => {
  try {
    creative.advertiser_id = triadStore.currentAdvertiser.id;
    document.body.classList.add("loading");
      const response  = await api.post('/save_creatives',{creatives:[creative]});
      get_creatives();
    document.body.classList.remove("loading");
    hideNewCreative.value   = 1;
    hideEditCreative.value  = 1;
    
    return response;
  } catch (error) {
    console.log(error);
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}
const addCustomParameter = (editCreative) => {
  if(editCreative.custom_params.length <= 3){
    editCreative.custom_params.push({key:'', value:''});
  }
}

onMounted(async () => {
  if (triadStore.currentAdvertiser.id) {
    get_creatives();
  } else {

  }
});
const inputFile =  async (newFile, oldFile)=> {
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    // Get response data
    console.log('response', newFile.response)
    if (newFile.xhr) {
      //  Get the response status code
      console.log('status', newFile.xhr.status)
    }

  }

  let formData = new FormData();
  formData.append('filepond', newFile.file);
    
  const response = await api.post('/file_upload', 
    formData
    , {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }
  );
  newFile.file_url = response.data.data;
    
};

const inputFilter =  function (newFile, oldFile, prevent) {
  if (newFile && !oldFile) {
    // Filter non-image file
    if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
      //return prevent()
    }
  }
  if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
    // Thumbnails
    newFile.blob = ''
    let URL = (window.URL || window.webkitURL)
    if (URL) {
      newFile.blob = URL.createObjectURL(newFile.file)
    }
    newFile.thumb = ''
    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
      newFile.thumb = newFile.blob
    }
  }
    //get image size
   
    if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
      newFile.error = 'image parsing'
      let img       = new Image();
      img.onload    = () => {
        upload.value.update(newFile, {error: '', height: img.height, width: img.width})
      } 
      img.οnerrοr    = (e) => {
        upload.value.update(newFile, { error: 'parsing image size'}) 
      }
      img.src = newFile.blob
    }
 //get Video size
    if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "video/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
   
      newFile.error   = 'image parsing'
    
      var video = document.createElement("video");
      video.setAttribute("src", newFile.blob);
      video.load();

      video.addEventListener('loadeddata', (event) => {
     
        let bitrate = newFile.file.size / (video.duration * .000125); 
        upload.value.update(newFile, {error: '', height: video.videoHeight, width: video.videoWidth, bitrate:bitrate.toFixed(2) })
      });
    }
};

</script>

<template>
  <div>
   <div v-show="upload && upload.dropActive" class="drop-active">
		<h3>Drop files to upload</h3>
  </div>
  
  <div class="px-4 lg:px-10" :class="{hidden:!hideNewCreative || !hideEditCreative }">
    <div class="w-full flex flex-col md:flex-row gap-4 lg:gap-5 md:mb-5">
      <label class="shrink-0 w-3/12">
        <span class="font-bold">Type</span>
        <select v-model="typeFilter">
          <option value="all"> All</option>
          <option value="video">Video</option>
          <option value="image">Image</option>
          <option value="html5">HTML5</option>
        
        </select>
      </label>
     
      <label class="relative grow">
        <span class="text-white">.</span>
        <input placeholder="Search" type="text" v-model="search" />
        <i class="fa-solid fa-magnifying-glass absolute top-1/2 -translate-y-1/2 right-4 text-primary" style="top:70%;"></i>
      </label>
      <button class="button-primary" @click.prevent="hideNewCreative = 0; newCreative = {files:[], serving_type:'hosted', custom_params:[], type:'video'}" style="height: 40px;
    margin-top: 30px;">
        Add New Creative 
      </button>
    </div>
    <div class="table-container">
      <table>
        <tr class="thead-tr">
          <th class="w-2/12 " v-if="mode!='normal'">Select</th>
          <th class="w-2/12 ">Preview</th>
          <th class="w-6/12">
            Creative Name
          </th>
          <th class="w-1/12">
            Type
          </th>
          <th class="w-1/12">
            Size
          </th>
          <th class="w-2/12" v-if="mode=='normal'">
            Assigned Ad Groups
          </th>
        </tr>
        <tr class="tbody-tr" v-for="creative in filteredCreatives" :key="creative.id">
          <td v-if="mode!='normal'">
            <button @click.prevent="setCreative(creative)" class="button-primary">Select</button>
          </td>
          <td class="text-center">
            

            <button class="relative" @click.prevent="currentCreative = creative; showPreview = 1;" v-if="creative.meta.file_url">
              <img v-if="!isImage(creative.meta.file_url)" src="https://via.placeholder.com/100x100" alt=""
                class="max-w-full w-full" />
              <img v-if="isImage(creative.meta.file_url)" :src="creative.meta.file_url" alt=""
                class="max-w-full w-full" />
              <i
                class="fa-regular fa-circle-play fa-2xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white drop-shadow-md"></i>
            </button>
          </td>
          <td>
            <span class="block">{{ creative.name }}</span>
            <a href="#" class="table-action" @click.prevent="editCreative = creative; hideEditCreative = 0">
              Edit
            </a>
            <a href="#" class="table-action" @click.prevent="editCreative = creative; showDeleteConfirm=1;">
              Delete
            </a>
          </td>
          <td>
            <span>{{ creative.creative_type }}</span>
          </td>
          <td>
            <span>{{ creative.file_size_kb }} MB</span>
          </td>
          <td  v-if="mode=='normal'">
            <span class="text-primary block font-bold" v-for="adgroup in creative.adgroups">
                  {{ adgroup.name }}
              </span>
          </td>
        </tr>

      </table>
    </div>
  </div>

  <div class="px-10" :class="{hidden: hideNewCreative}" v-if="newCreative.files">
  
    <button class="uppercase font-bold text-sm" @click.prevent="hideNewCreative = 1">
      <i class="fa-solid fa-arrow-left text-primary mr-1"></i> Back to Creative
      Library</button>
    <div class="max-w-4xl mx-auto">
      <h2 class="text-3xl font-bold mb-6 text-center">Add New Creative</h2>
      <form class="grid grid-cols-4 gap-5">
        <label class="col-span-4">
          <span>Name <sup>*</sup></span>
          <input type="text" v-model="newCreative.name" />
        </label>
        
        <label class="col-span-2">
          <span>Type <sup>*</sup></span>
          <select name="" v-model="newCreative.type"> <!-- Read from creative choices-->
            <option value="video">Video</option>
            <option value="image">Image</option>
            <option value="html5">HTML5</option>
          </select>
        </label>
        <label class="col-span-2">
          <span>Media Channel <sup>*</sup></span>
          <VueMultiselect v-model="newCreative.media_channel" :options="mediaChannels" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                placeholder="Select media channel" label="label" track-by="name" :preselect-first="false">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
          </VueMultiselect>
        </label>
        <label class="relative flex  cursor-pointer col-span-1 pt-4" >
          <span style="margin-right:5px;">Hosted (Upload File)</span>
          <input type="radio" id="one" value="hosted" v-model="newCreative.serving_type" />
          
        </label>
        <label class="relative flex items-center justify-center gap-1 cursor-pointer col-span-1 pt-4">
          <span>Third Party</span>
          <input type="radio" id="one" value="third-party" v-model="newCreative.serving_type" />
        </label>
 
        <label class="col-span-4" v-if="newCreative.serving_type=='third-party'">
          <span>VAST URL <sup>*</sup></span>
          <input type="text" v-model="newCreative.third_party_tag_vast_url">
        </label>

        <label class="col-span-4 -mb-3" v-if="newCreative.serving_type=='hosted'">
          <span>Upload File <sup>*</sup></span>
          <div class="bg-light py-6 text-center text-sm hoverhand" >
         
              <file-upload 
                  class=""
                    ref="upload"
                    v-model="newCreative.files"
                    :drop="true"
                    @input-file="inputFile"
                    @input-filter="inputFilter"       
              >
            
              Drag & drop your files or click to select
             </file-upload>
            
          </div>
        </label>
        <div class="grid grid-cols-4 col-span-4 gap-x-5" v-if="newCreative.files.length > 0">
            <div v-if="newCreative.files[0].type.substr(0, 6) === 'image/'">
              <img :src="newCreative.files[0].blob"  style="width:100%; height:auto;"/>
            </div>
            <div v-if="newCreative.files[0].type.substr(0, 6) === 'video/'">
              <video :src="newCreative.files[0].blob"  style="width:100%; height:auto;"/>
            </div>
            
        </div>
        <div class="grid grid-cols-4 col-span-4 gap-x-5" v-if="newCreative.files.length>0">
          <label>
            <span class="text-sm font-normal">Size</span>
            <input type="text" v-model=" newCreative.files[0].size" :disabled="true" />
          </label>
          <label>
            <span class="text-sm font-normal">Bitrate</span>
            <input type="text" v-model=" newCreative.files[0].bitrate" :disabled="true" />
          </label>
          <label>
            <span class="text-sm font-normal">Width</span>
            <input type="text" v-model=" newCreative.files[0].width" :disabled="true" />
          </label>
          <label>
            <span class="text-sm font-normal">Height</span>
            <input type="text" v-model=" newCreative.files[0].height" :disabled="true" />
          </label>
        
        </div>
        <div style="border:1px solid #ccc; border-radius:15px; padding:10px" class="col-span-4">
        <label class="col-span-4">
          <span>Landing Page <sup>*</sup></span>
          <input type="text" v-model="newCreative.landing_page_url"  placeholder="https://www.your-website.com/landing-page"/>
        </label>
        <div class="grid grid-cols-4 col-span-4 gap-x-5" style="margin-top:15px">
          <span class="col-span-4 font-bold">Tracking Info</span>
          <label>
            <span class="text-sm font-normal">UTM Source</span>
            <input type="text" v-model="newCreative.landing_page_tracking_template_utm_source" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Medium</span>
            <input type="text" v-model="newCreative.landing_page_tracking_template_utm_medium" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Campaign</span>
            <input type="text"  v-model="newCreative.landing_page_tracking_template_utm_campaign" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Content</span>
            <input type="text" v-model="newCreative.landing_page_tracking_template_utm_content" />
          </label>
          <div class="col-span-4 mt-3">
            <div class="flex justify-between" v-if="newCreative.custom_params.length < 4" style="margin-bottom:15px;">
              <button class="font-bold text-sm" @click.prevent="addCustomParameter(newCreative)">
                <i class="fa-solid fa-circle-plus text-primary"></i> Add Custom
                Parameters</button>
             
            </div>
            <span class="text-black/50 text-sm">   <span class="font-bold text-sm text-primary"> URL PREVIEW: </span>
            {{newCreative.landing_page_url}}<span v-if="(newCreative.landing_page_tracking_template_utm_source || newCreative.landing_page_tracking_template_utm_medium || newCreative.landing_page_tracking_template_utm_campaign || newCreative.landing_page_tracking_template_utm_content || newCreative.custom_params.length>0)">?</span><span v-if="newCreative.landing_page_tracking_template_utm_source">utm_source={{newCreative.landing_page_tracking_template_utm_source}}
            </span><span v-if="newCreative.landing_page_tracking_template_utm_medium">
              <span v-if="newCreative.landing_page_tracking_template_utm_source">&</span>utm_medium={{ newCreative.landing_page_tracking_template_utm_medium }}
  
            </span><span v-if="newCreative.landing_page_tracking_template_utm_campaign">
              <span v-if="newCreative.landing_page_tracking_template_utm_source || newCreative.landing_page_tracking_template_utm_medium">&</span>utm_campaign={{ newCreative.landing_page_tracking_template_utm_campaign }}
            </span><span v-if="newCreative.landing_page_tracking_template_utm_content">
              <span v-if="newCreative.landing_page_tracking_template_utm_source || newCreative.landing_page_tracking_template_utm_medium || newCreative.landing_page_tracking_template_utm_campaign">&</span>utm_content={{ newCreative.landing_page_tracking_template_utm_content }}
            </span><span v-for="custom_param in newCreative.custom_params">
              <span v-if="(newCreative.landing_page_tracking_template_utm_source || newCreative.landing_page_tracking_template_utm_medium || newCreative.landing_page_tracking_template_utm_campaign || newCreative.landing_page_tracking_template_utm_content || newCreative.custom_params.length>1)">
                &</span>{{custom_param.key  }}={{ custom_param.value }}
            </span>
          
          </span>
          </div>
          <div v-for="custom_param in newCreative.custom_params">
            <label class="col-span-2 mt-2" >
              <span class="text-sm font-normal">Custom Param   
                <button class="font-bold text-sm" @click.prevent="newCreative.custom_params.splice(newCreative.custom_params.indexOf(custom_param), 1);">
                <i class="fa-solid fa-delete-left"></i>
                </button>
              </span>
              <input type="text" v-model="custom_param.key" />
            </label>

            <label class="col-span-2 mt-2">
              <span class="text-sm font-normal">Custom Value</span>
              <input type="text" v-model="custom_param.value"  />
            </label>
        </div>
          
        </div>
</div>
        <div class="col-span-4 flex gap-3 max-w-lg items-center" v-if="newCreative.serving_type=='hosted'" >
          <span class="font-bold block pb-5">Dates available</span>
          <label>
            <input  type="datetime-local" v-model="newCreative.availability_start"/>
            <span class="uppercase text-xs text-center block mt-2">Start Date</span>
          </label>
          <label>
            <input type="datetime-local" v-model="newCreative.availability_end" />
            <span class="uppercase text-xs text-center block mt-2">End Date</span></label>
        </div>
        <div class="col-span-4 text-center mt-4">
          <button class="button-secondary"  @click.prevent="hideNewCreative = 1">
            Discard
          </button>
          <button class="button-primary" @click.prevent="saveCreative(newCreative);">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="px-10" :class="{hidden: hideEditCreative}">
    <button class="uppercase font-bold text-sm" @click.prevent="hideEditCreative = 1">
      <i class="fa-solid fa-arrow-left text-primary mr-1"></i> 
      Back to Creative Library
    </button>
    <div class="max-w-4xl mx-auto">
      <h2 class="text-3xl font-bold mb-1 text-center">Edit Creative</h2>
      <span class="text-xs text-primary font-bold uppercase text-center block" @click.prevent=" showDeleteConfirm=1;">
        Delete</span>
      <form class="grid grid-cols-4 gap-5 mt-5">
        <label class="col-span-4 flex items-center gap-3 relative">
          <span>Name</span>
          <input type="text" v-model="editCreative.name" />
          <button class="absolute right-0 top-0 h-full w-9 pt-1 hover:text-primary"><i
              class="fa-sharp fa-solid fa-pen-to-square"></i></button>
        </label>

        <div class="col-span-4">
          <span class="block"><b>Type:</b> {{ editCreative.creative_type }}</span>
          <span class="block"><b>Qualifies For:</b> CTV, CTVx, Display Video</span>
        </div>

        <div class="col-span-4 flex gap-5">
         
          <div class="w-2/3" v-if="editCreative.meta ">
            <div v-if="isImage(editCreative.meta.file_url )">
              <img :src="editCreative.meta.file_url "  style="width:100%; height:auto;"/>
            </div>
            <div v-else >
              <video :src="editCreative.meta.file_url "  style="width:100%; height:auto;" />
            </div>
          </div>
          <div class="w-1/3">
            <span class="block hidden"><b>Upload Date:</b> February 2, 2023</span>
            <span class="block"><b>File Type:</b>  {{ editCreative.creative_type }}</span>
            <span class="block"><b>File Size:</b>  {{ editCreative.file_size_kb }}MB</span>
            <span class="block"><b>Dimensions (px):</b> {{ editCreative.file_resolution_width_px }} X {{ editCreative.file_resolution_height_px }} </span>
            <span class="block"><b>Bitrate:</b>{{ editCreative.file_calculated_bitrate_kbps }} kbps </span>
          </div>
        </div>

        <div style="border:1px solid #ccc; border-radius:15px; padding:10px" class="col-span-4" v-if="editCreative.name">
        <label class="col-span-4">
          <span>Landing Page <sup>*</sup></span>
          <input type="text" v-model="editCreative.landing_page_url"  placeholder="https://www.your-website.com/landing-page"/>
        </label>
        <div class="grid grid-cols-4 col-span-4 gap-x-5" style="margin-top:15px">
          <span class="col-span-4 font-bold">Tracking Info</span>
          <label>
            <span class="text-sm font-normal">UTM Source</span>
            <input type="text" v-model="editCreative.landing_page_tracking_template_utm_source" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Medium</span>
            <input type="text" v-model="editCreative.landing_page_tracking_template_utm_medium" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Campaign</span>
            <input type="text"  v-model="editCreative.landing_page_tracking_template_utm_campaign" />
          </label>
          <label>
            <span class="text-sm font-normal">UTM Content</span>
            <input type="text" v-model="editCreative.landing_page_tracking_template_utm_content" />
          </label>
          <div class="col-span-4 mt-3">
            <div class="flex justify-between" v-if="editCreative.custom_params.length < 4" style="margin-bottom:15px;">
              <button class="font-bold text-sm" @click.prevent="addCustomParameter(editCreative)">
                <i class="fa-solid fa-circle-plus text-primary"></i> Add Custom
                Parameters</button>
             
            </div>
            <span class="text-black/50 text-sm">   <span class="font-bold text-sm text-primary"> URL PREVIEW: </span>
            {{editCreative.landing_page_url}}<span v-if="(editCreative.landing_page_tracking_template_utm_source || editCreative.landing_page_tracking_template_utm_medium || editCreative.landing_page_tracking_template_utm_campaign || editCreative.landing_page_tracking_template_utm_content || editCreative.custom_params.length>0)">?</span><span v-if="editCreative.landing_page_tracking_template_utm_source">utm_source={{editCreative.landing_page_tracking_template_utm_source}}
            </span><span v-if="editCreative.landing_page_tracking_template_utm_medium">
              <span v-if="editCreative.landing_page_tracking_template_utm_source">&</span>utm_medium={{ editCreative.landing_page_tracking_template_utm_medium }}
  
            </span><span v-if="editCreative.landing_page_tracking_template_utm_campaign">
              <span v-if="editCreative.landing_page_tracking_template_utm_source || editCreative.landing_page_tracking_template_utm_medium">&</span>utm_campaign={{ editCreative.landing_page_tracking_template_utm_campaign }}
            </span><span v-if="editCreative.landing_page_tracking_template_utm_content">
              <span v-if="editCreative.landing_page_tracking_template_utm_source || editCreative.landing_page_tracking_template_utm_medium || editCreative.landing_page_tracking_template_utm_campaign">&</span>utm_content={{ editCreative.landing_page_tracking_template_utm_content }}
            </span><span v-for="custom_param in editCreative.custom_params">
              <span v-if="(editCreative.landing_page_tracking_template_utm_source || editCreative.landing_page_tracking_template_utm_medium || editCreative.landing_page_tracking_template_utm_campaign || editCreative.landing_page_tracking_template_utm_content || editCreative.custom_params.length>1)">
                &</span>{{custom_param.key  }}={{ custom_param.value }}
            </span>
          
          </span>
          </div>
          <div v-for="custom_param in editCreative.custom_params">
            <label class="col-span-2 mt-2" >
              <span class="text-sm font-normal">Custom Param   
                <button class="font-bold text-sm" @click.prevent="editCreative.custom_params.splice(editCreative.custom_params.indexOf(custom_param), 1);">
                <i class="fa-solid fa-delete-left"></i>
                </button>
              </span>
              <input type="text" v-model="custom_param.key" />
            </label>

            <label class="col-span-2 mt-2">
              <span class="text-sm font-normal">Custom Value</span>
              <input type="text" v-model="custom_param.value"  />
            </label>
        </div>
          
        </div>
      </div>

        <div class="col-span-4 flex gap-3 max-w-lg items-center">
          <span class="font-bold block pb-5">Dates available</span>
          <label>
            <input  type="datetime-local" v-model="editCreative.availability_start"/>
            <span class="uppercase text-xs text-center block mt-2">Start Date</span>
          </label>
          <label>
            <input type="datetime-local" v-model="editCreative.availability_end" />
            <span class="uppercase text-xs text-center block mt-2">End Date</span></label>
        </div>
        <div class="col-span-4 text-center mt-4">
          <button class="button-secondary"  @click.prevent="hideEditCreative = 1">
            Discard
          </button>
          <button class="button-primary" @click.prevent="saveCreative(editCreative);">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>


  <div id="modal" class="fixed top-0 left-0 w-full h-full bg-black/80 flex justify-center items-center z-20"
    :class="{ hidden: !showPreview }" v-if="showPreview">
    <div class="max-w-3xl w-full p-4 relative" style=" display: flex; align-items: center; justify-content: center;">
      <button class="absolute bottom-full right-4 lg:right-auto lg:left-full w-8 h-8 bg-white fill-black p-2 rounded-full"
        @click.prevent="showPreview = 0; currentCreative = [];">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z">
          </path>
        </svg>
      </button>
      <iframe style="width:500px; height:500px;" class=" aspect-video rounded-lg shadow-lg"
        :src="currentCreative.meta.file_url" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""></iframe>
    </div>
  </div>

    <!-- MODAL Confirm Delete -->
    <div id="modal-confirm-delete" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "
    :class="{ hidden: !showDeleteConfirm }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow"
        @click.prevent="showDeleteConfirm = 0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-3xl mt-10 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-14 inline-block mr-4">
          <path
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
        </svg>
        Are you sure you want to delete the creative?
      </h2>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto" @click.prevent="showDeleteConfirm = 0; editCreative= {};">
          Nevermind
        </button>
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="deleteCreative(editCreative);">
          Delete
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL Confirm Delete -->

</div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.absolutex{

}
 .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all .2s
}
 .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
 label.dropdown-item {
  margin-bottom: 0;
}
 .btn-group .dropdown-toggle {
  margin-right: .6rem
}
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
 .filename {
  margin-bottom: .3rem
}
 .btn-is-option {
  margin-top: 0.25rem;
}
 .example-foorer {
  padding: .5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
 .edit-image img {
  max-width: 100%;
}
 .edit-image-tool {
  margin-top: .6rem;
}
 .edit-image-tool .btn-group{
  margin-right: .6rem;
}
 .footer-status {
  padding-top: .4rem;
}
 .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
 .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.hoverhand{
  cursor:pointer
}
[type='checkbox']:focus, [type='radio']:focus{
  box-shadow:none !important;
}
.multiselect__tags{
border-radius:0
;
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.multiselect__select::before{
  border-color:#ec2561 transparent transparent transparent
}

</style>