<template>
  <div class="account">
    <h1>This is an account page</h1>
  </div>
</template>
<script>
export default{
  name: 'account',
  setup(){
    
  }
}
</script>