<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/user/stores-auth';
import { api } from '../src/helpers';
import { useTriadStore } from '@/user/triad-store';

const triadStore        = useTriadStore();


const emit              = defineEmits(['message', 'loading']);




</script>

<template>
  <div id="login" aria-expanded="true" class="hidden aria-expanded:flex absolute top-0 left-0 bottom-0 right-0">
    <img src="assets/t_logo.png" alt="" class="absolute top-10 left-10 block">
    
    <router-view
      v-slot="{ Component, route }"
      @message="updateToast"
      @loading="updateLoading"
    >
     
        <component :is="Component" :key="route.path" />
     
    </router-view>
    <div class="absolute left-0 bottom-0 px-6 py-6 text-xs">
        Copyright &#169; 2023 TriAD CTV<sup>&#174;</sup>, Inc. All rights reserved. <br>
        <a href="" class="font-bold text-primary">Terms & Conditions</a> | <a href="" class="font-bold text-primary">Privacy Policy</a>
      </div>
    <div class="w-1/2 h-full bg-black flex flex-col justify-center items-center text-white relative">
      <img src="assets/login_bg.jpg" alt="" class="absolute bottom-0 right-0 block">
      <div class="max-w-md -mt-4 relative">
        <h3 class="text-3xl text-primary uppercase font-bold mb-4">Say Hello to</h3>
        <h2 class="text-[55px] font-bold leading-[60px]">TriAD 2.0</h2>
        <span class="text-3xl font-bold mt-2 hidden">Mar 24 @ 1pm</span>
        <p class="max-w-xs mt-2 mb-8">Better than ever and built with you in mind.</p>
        <p><i class="fa-sharp fa-solid fa-square-check text-primary mr-1"></i> New, innovative interface</p>
        <p><i class="fa-sharp fa-solid fa-square-check text-green mr-1"></i> Enhanced campaign builder</p>
        <p><i class="fa-sharp fa-solid fa-square-check text-[#F7DE4A] mr-1"></i> Easily chat with your account rep</p>
        <a href="/register" class="button-primary mt-40 " style="position:relative; top:30px;">Register Now</a>
      </div>
   
      
    </div>
 
  </div>
     
</template>

<style scoped></style>
