<template>
  <div class="accounts">
    <h1>This is an accounts page</h1>
  </div>
</template>
<script>
export default{
  name: 'accounts',
  setup(){
    
  }
}
</script>