<script setup>
import { RouterLink } from "vue-router";
import { ref, computed } from "vue";
import { useTriadStore } from '@/user/triad-store';

const triadStore    = useTriadStore();

const searchAdv = ref('');

const isAvertisersMenuInactive = ref(1);

const setAdvertiser = (advertiser) => {
  triadStore.currentAdvertiser = advertiser;
  isAvertisersMenuInactive.value = 1;
  location.reload();
};

const filteredAdvertisers = computed(() => {
  return triadStore.advertisers.filter((val) => {
   
    return val.name.toString().toLowerCase().includes(searchAdv.value);
  });
});
</script>

<template>
  <!-- SIDEBAR -->
  <div id="side-bar"
    class="bg-light min-h-[calc(100vh-60px)] lg:min-h-[calc(100vh-70px)] transition-all relative shrink-0 w-0 w-72 class">
    <div class="flex flex-col px-6 lg:px-10 h-full pt-20 lg:pt-20 absolute right-0 top-0 w-72">
      <nav class="flex flex-col">
        <div class="text-left mb-2 lg:mb-6">
          <small class="uppercase block font-bold text-xs">Advertiser:</small>
          <button v-if="triadStore.currentAdvertiser.length == 0"
            @click.prevent="isAvertisersMenuInactive = !isAvertisersMenuInactive" onclick="
                                            this.querySelector('i').classList.toggle('fa-flip-horizontal');
                                            ">
            Select an Advertiser
            <i class="fa-sharp fa-solid fa-caret-right text-primary ml-2"></i>
          </button>
          <button v-if="triadStore.currentAdvertiser.name" @click.prevent="isAvertisersMenuInactive = !isAvertisersMenuInactive"
            onclick="this.querySelector('i').classList.toggle('fa-flip-horizontal');
                                            ">
            {{ triadStore.currentAdvertiser.name }}
            <i class="fa-sharp fa-solid fa-caret-right text-primary ml-2"></i>
          </button>
        </div>

        <!-- ADVERTISERS MENU -->
        <div id="advertisers" :class="{ hidden: isAvertisersMenuInactive }"
          class="lg:absolute left-full top-0 h-full bg-black flex flex-col gap-2 px-5 pt-6 pb-6 lg:pt-24 w-max min-w-[240px] text-white -mx-6 lg:mx-0 z-10">
          <RouterLink to="/advertisers" class="text-primary uppercase text-left py-2 font-bold"
            @click="isAvertisersMenuInactive = true"> New advertiser <span class="ml-1">+</span> </RouterLink>
          <ul class="mb-2 flex flex-col gap-1" style="max-heigt:300px; overflow-y:auto;">
            <li>
              <input type="text" v-model="searchAdv" style="color: rgb(0, 0, 0); padding: 0 10px;
    width: 75%; " v-show="triadStore.advertisers.length" />
            </li>
            <li v-for="advertiser in filteredAdvertisers">
              <a href="#" class="hover:font-bold" @click.prevent="setAdvertiser(advertiser)">{{ advertiser.name }}</a>
            </li>
          </ul>

          <RouterLink to="/advertisers" class="text-primary uppercase text-left py-2 font-bold"
            @click="isAvertisersMenuInactive = true"> Edit advertiser <span class="ml-1">→</span> </RouterLink>
        </div>
        <!-- END OF ADVERTISERS MENU -->

        <RouterLink to="/step-one" active-class="is-active"
          class="button-primary font-extrabold mb-4 text-center"> New Campaign </RouterLink>

        <RouterLink to="/" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-sharp fa-solid fa-house"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Home</span>
        </RouterLink>
        <RouterLink to="campaigns" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-sharp fa-solid fa-list"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Campaigns</span>
        </RouterLink>
        <RouterLink to="audience" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-solid fa-users"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Audience Library</span>
        </RouterLink>
        <RouterLink to="creatives" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-solid fa-photo-film-music"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Creative Library</span>
        </RouterLink>
        <RouterLink to="reporting" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-sharp fa-solid fa-chart-line-up"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Reporting</span>
        </RouterLink>

        <RouterLink to="billing" active-class="is-active" class="flex items-center gap-3 group font-bold">
          <span
            class="side-nav--icon w-[30px] lg:w-[36px] h-[30px] lg:h-[36px] inline-flex justify-center items-center p-1 lg:p-2 transition-colors group-hover:bg-neutral-200 group-[.is-active]:bg-black group-[.is-active]:text-white">
            <i class="fa-sharp fa-solid fa-file-invoice-dollar"></i>
          </span>
          <span class="group-hover:text-neutral-600 transition-colors">Billing</span>
        </RouterLink>
      </nav>

      <div class="mt-auto mb-10"></div>
      <!-- ASSISTANT -->
      <div class="mt-auto mb-10 relative">
        <button class="bg-black rounded-full w-16 h-16 p-4 shadow-md"
          onclick="getElementById('platform-assistant').classList.toggle('hidden')">
          <i class="fa-sharp fa-solid fa-video-arrow-up-right text-white"></i>
        </button>

        <div id="platform-assistant" class="absolute bottom-full mb-4 flex flex-col w-72 lg:w-72 shadow-lg hidden">
          <div class="bg-black py-3 text-white px-4 font-medium hidden">Platform Assistant</div>
          <button class="relative" onclick="getElementById('assitant-modal').classList.toggle('hidden')">
            <img src="https://via.placeholder.com/160x90" alt="" class="max-w-full w-full hidden" />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
              class="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/3 fill-white drop-shadow-md">
              <path
                d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="assitant-modal" class="modal hidden">
    <div class="modal-body max-w-3xl p-0">
      <button class="close-modal"
        onclick="this.closest('.modal').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <iframe class="w-full aspect-video" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""></iframe>
    </div>
  </div>
</template>

<style></style>
