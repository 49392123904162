<script setup>
import {  ref  } from 'vue';
import { useTriadStore }    from '@/user/triad-store';
import { api } from '../../helpers';
import { useRoute, useRouter } from 'vue-router';

const route           = useRoute();
const router          = useRouter();
const triadStore      = useTriadStore();
const payments        = ref([]);

const back = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_four' });
  }, 1000);
}

const saveStepFive  = () => {
  setTimeout(() => {
    router.push(route.query.redirectTo || { name: 'step_six' });
  }, 1000);
}
const formatDate = (date) =>{
  if(date){
    return moment(date).format('MMMM DD, YYYY');
  }else{
    return ''
  }
}

const formatDateSimple = (date)=> {
  if(date){
    return moment(date).format('MM/DD/YYYY');
  }else{
    return ''
  }
}

const getPayments = () => {

  let arrayDates  = [];
  if(triadStore.currentCampaign.paymentFrequency=='one'){
    arrayDates.push({'date':moment(triadStore.currentCampaign.startDate).format('YYYY-MM-DD'), 'amount': triadStore.currentCampaign.budget});
  }
  if(triadStore.currentCampaign.paymentFrequency=='monthly'){
  
    let startDate   = new Date(triadStore.currentCampaign.startDate);
    startDate.setHours(0,0,0,0);
    let endDate     = new Date(triadStore.currentCampaign.endDate);
    endDate.setHours(0,0,0,0);
    let currentDate = startDate;

    while(currentDate < endDate){
    
      let currentDay = currentDate.getUTCDate();
     
      if(currentDay == 1){
        arrayDates.push({'date':moment(currentDate).utc().format('YYYY-MM-DD'), 'amount':0});
      }
      currentDate.setUTCMonth(currentDate.getUTCMonth()+1);
      currentDate.setUTCDate(1);
    }
    let today       = new Date(triadStore.currentCampaign.startDate);
    let currentDay  = today.getUTCDate();
    if(currentDay > 1 ){
      arrayDates.unshift({'date':moment(today).utc().format('YYYY-MM-DD'), 'amount':0});
    }
   /*
    let paymentsCount       = arrayDates.length;
    let singlePaymentAmount = triadStore.currentCampaign.budget / paymentsCount;
    for(let i=0; i< paymentsCount; i++){
      arrayDates[i].amount = singlePaymentAmount;
    } 
    */
  }
  payments.value = arrayDates;
  triadStore.currentCampaign.payments = payments.value;
}

const calculateFlightDaysAmounts = () => {

  triadStore.currentCampaign.daysAmount = {};

  for(let i=0; i< triadStore.currentCampaign.flights.length; i++){

    let flightStartDate = new Date(triadStore.currentCampaign.flights[i].startDate);
    let flightEndDate   = new Date(triadStore.currentCampaign.flights[i].endDate);

   // flightStartDate.setHours(0,0,0,0);
   // flightEndDate.setHours(0,0,0,0);
  
    let daysBetween   = datediff(flightStartDate, flightEndDate);
    let amountPerDay  = triadStore.currentCampaign.flights[i].totalBudget/ daysBetween;

    let currentDate = flightStartDate;

    while(currentDate <= flightEndDate){
      let index = moment(currentDate).utc().format('YYYYMMDD');
      triadStore.currentCampaign.daysAmount[index]  = {};
      triadStore.currentCampaign.daysAmount[index]['amount']    = amountPerDay;
      
      if( !(triadStore.currentCampaign.daysAmount[index].hasOwnProperty('adgroups')) ){
        triadStore.currentCampaign.daysAmount[index]['adgroups'] = [];
      }
      for(let k = 0; k < triadStore.currentCampaign.flights[i]['adgroups'].length; k++){

        if( !(triadStore.currentCampaign.daysAmount[index]['adgroups'].includes(k)) ){
          triadStore.currentCampaign.daysAmount[index]['adgroups'].push(k);
        }
      }
      
      currentDate.setDate(currentDate.getDate() + 1);
    }
    console.log(triadStore.currentCampaign.daysAmount);
  }
};

const calculateInvoicePaymentAmounts = () => {

  let firstFlightStartDate = new Date(triadStore.currentCampaign.flights[0].startDate);
  let campaignEndDate      = new Date(triadStore.currentCampaign.endDate);
  let campaignTotal        = triadStore.currentCampaign.budget;
  for(let i=0; i< triadStore.currentCampaign.payments.length; i++){
    // get Invoice Date
    let paymentStartDate = new Date(triadStore.currentCampaign.payments[i].date);
    let paymentEndDate;
   
    //if invoiceDate bigger than the campaign start date then we use the campaign start date just for the first one
    if(i==0 ){
      paymentStartDate = firstFlightStartDate;
      
    }
    // get end of the invoice date if multiple invoices then the end date is one day before the next date, if last invoice then is the campaign end date. 
    var nextIndex = i+1;
    if(triadStore.currentCampaign.payments[nextIndex]){
       paymentEndDate   = new Date(triadStore.currentCampaign.payments[nextIndex].date);
      paymentEndDate.setDate(paymentEndDate.getDate() - 1);
    
    }else{
      paymentEndDate = new Date(campaignEndDate);
    }

    let currentDate = paymentStartDate;
    currentDate.setHours(0,0,0,0);
    paymentEndDate.setHours(0,0,0,0);

    triadStore.currentCampaign.payments[i].amount = 0;
    triadStore.currentCampaign.payments[i].adgroups = []; 
    let adgroups = []; 
    while(currentDate <= paymentEndDate){
      let index = moment(currentDate).utc().format('YYYYMMDD');
      if( ! triadStore.currentCampaign.daysAmount[index] ){
        currentDate.setDate(currentDate.getDate() + 1);
       }else{
      
        let indexamount = triadStore.currentCampaign.daysAmount[index]['amount'];
        if( !(indexamount > 0 )){
          indexamount = 0;
        }
        for(let l=0; l< triadStore.currentCampaign.daysAmount[index]['adgroups'].length; l++){
          if( !(adgroups.includes(l) ) ){
            adgroups.push(l);
          }
        }

        triadStore.currentCampaign.payments[i].amount = triadStore.currentCampaign.payments[i].amount + indexamount;

        currentDate.setDate(currentDate.getDate() + 1);
        //reduce amount from total
      }
    }

    triadStore.currentCampaign.payments[i].adgroups = adgroups;
    triadStore.currentCampaign.payments[i].amount = Math.floor(triadStore.currentCampaign.payments[i].amount * 100) / 100;

    campaignTotal = campaignTotal -  triadStore.currentCampaign.payments[i].amount;
  }

  
  if(campaignTotal >0 ){

    let lastPaymentIndex = triadStore.currentCampaign.payments.length - 1 ; 
    triadStore.currentCampaign.payments[lastPaymentIndex].amount = triadStore.currentCampaign.payments[lastPaymentIndex].amount  + campaignTotal;
    triadStore.currentCampaign.payments[lastPaymentIndex].amount =  Math.floor(triadStore.currentCampaign.payments[lastPaymentIndex].amount*100) / 100;
  }
  
};

function datediff(first, second) {        
    return Math.round((second - first) / (1000 * 60 * 60 * 24))+1;
}

function monthDiff(dateFrom, dateTo) {
 return dateTo.getUTCMonth() - dateFrom.getUTCMonth() + 
   (12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear()))
}

const purchase = async () => {
  document.body.classList.add("loading");
    const response = await api.post('/purchase', { campaign: triadStore.currentCampaign });
  document.body.classList.remove("loading");
}
</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " @click.prevent="calculateFlightDaysAmounts" :class="{completed:triadStore.currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden" @click.prevent="calculateInvoicePaymentAmounts"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:triadStore.currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->



    <!-- STEP 5: Purchase Your Campaign -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="true">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{triadStore.currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Approve Your Campaign</h2>
      </div>
      <div class="flex flex-col mt-3 gap-2 justify-end ml-auto">
        <div class="border-2 border-black font-bold flex items-center h-[42px] min-w-[400px]">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center w-7/12 grow">
            Due at Checkout
          </div>
          <div class="px-5 text-center grow w-5/12">
            <span v-if="triadStore.currentCampaign.paymentFrequency =='one'">
              {{ triadStore.currentCampaign.budget }}
            </span>
            <span v-else>
              $0.00
            </span>
          </div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] min-w-[400px]">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center w-7/12 grow">
            Total Campaign Price
          </div>
          <div class="px-5 text-center grow w-5/12">  {{ triadStore.currentCampaign.budget }}</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-full ">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Campaign Dates
            </div>
            <div class="px-5 text-center grow">
              {{ formatDate(triadStore.currentCampaign.startDate) }} - {{ formatDate(triadStore.currentCampaign.endDate) }}
            </div>
          </div>
      </div>
      <div class="grid grid-cols-2 gap-12 mt-5">
        <div>
          <label class="flex items-center gap-3">
            <span class="grow min-w-fit">Select a Billing Profile:</span>
            <select name="" id="">
              <option value="">Tori Farr</option>
            </select>
          </label>
          <button class="ml-auto text-xs uppercase text-primary font-bold block mt-2 mb-2">Add New Profile</button>
          <div><b>Organization:</b> {{triadStore.currentCampaign.advertiserName}}</div>
          <div><b>Address:</b> 818 Parkway Ave. Indianapolis, IN 46203</div>
          <fieldset class="mt-7">
            <legend class="font-bold mb-3">Select a Payment Method</legend>
            <div class="flex gap-3 justify-between text-sm px-6">
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Credit Card</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">ACH</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Wire
                Transfer</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Check</label>
            </div>
          </fieldset>
          <div class="bg-light px-6 py-4 mt-6 relative text-sm hidden">
            <span
              class="absolute -top-[14px] left-[18px] w-0 h-0 border border-t-transparent border-t-0 border-r-[12px] border-r-transparent border-b-[14px] border-b-light border-l-[12px] border-l-transparent"></span>
            <fieldset class="flex flex-col gap-2">
              <label class="block"><input name="credit-card" type="radio" class="mr-2 text-primary focus:ring-0">American
                Express ending in 1059 (expires 11/25)</label>
              <label class="block"><input name="credit-card" type="radio" class="mr-2 text-primary focus:ring-0">Use a new
                payment method</label>
            </fieldset>
            <p>ACH instructions will be provided in your order confirmation email. Your campaign will be manually reviewed
              and activated.</p>
            <p>Wire transfer instructions will be provided in your order confirmation email. Your campaign will be
              manually reviewed and activated.</p>
          </div>
        </div>
        <div>
          <fieldset class="flex gap-4 hidden">
            <span class="font-bold inline-block mr-2">Pay Per</span>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Campaign</label>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Flight</label>
          </fieldset>
          <fieldset class="flex gap-4 mt-3">
            <span class="font-bold inline-block mr-2">Payment Frequency</span>
            <label class="inline-block">
              <input name="pay-per" type="radio" 
                class="mr-2 text-primary focus:ring-0"  v-model="triadStore.currentCampaign.paymentFrequency" value="monthly" />Monthly</label>
            <label class="inline-block">
              <input name="pay-per" type="radio" v-model="triadStore.currentCampaign.paymentFrequency" value="one"
                class="mr-2 text-primary focus:ring-0">One-Time Payment</label> 
                <button class="button-primary"  @click.prevent="getPayments">Calculate Payments</button>
          </fieldset>
          <div class="table-container mt-5">
            <table>
              <tr class="thead-tr">
                <th>Amount</th>
                <th>Due Date</th>
              </tr>
              <tr class="tbody-tr" v-for="(payment, index) in payments" :key="index">
                <td>{{payment.amount}}</td>
                <td>{{payment.date }}</td>
              </tr>
              
            </table>
          </div>
        </div>
      </div>
      
   
      <button class="button-primary fixed bottom-0 left-0" @click.prevent="back">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary"  @click.prevent="purchase">Purchase & Launch</button>
      </div>
    </div>
    <!-- END OF STEP 5 -->


  </div>


</template>

<style></style>