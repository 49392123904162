<script setup>
import { ref, onMounted, computed } from "vue";
import { api } from '../helpers';
import { useTriadStore }    from '@/user/triad-store'
const triadStore        = useTriadStore();

const audiences           = ref([]);
const search              = ref('');
const currentAudience     = ref({});
const hideRequestForm     = ref(1);
const showDeleteConfirm   = ref(0);
const requestAudience     = ref({});

const get_audiences = async () => {
  
  
  if(triadStore.currentAdvertiser.id){
    document.body.classList.add("loading");
      const response  = await api.get('/get_audiences?advertiser_id=' + triadStore.currentAdvertiser.id);
      audiences.value = response.data.data;
    document.body.classList.remove("loading");
  }else{
    alert('Please Select an Advertiser');
  }
 
};

const filteredAudiences = computed(() => {
  return audiences.value.filter((val) => {
    return val.name.toString().toLowerCase().includes(search.value)
  });
});

const deleteAudience = async (audience) => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/delete_audiences', { audiences: [audience] });
    get_audiences();
    document.body.classList.remove("loading");
    showDeleteConfirm.value = 0;

    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const sendRequest = async () => {
  try {
    document.body.classList.add("loading");
    const response = await api.post('/request_audience', { request: requestAudience.value, 'advertiser_id':triadStore.currentAdvertiser.id  });
    document.body.classList.remove("loading");
    hideRequestForm.value = 1;
    alert('Your audience request has been sent');
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

onMounted(async () => {
    get_audiences();
});

</script>
<template>

  <div id="audience" class="audience px-10" :class="{ hidden: !hideRequestForm }">
    <div class="w-full flex flex-col md:flex-row gap-5 mb-5">
      <label class="relative grow">
        
        <input placeholder="Search" type="text" v-model="search">
        <i class="fa-solid fa-magnifying-glass absolute top-1/2 -translate-y-1/2 right-4 text-primary"></i>
      </label>
      <button class="button-primary" @click.prevent="hideRequestForm = 0">Request Audience Build</button>
    </div>
    <div class="table-container">
      <table class="w-full" >
        <tbody>
          <tr class="thead-tr">
            <th class="w-1/3">Audience Name <button class="sort"
                onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-1/3">Audience Logic</th>
            <th class="w-1/3">Assigned Ad Groups</th>
          </tr>
          <tr class="tbody-tr" v-for="audience in filteredAudiences" :key="audience.id">
            <td>
              <span class="block">{{ audience.name }}</span>
              <a href="#" class="table-action" @click.prevent="currentAudience = audience; showDeleteConfirm = 1;">Delete
              </a>
            </td>
            <td>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.include" :key="index">
                 <span class="font-bold"  v-if="index>0"> + </span>{{ segmentGroup.name }}
              </span>
              <span class="font-bold" v-if="audience.segmentGroups.exclude.length >0"> ≠ </span>
              <span class="whitespace-normal" v-for="(segmentGroup, index) in audience.segmentGroups.exclude" :key="index">
                 <span class="font-bold"  v-if="index>0"> OR </span>{{ segmentGroup.name }}
              </span>
            </td>
            <td>
              <span class="text-primary block font-bold" v-for="adgroup in audience.adgroups">
                  {{ adgroup.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div id="request-audience-build" class="px-10" :class="{ hidden: hideRequestForm }">
    <button class="uppercase font-bold text-sm" @click.prevent="hideRequestForm = 1">
      <i class="fa-solid fa-arrow-left text-primary mr-1"></i> Back to Audience Library
    </button>
    <div class="text-center">
      <h2 class="text-3xl font-bold mb-2">Request Audience Build</h2>
      <p class="mb-7">In order to optimize your audience & reach, you will need to schedule an audience consultation with
        your account rep.</p>
    </div>
    <div class="flex gap-10">
      <div class="w-1/2 flex flex-col gap-4">
        <p class="text-center mb-8"><span class="font-bold">Step 1:</span> Fill out the form below</p>
        <label>
          <span>Describe your target audience(s)<sup>*</sup></span>
          <textarea name="" id="" rows="5" v-model="requestAudience.description"></textarea>
        </label>
        <label>
          <span>Geographical location(s) to target <sup>*</sup></span>
          <input type="text" name="" id="" placeholder="Zip code, city, DMA, region (US state) or country"
            v-model="requestAudience.geo">
        </label>
        <button type="submit" class="button-primary mt-10 mx-auto block" @click.prevent="sendRequest">
          Save Audience Details
        </button>
      </div>
      <div class="w-1/2">
        <p class="text-center mb-8"><span class="font-bold">Step 2:</span> Schedule your consultation</p>
        <iframe src="https://meetings.hubspot.com/jkaranasios1/intro-to-triad-ctv?embed=true" style="width:100%; height:700px;"></iframe>
      </div>
    </div>
  </div>

  <div id="modal-delete-audience" class="modal " :class="{ hidden: !showDeleteConfirm }">
    <div class="modal-body max-w-3xl">
      <button class="close-modal" @click.prevent="showDeleteConfirm = 0">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center gap-5 pt-5">
        <i class="fa-solid fa-triangle-exclamation text-5xl leading-0"></i>
        <div class="">
          <h2 class="text-center text-xl lg:text-2xl font-bold">
            Are you sure you want to delete this audience?
          </h2>
          <p>You will not be able to retrieve this audience once deleted.</p>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary" @click.prevent="showDeleteConfirm = 0">
          Nevermind
        </button>
        <button class="button-primary" @click.prevent="deleteAudience(currentAudience);">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>


<style></style>