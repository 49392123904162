<script setup>
import { onMounted, ref, watch  } from 'vue';
import { api } from '../../helpers';
import creativeLibrary from "../creatives.vue"

const viewByFlights           = ref(0);
const adGroupToAddIndex       = ref(-1);
const hideAudienceRequest     = ref(1);
const showFlightModal         = ref(0);
const showAddFlightModal      = ref(0);
const showCreativeModal       = ref(0); 
const advertisers             = ref([]);
const audiences               = ref([]);
const newFlight               = ref({valid:false, message:''});
const flights                 = ref([]);
const currentAdgroupIndex     = ref(-1);

const setAdgroupCreative = (creative) => {
  if(currentAdgroupIndex.value >-1){
    currentCampaign.value.adgroups[currentAdgroupIndex.value].creatives = [];
    currentCampaign.value.adgroups[currentAdgroupIndex.value].creatives.push(creative);
  }
  showCreativeModal.value=0;
};

const currentCampaign         = ref({
  name: '',
  advertiser: '',
  advertiserName: '',
  audiences: [],
  audiences_change: [],
  current_step:1,
  startDate:'',
  endDate:'',
  flightIndex: 0,
  adgroups: [],
  currentAdgroupFlights:{},
  budget:0,
});

const audienceRequest     = ref({
  change: '',
  ids: '',
});

const submitAudieneRequest = () => {
  hideAudienceRequest.value= 1;
  alert('Audience Request Change Submitted');
};
const addFlight = () =>{
  let lastIndex     = flights.value.length-1;
  let current       = new Date(flights.value[lastIndex].endDate); 
  let followingDay  = new Date(current.getTime() + 86400000); // + 1 day in ms
  flights.value.push({
    startDate:followingDay.toISOString().substring(0,10), 
    endDate:'',  
    budget: [],
    adgroups:[],
    lockedBudget: 0,
    totalBudget:0,
  });
}

const addAdgroup = () => {
  currentCampaign.value.adgroups.push({creatives:[]});
  if(currentCampaign.value.adgroups.length == 1){
    flights.value[0].adgroups.push(0);
  }
}
const formatDate = (date) =>{
  if(date){
    return moment(date).format('MMMM DD, YYYY');
  }else{
    return ''
  }
}

const validateFlights = () => {
  let lastEndDate     = '';
  let valid           = true;
  
  for(let i=0; i <  flights.value.length; i++ ){
    currentCampaign.value.flights[i].message ='';
    //first validate start Date 
     
      if(lastEndDate){
        if(flights.value[i].startDate <= lastEndDate){
          flights.value[i].message = 'Start Date should be after the previus flight end date';
          valid= false;
        }
      }

      lastEndDate   = currentCampaign.value.flights[i].endDate;
    }
}

const getAdgroupTotalBudget = (adgroupIndex) =>{
  let totalBudget = 0;
  for(let i= 0; i < flights.value.length; i++ ){
    if(flights.value[i].budget[adgroupIndex] >0 ){
      totalBudget = totalBudget*1 + flights.value[i].budget[adgroupIndex]*1;
    }
  }
  return totalBudget;
}

const checkFlightBudget = (flight, event) => {
 let lockedValue    = 0;
 let flightBudget   = flight.totalBudget *1;

 if(event.target.checked){
  let flightTotalAdgroupsBudget = getFlightTotalAdgroupsBudget(flight);

  if(flightBudget >= flightTotalAdgroupsBudget){
    lockedValue    = 1;
  }else{
    alert('Can not lock budget because the total budget of the Ad Groups is greater than the flight budget');
    lockedValue    = 0;
  }
 }
 event.target.checked = lockedValue;
 flight.lockedBudget = lockedValue;
}

const changeBudget  = (adgroupIndex, event, flight) =>{
  /*
  let oldValue      = 0;
  let newValue      = event.target.value *1;
  let flightBudget  = flight.totalBudget *1;
  let setValue      = 0;

  if(flight.budget[adgroupIndex]){
    oldValue = flight.budget[adgroupIndex]*1;
  }

  if(flight.lockedBudget*1 > 0){
    let flightTotalAdgroupsBudget = getFlightTotalAdgroupsBudget(flight);
    let newTotalAdgroupsBudget    = flightTotalAdgroupsBudget + newValue - oldValue;

    if(flightBudget >= newTotalAdgroupsBudget){
      setValue = newValue;
    }else{
      alert('Flight budget locked, please pick a lesser amount or increase flight budget');
      setValue = 0;
    }

  }else{
    setValue = newValue;
  }
  */
  let setValue                = event.target.value;
  flight.budget[adgroupIndex] = setValue;
  event.target.value          = setValue;
  flight.totalBudget          = getFlightTotalAdgroupsBudget(flight);

}

const getFlightTotalAdgroupsBudget = (flight) =>{
  let totalBudget = 0;
  for(let i= 0; i < flight.budget.length; i++ ){
    if(flight.budget[i] >0 ){
      totalBudget = totalBudget*1 + flight.budget[i]*1;
    }
  }
  return totalBudget*1;
}

async function saveStepOne() {
  try {
    flights.value.push({
      startDate  : currentCampaign.value.startDate,
      endDate    : currentCampaign.value.endDate,
      budget     : [],
      adgroups   : [],
      lockedBudget: 0,
      totalBudget: 0,
    });
   
    const args = {
      name      : currentCampaign.value.name,
      advertiser: currentCampaign.value.advertiser,
    };
    document.body.classList.add("loading");
   // const response = await api.post('/save_step_one', args);
    //currentCampaign.value.id            = response.data;
    currentCampaign.value.current_step  = 2;
    document.body.classList.remove("loading");
    advertisers.value.forEach(function(item){
      if(item.id == currentCampaign.value.advertiser){
        currentCampaign.value.advertiserName = item.name;
      }
    });

    return response;
  } catch (error) {
    return {
     // status: error.response.status,
   //   message: error.response.data.message,
    };
  }
}

async function saveStepTwo() {
  try {
    const args = {
      currentCampaign: currentCampaign.value
    };
    //const response = await api.post('/save_step_two', args);

    currentCampaign.value.current_step  = 3;
   
    return response;
  } catch (error) {
    return {
    //  status: error.response.status,
    //  message: error.response.data.message,
    };
  }
}
const back = (step) => {
  currentCampaign.value.current_step  = step;
}
const saveStepThree = ()=>{
  currentCampaign.value.current_step  = 5;
}
const saveStepFour = ()=>{
  currentCampaign.value.current_step  = 5;
}
const saveStepFive = ()=>{
  currentCampaign.value.current_step  = 6;
}

const inputFile = async (newFile, oldFile) => {
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    // Get response data
    console.log('response', newFile.response)
    if (newFile.xhr) {
      //  Get the response status code
      console.log('status', newFile.xhr.status)
    }

  }

  let formData = new FormData();
  formData.append('filepond', newFile.file);

  const response = await api.post('/file_upload',
    formData
    , {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

onMounted(async () => {

  let account =   JSON.parse(localStorage.getItem('account'));
  console.log(account.id);
 // document.body.classList.add("loading");
    const response    = await api.get('/get_advertisers?account_id=' + account.id);
  advertisers.value = response.data.data;

  const response2 = await api.get('/get_audiences?advertiser_id=' + currentCampaign.value.advertiser);
  audiences.value = response2.data.data;
 // document.body.classList.remove("loading");

  watch(flights, (value) => {
    let lastIndex                     = flights.value.length-1;
    currentCampaign.value.startDate   = flights.value[0].startDate;
    currentCampaign.value.endDate     = flights.value[lastIndex].endDate;
    let totalCampaignBudget           = 0
    for(let i= 0; i < flights.value.length; i++ ){
      totalCampaignBudget = totalCampaignBudget + getFlightTotalAdgroupsBudget(flights.value[i])*1;
    }
    currentCampaign.value.budget = totalCampaignBudget;
    }, { deep: true }
  );

});
</script>

<template>
  <div id="campaign-builder" class="fixed top-[70px] left-0 w-full bottom-0 bg-white flex flex-col z-20">
    <!-- STEP INDICATORS -->
    <div class="overflow-x-auto pt-2 shrink-0 px-10">
      <nav class="font-bold flex justify-around border-b-2 border-[#F0EFEF]">
        <div class="campaign-builder-step-indicator group " :class="{completed:currentCampaign.current_step >2 }"><!-- Agregar clase "completed" -->
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Audience</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:currentCampaign.current_step >3 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>CTV Builder <sup class="text-[.45rem]">TM</sup></span>
        </div>

        <div class="campaign-builder-step-indicator group hidden">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Creative</span>
        </div>

        <div class="campaign-builder-step-indicator group" :class="{completed:currentCampaign.current_step >5 }">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Review</span>
        </div>

        <div class="campaign-builder-step-indicator group">
          <i class="fa-sharp fa-regular fa-square-check group-[.completed]:hidden"></i>
          <i class="fa-sharp fa-solid fa-square-check hidden group-[.completed]:block text-green"></i>
          <span>Purchase</span>
        </div>
      </nav>
    </div>
    <!-- STEP INDICATORS -->

    <!-- STEP 0: Create Your Campaign -->
    <div id="" class="campaign-builder-step-container justify-center" :aria-expanded="currentCampaign.current_step==1">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold mb-auto">Create Your Campaign</h2>
      </div>
      <div class="max-w-xl mx-auto flex flex-col gap-4 -mt-14">
        <label class="flex items-center justify-between gap-4">
          <span>Name Your Campaign</span>
          <input type="text" class="w-96" placeholder="Campaign Name" v-model="currentCampaign.name">
        </label>
        <label for="" class="lg:flex items-center justify-between gap-4">
          <span>Select an Advertiser</span>
          <select name="select" class="w-96" v-model="currentCampaign.advertiser">
            <option v-for="advertiser in advertisers" :value="advertiser.id">
              {{ advertiser.name }}
            </option>
          </select></label>

          <div class="flex items-center gap-3"><!-- Agregar "invisible" cuando sea necesario -->
            <span class="font-bold text-right mb-6">Campaign Dates</span>
            <label><input type="date" v-model="currentCampaign.startDate" />
              <span class="uppercase block text-xs text-center mt-2">Start Date</span></label>
            <label><input type="date"  v-model="currentCampaign.endDate"/>
              <span class="uppercase block text-xs text-center mt-2">End Date</span></label>
          </div>
        <button class="uppercase text-primary p-0 font-bold text-xs ml-auto hidden">
          Create New Advertiser
        </button>
      </div>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepOne">Next <i
            class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 0 -->

    <!-- STEP 1: Review Your Target Audience -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="currentCampaign.current_step==2">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Review Your Target Audience</h2>
      </div>

      <label class="ml-auto relative mt-4">
        <input type="text" class="w-96" v-model="currentCampaign.name" />
        <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
          <i class="fa-sharp fa-solid fa-pen-to-square"></i>
        </button>
      </label>

      <div id="select-audience" class="table-container mt-10">
        <table>
          <tr class="thead-tr">
            <th class="w-5/12">Audicence </th>
            <th class="w-7/12">Audience Logic</th>
          </tr>

          <!-- !!! -->
          <tr class="tbody-tr" v-for="audience in audiences" :key="audience.id">
            <td>
              <input type="checkbox" id="" name="" :value="audience.id" class="text-primary focus:ring-primary"
                v-model="currentCampaign.audiences_change" />
              <span>{{ audience.name }} </span>
         
            </td>
            <td></td>
          </tr>
          <!-- !!!

          <tr class="tbody-tr">
            <td>
              <input type="checkbox" id="" name=""
                class="checkbox-sm checked:bg-primary checked:text-primary focus:ring-0 mr-2" />
              <span class="with-separator">Women</span>
              <span class="with-separator">25-44</span>
              <span class="with-separator"><a href="#" class="text-primary font-bold"
                  onclick="event.preventDefault();">Play Sports</a></span>
              <span class="with-separator">Online Shoppers</span>
            </td>
            <td>
              <span class="with-space">Women</span>
              <span class="with-space">+</span>
              <span class="with-space">Diagnosed with Heavy Periods</span>
              <span class="with-space">+</span>
              <span class="with-space">≠</span>
              <span class="with-space">Current Customers</span>
              <span class="with-space">OR</span>
              <span class="with-space">Low-Income</span>
            </td>
          </tr>
           -->
        </table>
      </div>

      <div class="my-10 text-center">
        <h2 class="text-bold text-3xl">Need to make a change?</h2>
        <p>Select the audience/s you would you like to change and then click the button to access the change request
          form.
        </p>
        <button class="button-primary mt-4" @click.prevent="hideAudienceRequest = 0; audienceRequest.ids = currentCampaign.audiences_change.toString() ">
          Request Audience Change
        </button>
      </div>

      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepTwo">
          Next 
          <i class="fa-solid fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
    <!-- END OF STEP 1 -->

    <!-- STEP 2: Build Your Ad Groups -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="currentCampaign.current_step==3">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Build Your Ad Groups</h2>
      </div>
      <div class="ml-auto flex items-start gap-10">
        <div>
          
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-full ">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Campaign Dates
            </div>
            <div class="px-5 text-center grow">
              {{ formatDate(currentCampaign.startDate) }} - {{ formatDate(currentCampaign.endDate) }}
            </div>
          </div>
          <button class="table-action hidden"  @click.prevent="showFlightModal =1">
            Manage Flights
          </button>
        </div>
        <div>
          <div class="relative">
            <input type="text" placeholder="Campaign Name" class="w-96" v-model="currentCampaign.name">
            <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
              <i class="fa-sharp fa-solid fa-pen-to-square"></i>
            </button>
          </div>
          <div class="text-xs mt-2"><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-96">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Total Campaign Budget
            </div>
            <div class="px-5 text-center grow">
              {{ currentCampaign.budget }}
            </div>
          </div>
        </div>
      </div>

      <!-- Botones para cambiar de vistas en las tablas de abajo. Lo que cambian son las clases "bg-white" / "bg-light" cuando estén activos -->
      <div class="flex mt-6 text-sm ">
        <button class="button-secondary "  :class="{'bg-light': !viewByFlights}" @click.prevent="viewByFlights=0">
           Manage Ad Groups
        </button>
        <button class="button-secondary " :class="{'bg-light': viewByFlights}"  @click.prevent="viewByFlights=1" v-if="flights.length >1">
           Manage Flights
        </button> 
        <button class="text-primary uppercase text-xs px-5 py-2 font-bold" :class="{'bg-light': viewByFlights}" v-if="flights.length <=1" @click.prevent="viewByFlights=1" >
          New Flight <i
            class="fa-sharp fa-solid fa-plus fa-xs ml-1"></i>
        </button>
      </div>
      
      <!--view Adgroups BY Flights in ROWS-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="viewByFlights" >
        <div  v-for="(flight, index) in flights" :key="index" >
          <div class="table-container" style="margin-top:30px;">
            <table>
            <tr class="thead-tr">
            <th></th>
              <th class="">Start Date</th>
              <th class="">End Date</th>
              <th class="">Budget</th>
            </tr>
            <tbody >
              <tr class="tbody-tr" >
                <td><button @click.prevent="currentCampaign.flightIndex = index">View Ad Groups</button></td>
                <td><input type="date" v-model="flight.startDate"/></td>
                <td><input type="date" v-model="flight.endDate"/></td>
                <td>
                  {{ flight.totalBudget }}
                 <!-- Hiding the locking budget for now-->
                  <div v-if="0">
                    <input type="number" v-model="flight.totalBudget" /><br/>
                    <input type="checkbox" name="" id="" class="peer" value:="flight.lockedBudget" @change="checkFlightBudget(flight, $event)" true-value="1"
                      false-value="0" >
                    <i class="fa-sharp fa-solid fa-lock fa-sm peer-checked:hidden"></i>
                    <i class="fa-sharp fa-solid fa-lock-open fa-sm hidden peer-checked:inline"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <div class="table-container" style="padding:10px 30px;" :class="{hidden:index != currentCampaign.flightIndex}">
                    <table>
                      <tr class="thead-tr">
                        <th class="w-1/12">Ad Group </th>
                        <th class="w-3/12">Audience</th>
                        <th class="w-4/12">Medium</th>
                        <th class="w-1/12">Impressions</th>
                        <th class="w-1/12">Budget <sup>
                          <i class="fa-solid fa-circle-question fa-xs"></i></sup>
                        </th>
                      </tr>
                      <tbody  v-for="(adgroup, adgroupIndex) in currentCampaign.adgroups" :key="adgroupIndex" >
                        <tr class="tbody-tr" v-if="flight.adgroups.includes(adgroupIndex)" >
                          <td>
                            {{ adgroup.name }}
                          </td>
                          <td>
                            {{ adgroup.audience }}
                          </td>
                          
                          <td>{{adgroup.medium}}</td>
                          <td>
                            {{adgroup.impressions}}
                          </td>
                          <td>
                              <span>
                                <input type="number" :value="flight.budget[adgroupIndex]"  @change="changeBudget(adgroupIndex, $event, flight)" />
                              </span>
                          </td>
                        </tr>
                        
                      </tbody>
                      <tr>
                          <td colspan="5"> 
                            <div style="width:30%; float:right; margin-top:15px;">
                              <select v-model="adGroupToAddIndex">
                                  <option disabled value="-1">Add Ad Group To Flight</option>
                                  <optgroup label=""  v-for="(adgroup, aindex)  in currentCampaign.adgroups" :key="aindex"  >
                                    <option   v-if="(!flight.adgroups.includes(aindex))" :value="aindex">{{ adgroup.name }}</option>
                                  </optgroup>
                              </select>
                              <button class="button-primary ml-auto block mt-4" :disabled="!(adGroupToAddIndex >-1)" @click.prevent="flight.adgroups.push(adGroupToAddIndex); adGroupToAddIndex=-1 ">
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
        </div>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addFlight">
          New Flight
        </button>
      </div>

      <!-- View by Adgroups-->
      <div class="bg-light -mx-10 px-10 py-6"  v-if="!viewByFlights">

        <!-- Tabalas de abajo -->
        <div class="table-container">
          <table>
          <tr class="thead-tr">
            <th class="w-1/12">Ad Group</th>
            <th class="w-3/12">Audience</th>
            <th class="w-2/12">Flights</th>
            <th class="w-4/12">Medium</th>
            <th class="w-4/12">Creative(s)</th>
            <th class="w-1/12">Impressions</th>
            <th class="w-1/12">Budget <sup>
              <i class="fa-solid fa-circle-question fa-xs"></i></sup>
            </th>
          </tr>
          <tr class="tbody-tr" v-for="(adgroup, adgroupIndex) in currentCampaign.adgroups" :key="adgroupIndex">
            <td>
              <input type=text v-model="adgroup.name"/> 
            </td>
            <td>
              <select v-model="adgroup.audience">
                <option disabled value="">Please select one</option>
                <option v-for="audience in audiences" :value="audience.id">
                  {{ audience.name }}
                </option>
              </select>
            </td>
            <td>
                <div v-for="(flight, findex) in flights" :key="findex">
                    <div v-if="flight.adgroups.includes(adgroupIndex)">
                        {{ flight.startDate  }} - {{ flight.endDate }}
                    </div>
                </div>
            </td>
            
            <td>
              <label class="flex gap-3">
                <select v-model="adgroup.medium">
                  <option value="Large-Format CTV">Large-Format CTV</option>
                  <option value="CTVx">CTVx</option>
                  <option value="Display">Display</option>
                  <option value="Display Video">Display Video</option>
                </select>
                <button class="text-xs text-primary font-bold uppercase">Specs & Details</button>
              </label>
              <div class="text-xs mt-2 font-bold flex gap-3 hidden">
                <span>Add On:</span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> CTVx<sup>TM</sup></span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> Display</span>
                <span><i class="fa-solid fa-circle-plus text-primary"></i> Display Video</span>
              </div>
            </td>
            <td>
              <div class="mb-1 last-of-type:mb-2 " v-for="(creative, cindex) in adgroup.creatives" :key="creative.id">
                <button class="text-primary -ml-1 pl-1 pr-2"  @click.prevent=" adgroup.creatives.splice(cindex, 1);">
                  <i class="fa-solid fa-xmark"></i>
                </button>
                  {{ creative.name }}
                <div>
                  <button class="table-action">Preview</button>
                  <button class="table-action">Replace</button>
                </div>
              </div>
             
             
              <button class="button-primary text-xs" @click.prevent="currentAdgroupIndex=adgroupIndex; showCreativeModal=1;" v-if="adgroup.creatives.length ==0">
                Add Creative
              </button>
            </td>
            <td>
            650,000
            </td>
            <td>
              <span v-if="flights.length ==1">
                <input type="number" :value="flights[0].budget[adgroupIndex]"  @change="changeBudget(adgroupIndex, $event, flights[0])" />
              </span>
              <span v-else >
                {{ getAdgroupTotalBudget(adgroupIndex) }}
              </span>
            </td>
          </tr>
        </table>
        </div>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addAdgroup">
          Add Ad Group
        </button>
      </div>
      <button class="button-primary fixed bottom-0 left-0" @click.prevent="back(2);">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepThree">Next <i class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 2 -->

    <!-- STEP 3: Select Your Creative -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="currentCampaign.current_step==4">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Select Your Creative</h2>
      </div>
      <div class="ml-auto flex items-start gap-10">
        <div>
          <div class="flex items-center gap-3 invisible"><!-- Agregar "invisible" cuando sea necesario -->
            <span class="font-bold text-right mb-6">Campaign Dates</span>
            <label><input type="date">
              <span class="uppercase block text-xs text-center mt-2">Start Date</span></label>
            <label><input type="date">
              <span class="uppercase block text-xs text-center mt-2">End Date</span></label>
          </div>
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-full">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Campaign Dates
            </div>
            <div class="px-5 text-center grow">{{ currentCampaign.startDate }} - {{ currentCampaign.endDate }} </div>
          </div>
          <button class="table-action hidden">Manage Flights</button>
        </div>
        <div>
          <div class="relative">
            <input type="text" placeholder="Campaign Name" class="w-96" v-model="currentCampaign.name">
            <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
              <i class="fa-sharp fa-solid fa-pen-to-square"></i>
            </button>
          </div>
          <div class="text-xs mt-2"><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
          <div class="border-2 border-black font-bold flex items-center h-[42px] mt-4 w-96">
            <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
              Total Campaign Budget
            </div>
            <div class="px-5 text-center grow">{{ currentCampaign.budget }}</div>
          </div>
        </div>
      </div>
      <div class="table-container mt-4">
        <table>
          <tr class="thead-tr">
            <th class="w-1/12">Ad Group</th>
            <th class="w-3/12">Audience</th>
            <th class="w-2/12">Flights</th>
            <th class="w-1/12">Medium</th>
            <th class="w-4/12">Creative(s)</th>
            <th class="w-1/12">Budget <sup><i class="fa-solid fa-circle-question fa-xs"></i></sup></th>
          </tr>
          <tr class="tbody-tr" v-for="(adgroup, adgroupIndex) in currentCampaign.adgroups" :key="adgroupIndex">
            <td>{{ adgroup.name }}</td>
            <td>{{ adgroup.audience }}</td>
            <td>
              <div v-for="(flight, findex) in flights" :key="findex">
                    <div v-if="flight.adgroups.includes(adgroupIndex)">
                        {{ flight.startDate  }} - {{ flight.endDate }}
                    </div>
                </div>
            </td>
            <td>{{ adgroup.medium }}</td>
            <td>
              <div class="mb-1 last-of-type:mb-2 hidden">
                <button class="text-primary -ml-1 pl-1 pr-2"><i class="fa-solid fa-xmark"></i></button>
                Jockey Underwear: 30 Commercial “Fever”
                <div>
                  <button class="table-action">Preview</button>
                  <button class="table-action">Replace</button>
                </div>
              </div>
             
              <button class="button-primary text-xs">Add Creative</button>
            </td>
            <td>  {{ getAdgroupTotalBudget(adgroupIndex) }}</td>
          </tr>
        </table>
      </div>
      <button class="button-primary fixed bottom-0 left-0" @click.prevent="back(3);">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back
      </button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepFour">Next <i class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 3 -->

    <!-- STEP 4: Approve Your Campaign -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="currentCampaign.current_step==5">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Approve Your Campaign</h2>
      </div>
      <div class="ml-auto flex items-start gap-10">
        <div class="relative">
          <input type="text" placeholder="Campaign Name" class="w-96" v-model="currentCampaign.name">
          <button class="absolute right-3 top-0 w-4 h-full hover:text-primary transition-all">
            <i class="fa-sharp fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>
      <div class="flex mt-6 gap-5">
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Avg Frequency
          </div>
          <div class="px-5 text-center grow">8</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Est. Impressions
          </div>
          <div class="px-5 text-center grow">545,420</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Campaign Dates
          </div>
          <div class="px-5 text-center grow">{{ currentCampaign.startDate }} - {{ currentCampaign.endDate }} </div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] grow">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow">
            Total Campaign Budget
          </div>
          <div class="px-5 text-center grow">{{ currentCampaign.budget }}</div>
        </div>
      </div>
      
      <div class="bg-light -mx-10 px-10 py-4">
       
        <div class="bg-light -mx-10 px-10 py-6"  >
        <div  v-for="(flight, index) in flights" :key="index" >
          <div class="table-container" style="margin-top:30px;">
            <table>
            <tr class="thead-tr">
            <th></th>
              <th class="">Start Date</th>
              <th class="">End Date</th>
              <th class="">Budget</th>
            </tr>
            <tbody >
              <tr class="tbody-tr" >
                <td><button @click.prevent="currentCampaign.flightIndex = index">View Ad Groups</button></td>
                <td>{{ flight.startDate }}</td>
                <td>{{ flight.endDate }}</td>
                <td>
                  {{flight.totalBudget}}
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <div class="table-container" style="padding:10px 30px;" :class="{hidden:index != currentCampaign.flightIndex}">
                    <table>
                      <tr class="thead-tr">
                        <th class="w-1/12">Ad Group </th>
                        <th class="w-3/12">Audience</th>
                        <th class="w-4/12">Medium</th>
                        <th class="w-1/12">Impressions</th>
                        <th class="w-1/12">HH Freq</th>
                        <th class="w-1/12">HH Reach</th>
                        <th class="w-1/12">Budget <sup>
                          <i class="fa-solid fa-circle-question fa-xs"></i></sup>
                        </th>
                      </tr>
                      <tbody  v-for="(adgroup, adgroupIndex) in currentCampaign.adgroups" :key="adgroupIndex" >
                        <tr class="tbody-tr" v-if="flight.adgroups.includes(adgroupIndex)" >
                          <td>
                            {{ adgroup.name }}
                          </td>
                          <td>
                            {{ adgroup.audience }}
                          </td>
                          
                          <td>{{adgroup.medium}}</td>
                          <td>
                            {{adgroup.impressions}}
                          </td>
                          <td>
                            {{adgroup.frew}}
                          </td>
                          <td>
                            {{adgroup.reach}}
                          </td>
                          <td>
                              {{ flight.budget[adgroupIndex] }}
                          </td>
                        </tr>
                        
                      </tbody>
                      
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
        </div>
       
      </div>
       
      </div>
      <button class="button-primary fixed bottom-0 left-0 " @click.prevent="back(4);">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary" @click.prevent="saveStepFive">Next <i class="fa-solid fa-arrow-right ml-2"></i></button>
      </div>
    </div>
    <!-- END OF STEP 4 -->

    <!-- STEP 5: Purchase Your Campaign -->
    <div id="" class="campaign-builder-step-container" :aria-expanded="currentCampaign.current_step==6">
      <div class="absolute top-5 left-10">
        <div><span class="font-bold">Advertiser:</span> {{currentCampaign.advertiserName}}</div>
        <h2 class="text-3xl font-semibold">Approve Your Campaign</h2>
      </div>
      <div class="flex flex-col mt-3 gap-2 justify-end ml-auto">
        <div class="border-2 border-black font-bold flex items-center h-[42px] min-w-[400px]">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center w-7/12 grow">
            Due at Checkout
          </div>
          <div class="px-5 text-center grow w-5/12">$00.00</div>
        </div>
        <div class="border-2 border-black font-bold flex items-center h-[42px] min-w-[400px]">
          <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center w-7/12 grow">
            Total Campaign Price
          </div>
          <div class="px-5 text-center grow w-5/12">$13,000</div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-12 mt-5">
        <div>
          <label class="flex items-center gap-3">
            <span class="grow min-w-fit">Select a Billing Profile:</span>
            <select name="" id="">
              <option value="">Tori Farr</option>
            </select>
          </label>
          <button class="ml-auto text-xs uppercase text-primary font-bold block mt-2 mb-2">Add New Profile</button>
          <div><b>Organization:</b> {{currentCampaign.advertiserName}}</div>
          <div><b>Address:</b> 818 Parkway Ave. Indianapolis, IN 46203</div>
          <fieldset class="mt-7">
            <legend class="font-bold mb-3">Select a Payment Method</legend>
            <div class="flex gap-3 justify-between text-sm px-6">
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Credit Card</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">ACH</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Wire
                Transfer</label>
              <label><input name="payment-method" type="radio" class="mr-2 text-primary focus:ring-0">Check</label>
            </div>
          </fieldset>
          <div class="bg-light px-6 py-4 mt-6 relative text-sm">
            <span
              class="absolute -top-[14px] left-[18px] w-0 h-0 border border-t-transparent border-t-0 border-r-[12px] border-r-transparent border-b-[14px] border-b-light border-l-[12px] border-l-transparent"></span>
            <fieldset class="flex flex-col gap-2">
              <label class="block"><input name="credit-card" type="radio" class="mr-2 text-primary focus:ring-0">American
                Express ending in 1059 (expires 11/25)</label>
              <label class="block"><input name="credit-card" type="radio" class="mr-2 text-primary focus:ring-0">Use a new
                payment method</label>
            </fieldset>
            <p>ACH instructions will be provided in your order confirmation email. Your campaign will be manually reviewed
              and activated.</p>
            <p>Wire transfer instructions will be provided in your order confirmation email. Your campaign will be
              manually reviewed and activated.</p>
          </div>
        </div>
        <div>
          <fieldset class="flex gap-4">
            <span class="font-bold inline-block mr-2">Pay Per</span>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Campaign</label>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Flight</label>
          </fieldset>
          <fieldset class="flex gap-4 mt-3">
            <span class="font-bold inline-block mr-2">Payment Frequency</span>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Campaign</label>
            <label class="inline-block"><input name="pay-per" type="radio"
                class="mr-2 text-primary focus:ring-0">Flight</label>
          </fieldset>
          <div class="table-container mt-5">
            <table>
              <tr class="thead-tr">
                <th>Amount</th>
                <th>Due Date</th>
              </tr>
              <tr class="tbody-tr">
                <td>$3,250</td>
                <td>March 1, 2023</td>
              </tr>
              <tr class="tbody-tr">
                <td>$3,250</td>
                <td>March 1, 2023</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <button class="button-primary mx-auto mt-8">Generate PDF to Sign</button>
      <p class="text-xs text-center mt-4">NOTE: By signing the generated PDF, you are agreeing to our <a href=""
          class="text-primary">Ad Policy & Guidelines</a>, <a href="" class="text-primary">Terms & Conditions</a>, and <a
          href="" class="text-primary">Privacy Policy</a>.</p>
      <button class="button-primary fixed bottom-0 left-0" @click.prevent="back(5);">
        <i class="fa-solid fa-arrow-left mr-2"></i> Back</button>
      <div class="fixed right-0 bottom-0">
        <button class="button-secondary">Exit Builder</button>
        <button class="button-primary">Purchase & Launch</button>
      </div>
    </div>
    <!-- END OF STEP 5 -->

    <!-- SUCCESS -->
    <div id="" class="campaign-builder-step-container justify-center items-center gap-3" :aria-expanded="false">
      <img src="http://via.placeholder.com/200x200" alt="" class="mb-4">
      <h2 class="text-4xl font-bold">Success!</h2>
      <p class="text-2xl mb-5">Your Your campaign is built & ready for launch.</p>
      <button class="button-primary">All Campaigns</button>
      <button class="button-secondary text-sm uppercase">Account Home</button>
    </div>
    <!-- SUCCESS -->
  </div>

  <!-- MODAL EXIT BUILDER -->
  <div id="modal-delete-user" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <div class="flex items-center justify-center mt-10 mb-4 gap-5 text-2xl">
        <i class="fa-solid fa-triangle-exclamation fa-2xl"></i>
        <div>
          <h2 class="text-xl lg:text-2xl font-bold">Are you sure you want to exit the builder?</h2>
          <p class="text-base">Don't forget to save your changes before you exit so you can come back to your draft later.
          </p>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Back to Builder
        </button>
        <button class="button-primary">
          Save Draft
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EXIT BUILDER -->

  <!-- MODAL CREATE NEW ADVERTISER -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-3xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-10">Add New Advertiser</h2>
      <form action="" class="grid grid-cols-2 gap-x-10 gap-y-8 max-w-3xl mx-auto">
        <label>
          <span>Advertiser Name <sup>*</sup></span>
          <input type="text" />
        </label>
        <label>
          <span>Advertiser Logo</span>
          <input type="file" id="myFile" name="filename">
          <div class="text-sm"><span class="font-bold">Accepted File Types:</span> jpg, gif, png</div>
          <div class="text-sm"><span class="font-bold">Max. File Size:</span> 1 MB.</div>
        </label>
        <label>
          <span>Website URL <sup>*</sup></span>
          <input type="text" />
        </label>
        <label>
          <span>Advertiser Industry <sup>*</sup></span>
          <select name="select">
            <option value="">- Select -</option>
            <option value="Arts &amp; Entertainment">Arts &amp; Entertainment</option>
            <option value="Automotive" selected="selected">Automotive</option>
            <option value="Business">Business</option>
            <option value="Careers">Careers</option>
            <option value="Education">Education</option>
            <option value="Family &amp; Parenting">Family &amp; Parenting</option>
            <option value="Food &amp; Drink">Food &amp; Drink</option>
            <option value="Health &amp; Fitness">Health &amp; Fitness</option>
            <option value="Hobbies &amp; Interests">Hobbies &amp; Interests</option>
            <option value="Home &amp; Garden">Home &amp; Garden</option>
            <option value="News">News</option>
            <option value="Personal Finance">Personal Finance</option>
            <option value="Pets">Pets</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Religion &amp; Spirituality">Religion &amp; Spirituality</option>
            <option value="Science">Science</option>
            <option value="Shopping">Shopping</option>
            <option value="Society">Society</option>
            <option value="Sports">Sports</option>
            <option value="Style &amp; Fashion">Style &amp; Fashion</option>
            <option value="Technology &amp; Computing">Technology &amp; Computing</option>
            <option value="Travel">Travel</option>
            <option value="Other">Other</option>
          </select>
        </label>
      </form>
      <div class="modal-actions">
        <button class="button-secondary">
          Discard
        </button>
        <button class="button-primary">
          Add Advertiser
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL CREATE NEW ADVERTISER -->

  <!-- MODAL THIRD PARTY SEGMENT GROUP -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-5xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h3 class="text-sm font-bold text-[#AAAAAA] text-center">Third-Party Segment Group</h3>
      <h2 class="text-3xl font-bold text-center mb-8">Play Sports</h2>
      <div class="table-container">
        <table>
          <tr class="thead-tr">
            <th>Data Provider</th>
            <th>Description</th>
            <th>Additional Info</th>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Travel Intent > Activities > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Acxiom</td>
            <td>US Travel > Likely Activity > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>SK Planet</td>
            <td>SK Planet > Area and Place > Destination > Sports/Leisure > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Fifty</td>
            <td>Fifty > Sports > Individual Sports > Racquet Sports > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Acxiom</td>
            <td>Acxiom France > Behavioral > Lifestyle > Hobbies & Interests</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Oracle</td>
            <td>Oracle > Hobbies & Interests (Affinity) > Sports Players > Racket</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Bombora</td>
            <td>Sports > Tennis B2C > B2C Lifestyle > Outdoor Activities > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Hockey - Precision</td>
            <td>Plays Hockey - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Soccer - Precision</td>
            <td>Plays Soccer - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Tennis - Precision</td>
            <td>Plays Tennis - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Travel Intent > Activities > Tennis US Travel > Likely Activity > Tennis</td>
            <td>Plays Tennis - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Acxiom</td>
            <td>SK Planet > Area and Place > Destination > Sports/Leisure > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>SK Planet</td>
            <td>Fifty > Sports > Individual Sports > Racquet Sports > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Fifty</td>
            <td>Acxiom France > Behavioral > Lifestyle > Hobbies & Interests</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Bombora</td>
            <td>(Affinity) > Sports Players > Racket Sports > Tennis</td>
            <td>Tennis </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Hockey - Precision</td>
            <td>Plays Hockey - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Soccer - Precision</td>
            <td>Plays Soccer - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Lifestyle and Interests > Plays Sports > Plays Tennis - Precision</td>
            <td>Plays Tennis - Precision </td>
          </tr>
          <tr class="tbody-tr compressed">
            <td>Experian</td>
            <td>Travel Intent > Activities > Tennis</td>
            <td>Tennis</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
  <!-- END OF MODAL THIRD PARTY SEGMENT GROUP -->

  <!-- MODAL REQUEST AUDIENCE CHANGE -->
  <div id="" class="modal " :class="{hidden:hideAudienceRequest}">
    <div class="modal-body max-w-3xl">
      <button class="close-modal" @click.prevent="hideAudienceRequest = 1">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-2">Request Audience Change</h2>
      <p class="text-center mb-8">Submit the form below and your account rep will be in touch to help with this change.
      </p>
      <form action="" class="flex flex-col gap-4">
        <label>
          <span>Audience ID(s) <sup>*</sup></span>
          <input type="text" v-model="audienceRequest.ids" />
        </label>
        <label>
          <span>What is the change that needs to be made? <sup>*</sup></span>
          <textarea name="" id="" rows="4" v-model="audienceRequest.content"></textarea>
        </label>
      </form>
      <div class="modal-actions">
        <button class="button-secondary" @click.prevent="hideAudienceRequest = 1">
          Discard
        </button>
        <button class="button-primary" @click.prevent="submitAudieneRequest">
          Submit Request
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL REQUEST AUDIENCE CHANGE -->

  <!-- MODAL EDIT GEOTARGETING -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-8">Edit Geotargeting</h2>
      <div class="table-container">
        <table>
          <tr class="thead-tr">
            <th class="w-3/12">Action</th>
            <th class="w-4/12">Type</th>
            <th class="w-5/12">Location</th>
          </tr>
          <tr class="tbody-tr">
            <td class="flex">
              <button class="text-primary py-2 pl-1 pr-3"><i class="fa-solid fa-xmark"></i></button>
              <label class="grow">
                <select name="" id="">
                  <option value="" selected>Include</option>
                </select>
              </label>
            </td>
            <td>
              <label class="grow">
                <select name="" id="">
                  <option value="" selected>Country</option>
                </select>
              </label>
            </td>
            <td>
              <div class="flex flex-wrap gap-1">
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Afghanistan</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Albania</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Algeria</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Afghanistan</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Afghanistan</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Albania</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Algeria</button>
                <button class="button-xs"><i class="fa-solid fa-xmark"></i> Afghanistan</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Discard
        </button>
        <button class="button-primary">
          Save Changes
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EDIT GEOTARGETING -->

  <!-- MODAL Specs & Details: Connected TV / CTVx™ -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h3 class="text-sm font-bold text-[#AAAAAA] text-center mb-2">Specs & Details</h3>
      <label class="relative flex items-center justify-center gap-3 cursor-pointer col-span-2">
        <span class="text-3xl font-bold w-80 text-right">Connected TV</span>
        <input type="checkbox" class="sr-only peer" />
        <div
          class="relative w-9 h-5 bg-white ring-2 ring-primary rounded-full peer peer-checked:bg-primary peer-checked:after:bg-white peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-primary after:rounded-full after:h-4 after:w-4 after:transition-all">
        </div>
        <span class="text-3xl font-bold w-80">CTVx™</span>
      </label>
      <div class="flex justify-center items-center gap-4 mt-4">
        <span class="font-bold">Devices Targeted:</span>
        <div class="flex text-xs items-center gap-2"><img src="assets/tv.png" alt=""> Large-Format CTV</div>
        <div class="flex text-xs items-center gap-2"><img src="assets/laptop.png" alt=""> Laptop/Desktop</div>
        <div class="flex text-xs items-center gap-2"><img src="assets/phone.png" alt=""> Mobile</div>
      </div>
      <div class="grid grid-cols-5 mt-5 gap-5">
        <div class="col-span-2">
          <div><span class="font-bold">File Type:</span> MP4</div>
          <div><span class="font-bold">File Size:</span> 16:9 ratio (min. 1920x1080)</div>
          <div><span class="font-bold">Duration:</span> 15, 30, or 60 seconds</div>
          <div><span class="font-bold">Bitrate Range:</span> 18,000-30,000 kbps</div>
          <div><span class="font-bold">Frame Rate (FPS):</span> 23.98 or 29.97</div>
          <div><span class="font-bold">Audio Sample Rate:</span> 48kHz</div>
          <div><span class="font-bold">Maximum File Size:</span> 1G</div>
        </div>
        <div class="col-span-3">
          <span class="font-bold mb-2 block">Partners:</span>
          <div class="grid grid-cols-4 gap-3">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
            <img src="http://via.placeholder.com/300x200" alt="" class="w-full h-20 object-contain">
          </div>
        </div>
        <div class="col-span-5">
          <span class="block font-bold">MVPDs, Networks & Apps:</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">A&E</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">ABC</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">AMC</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">AT&T TV (DirecTV)</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">BBC</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Bounce TV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">CBSi</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">CNN</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Comcast XFINITY</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">CrunchyRoll</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">The CW</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Discovery Networks</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Discovery+</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">ESPN</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Fox Business</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Fox Networks</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Fox News</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Fox</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Sports</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">FuboTV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Gravitas Ventures</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Haystack</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Hulu</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Jukin Media</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Local Now</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">MLB</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">NBC Universal</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Newsy</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Paramount+</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Pluto TV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Popcorn Flix</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">The Roku Channel</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Sinclair</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Sky News</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Sling TV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">SnagFilms</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Sony</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Crackle</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">TubiTV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Twitch.TV</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Vevo</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Vudu Movies</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Warner Media</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Weather Nation</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">XITE</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Xumo</span>
          <span class="inline-block after:content-['▪'] after:mx-1 last:after:hidden">Youtube TV</span>
        </div>
      </div>
    </div>
  </div>
  <!-- END OF MODAL Specs & Details: Specs & Details / CTVx™ -->

  <!-- MODAL Specs & Details: Display / Display Video -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-3xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h3 class="text-sm font-bold text-[#AAAAAA] text-center mb-2">Specs & Details</h3>
      <label class="relative flex items-center justify-center gap-3 cursor-pointer col-span-2">
        <span class="text-3xl font-bold w-80 text-right">Display</span>
        <input type="checkbox" class="sr-only peer" />
        <div
          class="relative w-9 h-5 bg-white ring-2 ring-primary rounded-full peer peer-checked:bg-primary peer-checked:after:bg-white peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-primary after:rounded-full after:h-4 after:w-4 after:transition-all">
        </div>
        <span class="text-3xl font-bold w-80">Display Video</span>
      </label>
      <div class="flex justify-center items-center gap-4 mt-4">
        <span class="font-bold">Devices Targeted:</span>
        <div class="flex text-xs items-center gap-2"><img src="assets/laptop.png" alt=""> Laptop/Desktop</div>
        <div class="flex text-xs items-center gap-2"><img src="assets/phone.png" alt=""> Mobile</div>
      </div>
      <!-- Display -->
      <div class="grid grid-cols-2 mt-5 gap-5">
        <div>
          <div><span class="font-bold">File Type:</span> JPEG, PNG, GIF, or HTML5 </div>
          <div><span class="font-bold">Recommended Sizes:</span> 160x600, 320x50, 300x250, 728x90 </div>
          <div><span class="font-bold">Max File Size:</span> 150kb Max </div>
          <div><span class="font-bold">Background Color:</span> Creatives with partially white or black backgrounds
            must include a visible border of a contrasting color </div>
          <div>*Must not appear sideways or upside down</div>
        </div>
        <div class="flex flex-col">
          <div><span class="font-bold">Additional Specs for Animation</span></div>
          <div><span class="font-bold">File Type:</span> GIF or HTML5 </div>
          <div><span class="font-bold">Duration:</span> 15 sec animation </div>
          <div>*infinite looping allowed</div>
          <div><span class="font-bold">Click Action:</span> Must open a new page</div>
          <a class="button-primary block text-center mt-auto">HTML5 Specs & Instructions</a>
        </div>
      </div>
      <!-- Display -->
      <!-- Display Video -->
      <div class="grid grid-cols-2 mt-5 gap-5">
        <div>
          <div><span class="font-bold">File Type:</span> MP4 preferred </div>
          <div>*FLV, WEBM, MOV, MPG, MPEG also allowed </div>
          <div><span class="font-bold">File Size:</span> 200MB </div>
          <div><span class="font-bold">Max Resolution:</span> 16:9 at 1920 or 4:3 at 640x480 </div>
          <div><span class="font-bold">Bitrate:</span> Greater than or equal to 2500 kbps </div>
          <div><span class="font-bold">Duration:</span> 5 seconds — 300 seconds (5 min) </div>
          <div>> We recommend :15, :30, or :60 </div>
          <div><span class="font-bold">Audio: Optional but recommended</span></div>
          <div><span class="font-bold">Destination URL:</span> Required</div>
        </div>
        <div class="flex flex-col">
          <p class="mb-3 last:mb-0"><span class="font-bold block">Instream Video</span> advertising delivered pre-roll,
            mid-roll or post-roll within a video (think YouTube)</p>
          <p class="mb-3 last:mb-0"><span class="font-bold block">Outstream Video</span> typically turns up in non-video
            environments such as on news websites (think BBC or Wall Street Journal), where the video ad begins playing as
            you scroll down the page reading an article.</p>
        </div>
      </div>
      <!-- Display Video -->
    </div>
  </div>
  <!-- END OF MODAL Specs & Details: Display / Display Video -->


  <!-- MODAL CREATIVE LIBRARY -->
  <div id="" class="modal " :class="{hidden:!showCreativeModal}">
    <div class="modal-body max-w-6xl">
      <button class="close-modal" @click.prevent="showCreativeModal =0">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-4">Creative Library</h2>
      <creativeLibrary mode="builder"  @setCreative="setAdgroupCreative" />
    </div>
  </div>
  <!-- END OF MODAL CREATIVE LIBRARY -->

  <!-- MODAL UPLOAD NEW CREATIVE -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-4xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-4">Upload New Creative</h2>
      <div class="grid grid-cols-2 gap-x-10 gap-y-4">
        <label class="col-span-2">
          <span>Creative Name <sup>*</sup></span>
          <input type="text">
          <div class="text-sm mt-1">Any name to help you identify the creative</div>
        </label>
        <label>
          <span>Creative Type <sup>*</sup></span>
          <select name="" id="">
            <option value="">Select a Type</option>
          </select>
        </label>
        <fieldset>
          <legend class="font-bold">Serving Type <sup>*</sup></legend>
          <div><input type="radio" class="mr-2">Hosted (Upload File)</div>
          <div><input type="radio" class="mr-2">Third-Party (Display Tag)</div>
        </fieldset>
        <label>
          <span>Destination URL <sup>*</sup></span>
          <input type="text">
          <div class="text-sm mt-1">Full url including protocol</div>
        </label>
        <label>
          <span>Query String <sup>*</sup></span>
          <input type="text">
        </label>
        <!-- Me parece que este componenete para subir archivos ya lo tienes. -->
        <label>
          <span>Upload File <sup>*</sup></span>
        </label>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Discard
        </button>
        <button class="button-primary">
          Upload
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL UPLOAD NEW CREATIVE -->

  <!-- MODAL EDIT CREATIVE -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-3xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold mb-1 text-center">Edit Creative</h2>
      <h3 class="text-center uppercase text-primary text-xs font-bold">Archive</h3>
      <form class="grid grid-cols-4 gap-x-3 gap-y-4 mt-5">
        <label class="col-span-4 flex items-center gap-3 relative">
          <span>Name</span>
          <input type="text" />
          <button class="absolute right-0 top-0 h-full w-9 pt-1 hover:text-primary"><i
              class="fa-sharp fa-solid fa-pen-to-square"></i></button>
        </label>
        <div class="col-span-4 grid grid-cols-3">
          <div>
            <span class="block"><b>Type:</b></span>
            <span class="block">Video</span>
          </div>
          <div>
            <span class="block"><b>Qualifies For:</b></span>
            <span class="block">CTV, CTVx, Display Video</span>
          </div>
          <div>
            <span class="block"><b>Upload Date:</b></span>
            <span class="block">February 2, 2023</span>
          </div>
        </div>
        <div class="col-span-4 flex gap-5">
          <div class="w-7/12">
            <img src="https://via.placeholder.com/390x220" alt="" class="max-w-full w-full">
          </div>
          <div class="w-5/12">
            <span class="block"><b>Upload Date:</b> February 2, 2023</span>
            <span class="block"><b>File Size:</b> MB</span>
            <span class="block"><b>Dimensions (px):</b> X </span>
            <span class="block"><b>Bitrate:</b> kbps</span>
          </div>
        </div>
        <label class="col-span-4">
          <span>Landing Page <sup>*</sup></span>
          <input type="text" />
        </label>
        <span class="col-span-4 font-bold -mb-4">Tracking Info</span>
        <label>
          <span class="text-sm font-normal">Source</span>
          <input type="text" />
        </label>
        <label>
          <span class="text-sm font-normal">Medium</span>
          <input type="text" />
        </label>
        <label>
          <span class="text-sm font-normal">Campaign</span>
          <input type="text" />
        </label>
        <label>
          <span class="text-sm font-normal">Content</span>
          <input type="text" />
        </label>
        <div class="col-span-4 flex gap-3">
          <label class="w-1/2">
            <span class="text-sm font-normal">Custom Param</span>
            <input type="text" />
          </label>
          <label class="w-1/2">
            <span class="text-sm font-normal">Custom Value</span>
            <input type="text" />
          </label>
        </div>
        <div class="col-span-4 mb-2">
          <button class="font-bold text-sm col-span-2"><i class="fa-solid fa-circle-plus text-primary"></i> Add Custom
            Parameters</button>
        </div>
        <div class="col-span-4 flex gap-3 max-w-lg items-center">
          <span class="font-bold block pb-5 shrink-0">Dates Available</span>
          <label>
            <input type="datetime-local" />
            <span class="uppercase text-xs text-center block mt-2">Start Date</span>
          </label>
          <label>
            <input type="datetime-local" />
            <span class="uppercase text-xs text-center block mt-2">End Date</span></label>
        </div>
      </form>
      <div class="modal-actions">
        <button class="button-secondary">
          Discard
        </button>
        <button class="button-primary">
          Save Changes
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL EDIT CREATIVE -->

  <!-- MODAL VIEW FLIGHTS -->
  <div id="" class="modal "  :class="{hidden: !showFlightModal}">
    <div class="modal-body max-w-3xl">
      <button class="close-modal"  @click.prevent="showFlightModal =0">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold text-center mb-4">View Flights</h2>
      <div class="border-2 border-black flex items-center h-[42px] mt-4 max-w-fit mx-auto">
        <div class="px-4 border-r-2 border-black h-full flex justify-center items-center text-center grow font-bold">
          Campaign Dates</div>
        <div class="px-5 text-center grow"> 
          {{currentCampaign.startDate }} - {{currentCampaign.endDate }}
        </div>
      </div>
      <div class="table-container mt-9">
        <table>
          
          <tr class="thead-tr">
            <th class="min-w-[17%]">Start Date</th>
            <th class="min-w-[17%]">End Date</th>
          </tr>
          <tbody v-for="(flight, index) in flights" :key="index">
          <tr class="tbody-tr" >
            <td><input type="date" v-model="flight.startDate"/></td>
            <td><input type="date" v-model="flight.endDate"/></td>
          </tr>
          <tr>
            <td colspan="2">
              <span class="text-red">{{ flight.message }}</span>
            </td>
          </tr>
        </tbody>
        </table>
        <button class="button-primary ml-auto block mt-4" @click.prevent="addFlight">
          New Flight
        </button>
        <button class="button-primary ml-auto block mt-4" @click.prevent="saveFlights">
          Save Flights
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL VIEW FLIGHTS -->

  <!-- MODAL PREVIEW CREATIVE -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-3xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold mb-3 text-center">Preview Creative</h2>
      <p class="text-center">Creative Name: Jockey Underwear :30 Commercial “Fever”</p>
      <div class="flex gap-5 mt-10">
        <div class="w-7/12">
          <img src="https://via.placeholder.com/390x220" alt="" class="max-w-full w-full">
        </div>
        <div class="w-5/12">
          <span class="block"><b>Upload Date:</b> February 2, 2023</span>
          <span class="block"><b>File Size:</b> MB</span>
          <span class="block"><b>Dimensions (px):</b> X </span>
          <span class="block"><b>Bitrate:</b> kbps</span>
          <span class="block"><b>Qualifies For:</b></span>
          <span class="block">CTV, CTVx, Display Video</span>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="w-1/2">
          <span class="font-bold block">Destination URL <sup class="text-primary font-normal">*</sup></span>
          <span class="block">https://www.jockey.com</span>
        </div>
        <div class="w-1/2">
          <span class="font-bold block">Query String</span>
          <span class="block">/catalog/all/all/all?q=worry%20free</span>
        </div>
      </div>
      <div class="modal-actions">
        <button class="button-secondary">
          Discard
        </button>
        <button class="button-primary">
          Save Changes
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL PREVIEW CREATIVE -->

  <!-- MODAL AUDIENCE DETAILS -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-2xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold mb-3 text-center">Audience Details</h2>
      <p class="text-center">Households of Women Diagnosed with Heavy Periods</p>
      <h3 class="font-bold text-center mt-7">Audience Logic <sup><i class="fa-solid fa-circle-question fa-xs"></i></sup>
      </h3>
      <div class="text-center">
        <span class="font-bold text-primary mr-1 last:mr-0">Women</span>
        <span class="mr-1 last:mr-0">&&</span>
        <span class="font-bold text-primary mr-1 last:mr-0">Diagnosed with Heavy Periods</span>
        <span class="mr-1 last:mr-0">&&!</span>
        <span class="font-bold text-primary mr-1 last:mr-0">Current Customers</span>
        <span class="mr-1 last:mr-0">||</span>
        <span class="font-bold text-primary mr-1 last:mr-0">Poor</span>
      </div>
      <div class="flex mt-8 text-center">
        <div class="w-1/2">
          <div class="bg-black text-white w-[30px] h-[30px] flex justify-center items-center mx-auto mb-2">
            <i class="fa-sharp fa-solid fa-house fa-sm"></i>
          </div>
          <span class="block font-bold">86,000</span>
          <span class="block font-bold">Households <sup><i class="fa-solid fa-circle-question fa-xs"></i></sup></span>
        </div>
        <div class="w-1/2">
          <div class="bg-black text-white w-[30px] h-[30px] flex justify-center items-center mx-auto mb-2">
            <i class="fa-sharp fa-solid fa-desktop fa-sm"></i>
          </div>
          <span class="block font-bold">301,600</span>
          <span class="block font-bold">CTV Devices <sup><i class="fa-solid fa-circle-question fa-xs"></i></sup></span>
        </div>
      </div>
    </div>
  </div>
  <!-- END OF MODAL AUDIENCE DETAILS -->

  <!-- MODAL NEW BILLING PROFILE -->
  <div id="" class="modal hidden">
    <div class="modal-body max-w-2xl">
      <button class="close-modal">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold mb-8 text-center">New Billing Profile</h2>
      <div class="grid grid-cols-2 gap-x-10 gap-y-4">
        <label>
          <span>First Name<sup>*</sup></span>
          <input type="text">
        </label>
        <label>
          <span>Last Name<sup>*</sup></span>
          <input type="text">
        </label>
        <label>
          <span>Organization<sup>*</sup></span>
          <input type="text">
        </label>
        <label>
          <span>Country / Region<sup>*</sup></span>
          <select name="" id=""></select>
        </label>
        <label class="col-span-2">
          <span>Street Address<sup>*</sup></span>
          <input type="text" placeholder="Street Address 1" class="mb-2">
          <input type="text" placeholder="Street Address 2">
        </label>
        <label class="col-span-2">
          <span>Town / City<sup>*</sup></span>
          <input type="text" placeholder="Street Address 2">
        </label>
        <label>
          <span>State<sup>*</sup></span>
          <select name="" id=""></select>
        </label>
        <label>
          <span>Phone<sup>*</sup></span>
          <input type="text" name="" id="">
        </label>
        <label class="col-span-2">
          <span>Email Address<sup>*</sup></span>
          <input type="text">
        </label>
        <p class="col-span-2"><b>Advertiser:</b>{{currentCampaign.advertiserName}}</p>
      </div>
      <div class="modal-actions">
        <button class="button-primary">
          Save Billing Profile
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL NEW BILLING PROFILE -->

<!-- MODAL NEW Flight -->
<div id="" class="modal " :class="{hidden: !showAddFlightModal}">
    <div class="modal-body max-w-2xl">
      <button class="close-modal" @click.prevent="showAddFlightModal=0;">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <h2 class="text-3xl font-bold mb-8 text-center">New Flight</h2>
      <div class="grid grid-cols-2 gap-x-10 gap-y-4">
        <label>
          <span>Start Date<sup>*</sup></span>
          <input type="date" v-model="newFlight.startDate"/>
        </label>
        <label>
          <span>End Date<sup>*</sup></span>
          <input type="date" v-model="newFlight.endDate" />
        </label>
      </div>
      <p class="text-red" v-if="!newFlight.valid && newFlight.message">
          {{ newFlight.message }}
      </p>
      <div class="modal-actions">
        <button class="button-primary"  @click.prevent="addFlight(newFlight);">
          Add Flight
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL  NEW Flight -->

</template>

<style></style>
