<script setup>
import { ref, onMounted, computed } from "vue"
import { api } from '../../helpers'

const advertisers         = ref([]);
const search              = ref('');
const searchIndustry      = ref('');
const new_advertiser      = ref([]);
const eAdvertiser         = ref([]);
const showEdit            = ref(0);
const showNew             = ref(0);
const showSuccessMessage  = ref(0);
const showDeleteModal     = ref(0);
const user_id             = localStorage.getItem('userId');

const files           = ref([]);
const upload          = ref(null);

const add_advertiser = async () => {
  try {
    document.body.classList.add("loading");
      const response = await api.post('/add_advertiser', {
        'user_id'     : user_id,
        'name'        : new_advertiser.value.name,
        'website_url' : new_advertiser.value.website_url,
        'industry'    : new_advertiser.value.industry,
        'logo_url'    : new_advertiser.value.logo_url,
      });

    const response2 = await api.get('/get_advertisers?user_id=' + user_id);
    document.body.classList.remove("loading");

    showNew.value             = 0;
    advertisers.value         = response2.data.data;
    showSuccessMessage.value  = 1;
    files.value               = [];
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const startEdit = (advertiser) => {
  eAdvertiser.value = advertiser;
showEdit.value      = 1;
}
const startNew = () => {
  new_advertiser.value  = {};
  showNew.value         = 1;
}

const save_advertiser = async () => {
  try {
    document.body.classList.add("loading");
      const response = await api.post('/edit_advertiser', {
        'user_id'     : user_id,
        'profile_id'  : eAdvertiser.value.id,
        'name'        : eAdvertiser.value.name,
        'website_url' : eAdvertiser.value.website_url,
        'industry'    : eAdvertiser.value.industry,
        'new_logo'    : eAdvertiser.value.new_logo,

      });
      const response2 = await api.get('/get_advertisers?user_id=' + user_id);
    document.body.classList.remove("loading");

    advertisers.value = response2.data.data;
    eAdvertiser.value        = [];
    showEdit.value           = 0;
    showSuccessMessage.value = 1;
    files.value              = [];
    return response;
  } catch (error) {
    return {
      status: error.response.status,
      message: error.response.data.message,
    };
  }
}

const deleteAdvertiser = async (advertiser) => {
  try {

    document.body.classList.add("loading");
      const response  = await api.get('/remove_advertiser?user_id=' + user_id + '&profile_id=' + advertiser.id);
      const response2 = await api.get('/get_advertisers?user_id=' + user_id);
    document.body.classList.remove("loading");
    advertisers.value = response2.data.data;
    clearCurrentAdvertiser();
    return response;

  } catch (error) {

    return {
      status: error.response.status,
      message: error.response.data.message,
    };

  }
}

const clearCurrentAdvertiser = () => {
  eAdvertiser.value = [];
}

const filteredAdvertisers   = computed(() => {
  return advertisers.value.filter((val) => { 
    if(!val.industry){
      val.industry = ' ';
    }
    return val.name.toString().toLowerCase().includes(search.value) && val.industry.toString().includes(searchIndustry.value) 
  }).sort((a,b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1);

}); 

onMounted(async () => {
  const account  = JSON.parse(localStorage.getItem('account'));
  document.body.classList.add("loading");
    const response = await api.get('/get_advertisers?account_id=' + account.id);
  document.body.classList.remove("loading");
  advertisers.value = response.data.data;
});

const inputFile =  async (newFile, oldFile)=> {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }

      }
      // Thumbnails
      newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
         
          newFile.thumb = newFile.blob
        }

        let formData = new FormData();
        formData.append('filepond', newFile.file);
        document.body.classList.add("loading");
          const response = await api.post('/file_upload', 
            formData
            , {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
          );
        document.body.classList.remove("loading");

        new_advertiser.value.new_logo = response.data.data;
        eAdvertiser.value.new_logo    = response.data.data;
    };
 
    const inputFilter =  function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }
     
    };
</script>

<template>
  <div id="advertisers" class="px-10">

    <div v-if="!showEdit && !showNew">
      <div class="w-full flex flex-col md:flex-row gap-4 lg:gap-5 md:mb-5">
        <label class="shrink-0 w-3/12">
      
          <select v-model="searchIndustry">
            <option value="">Industry: All</option>
         
            <option value="Arts &amp; Entertainment">Arts &amp; Entertainment</option>
            <option value="Automotive" selected="selected" data-i="0">Automotive</option>
            <option value="Business">Business</option>
            <option value="Careers">Careers</option>
            <option value="Education">Education</option>
            <option value="Family &amp; Parenting">Family &amp; Parenting</option>
            <option value="Food &amp; Drink">Food &amp; Drink</option>
            <option value="Health &amp; Fitness">Health &amp; Fitness</option>
            <option value="Hobbies &amp; Interests">Hobbies &amp; Interests</option>
            <option value="Home &amp; Garden">Home &amp; Garden</option>
            <option value="News">News</option>
            <option value="Personal Finance">Personal Finance</option>
            <option value="Pets">Pets</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Religion &amp; Spirituality">Religion &amp; Spirituality</option>
            <option value="Science">Science</option>
            <option value="Shopping">Shopping</option>
            <option value="Society">Society</option>
            <option value="Sports">Sports</option>
            <option value="Style &amp; Fashion">Style &amp; Fashion</option>
            <option value="Technology &amp; Computing">Technology &amp; Computing</option>
            <option value="Travel">Travel</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label class="relative grow">
          <input placeholder="Search" type="text" v-model="search" />
          <i class="fa-solid fa-magnifying-glass absolute top-1/2 -translate-y-1/2 right-4 text-primary"></i>
        </label>
        <button class="button-primary" @click="showNew=1">Add New Advertiser</button>
      </div>
      <div class="table-container">
        <table class="w-full">
          <tr class="thead-tr">
            <th class="w-1/12">
              Logo
            </th>
            <th class="w-4/12">
              Profile Name 
            </th>
            <th class="w-4/12">
              Website URL
            </th>
            <th class="w-3/12">
              Industry 
            </th>
          </tr>
          <tr class="tbody-tr" v-for="advertiser in filteredAdvertisers">
            <td>
              <img :src="advertiser.logo_url" alt="" class="w-full h-12 object-contain object-center" />
            </td>
            <td>
              <span class="block">{{ advertiser.name }}</span>
              <button class="table-action" @click.prevent="startEdit(advertiser)">Edit</button>
              <button class="table-action" @click.prevent="showDeleteModal =1; eAdvertiser=advertiser; ">Delete</button>
            </td>
            <td>
              <a href="#" class="text-primary">{{ advertiser.website_url }}</a>
            </td>
            <td>
              <span>{{ advertiser.industry }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="edit_profile" v-if="showEdit">
      <button class="uppercase font-bold text-sm" @click.prevent="showEdit = false">
        <i class="fa-solid fa-arrow-left text-primary mr-1"></i> Back to
        Advertiser List
      </button>

      <div class="grid lg:grid-cols-2 gap-x-5 gap-y-4 max-w-xl mx-auto">
        <div class="col-span-2">
          <h2 class="font-bold text-3xl text-center mb-8">Edit Advertiser</h2>
          <div class="w-[100px] h-[100px] rounded-full overflow-hidden relative border-primary border-2 mx-auto">
              <img  v-if="files.length ==0" :src="eAdvertiser.logo_url" alt="" class="object-cover object-center w-full h-full" />
              <img class="object-cover object-center w-full h-full" v-if="files.length >0" :src="files[0].thumb" />
              <div class="bg-black/60 w-full h-full absolute left-0 top-0"></div>
              <file-upload
                  class=" absolutex top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white "
                    ref="upload"
                    v-model="files"
                    :custom-action="customAction"
                    @input-file="inputFile"
                    @input-filter="inputFilter"
              >
                <i class="fa-light fa-camera fa-2x absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"></i>
              </file-upload>
            </div>
          <small class="text-neutral-500 text-center w-full block mt-3">Advertiser Logo</small>
        </div>
        <div class="col-span-2">
          <label>
            <span>Advertiser Name <sup>*</sup></span>
            <input type="text" v-model="eAdvertiser.name" /></label>
        </div>
        <label>
          <span>Website URL <sup>*</sup></span>
          <input type="text" v-model="eAdvertiser.website_url" />
        </label>
        <label>
          <span>Advertiser Industry <sup>*</sup></span>
          <select name="select" v-model="eAdvertiser.industry">
            <option value="">- Select -</option>
            <option value="Arts &amp; Entertainment">Arts &amp; Entertainment</option>
            <option value="Automotive" selected="selected" data-i="0">Automotive</option>
            <option value="Business">Business</option>
            <option value="Careers">Careers</option>
            <option value="Education">Education</option>
            <option value="Family &amp; Parenting">Family &amp; Parenting</option>
            <option value="Food &amp; Drink">Food &amp; Drink</option>
            <option value="Health &amp; Fitness">Health &amp; Fitness</option>
            <option value="Hobbies &amp; Interests">Hobbies &amp; Interests</option>
            <option value="Home &amp; Garden">Home &amp; Garden</option>
            <option value="News">News</option>
            <option value="Personal Finance">Personal Finance</option>
            <option value="Pets">Pets</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Religion &amp; Spirituality">Religion &amp; Spirituality</option>
            <option value="Science">Science</option>
            <option value="Shopping">Shopping</option>
            <option value="Society">Society</option>
            <option value="Sports">Sports</option>
            <option value="Style &amp; Fashion">Style &amp; Fashion</option>
            <option value="Technology &amp; Computing">Technology &amp; Computing</option>
            <option value="Travel">Travel</option>
            <option value="Other">Other</option>
          </select>
        </label>
      </div>
      <button type="submit" class="button-primary mt-10 mx-auto block" @click.prevent="save_advertiser">
        Save Changes
      </button>
    </div>

    <div id="tab2" aria-expanded="false" class=" aria-expanded:block overflow-x-auto pb-6" v-if="showNew">
      <form action="" class="grid lg:grid-cols-2 gap-6 lg:gap-x-10 lg:gap-y-8 max-w-3xl mx-auto mt-2">
        <div class="">
          <label for="">Advertiser Name <sup class="text-primary">*</sup>
            <input type="text" class="shadow-[0px_4px_8px_rgba(0,0,0,.15)] w-full p-3 mt-2"
              v-model="new_advertiser.name" /></label>
        </div>
        <div class="">
          <div class="w-[100px] h-[100px] rounded-full overflow-hidden relative border-primary border-2 mx-auto">
              <img  v-if="files.length ==0" :src="new_advertiser.new_logo" alt="" class="object-cover object-center w-full h-full" />
              <img class="object-cover object-center w-full h-full" v-if="files.length >0" :src="files[0].thumb" />
              <div class="bg-black/60 w-full h-full absolute left-0 top-0"></div>
              <file-upload
                  class=" absolutex top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white "
                    ref="upload"
                    v-model="files"
                    :custom-action="customAction"
                    @input-file="inputFile"
                    @input-filter="inputFilter"
              >
                <i class="fa-light fa-camera fa-2x absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"></i>
              </file-upload>
            </div>
        </div>
        <div class="">
          <label for="">Website URL <sup class="text-primary">*</sup>
            <input type="text" class="shadow-[0px_4px_8px_rgba(0,0,0,.15)] w-full p-3 mt-2"
              v-model="new_advertiser.website_url" /></label>
        </div>
        <div class="">
          <label for="">Advertiser Industry <sup class="text-primary">*</sup>
            <select name="select" class="w-full shadow-[0px_4px_8px_rgba(0,0,0,.15)] p-3 mt-2"
              v-model="new_advertiser.industry">
              <option value="">- Select -</option>
              <option value="Arts &amp; Entertainment">Arts &amp; Entertainment</option>
              <option value="Automotive" selected="selected" data-i="0">Automotive</option>
              <option value="Business">Business</option>
              <option value="Careers">Careers</option>
              <option value="Education">Education</option>
              <option value="Family &amp; Parenting">Family &amp; Parenting</option>
              <option value="Food &amp; Drink">Food &amp; Drink</option>
              <option value="Health &amp; Fitness">Health &amp; Fitness</option>
              <option value="Hobbies &amp; Interests">Hobbies &amp; Interests</option>
              <option value="Home &amp; Garden">Home &amp; Garden</option>
              <option value="News">News</option>
              <option value="Personal Finance">Personal Finance</option>
              <option value="Pets">Pets</option>
              <option value="Real Estate">Real Estate</option>
              <option value="Religion &amp; Spirituality">Religion &amp; Spirituality</option>
              <option value="Science">Science</option>
              <option value="Shopping">Shopping</option>
              <option value="Society">Society</option>
              <option value="Sports">Sports</option>
              <option value="Style &amp; Fashion">Style &amp; Fashion</option>
              <option value="Technology &amp; Computing">Technology &amp; Computing</option>
              <option value="Travel">Travel</option>
              <option value="Other">Other</option>
            </select></label>
        </div>
        <div class="lg:col-span-2 text-center">
          <button type="submit" class="bg-primary text-white uppercase px-8 py-3 w-full lg:w-auto mt-2"
            @click.prevent="add_advertiser">
            Create Advertiser
          </button>
        </div>
      </form>
    </div>

    <div class="success" v-if="showSuccessMessage">Your changes have been saved. <button class="close" @click.prevent="showSuccessMessage=0"><i class="fa-sharp fa-solid fa-xmark"></i></button></div>

  </div>


  <!-- MODAL DELETE USER -->
  <div id="modal-delete-user" class="fixed top-0 left-0 w-full h-full bg-black/80 z-20 "  :class="{ hidden: !showDeleteModal }">
    <div
      class="max-w-3xl w-full px-4 py-10 lg:p-8 xl:p-10 bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-screen overflow-x-hidden overflow-y-auto">
      <button class="absolute top-4 right-4 w-8 h-8 bg-white fill-black p-2 rounded-full mb-4 shadow"
        onclick="getElementById('modal-delete-user').classList.toggle('hidden'); document.body.classList.toggle('overflow-hidden');">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-full h-full object-contain">
          <path
            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </button>
      <h2 class="text-center mb-4 text-xl lg:text-3xl mt-10 font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-14 inline-block mr-4">
          <path
            d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
        </svg>
        Are you sure you want to delete this advertiser?
      </h2>
      <div class="flex justify-end relative mt-4 lg:mt-0 -bottom-4 lg:-bottom-10 lg:-right-10">
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto" @click.prevent="clearCurrentAdvertiser; showDeleteModal = 0">
          Nevermind
        </button>
        <button class="px-6 lg:px-8 py-3 w-1/2 md:w-auto bg-primary text-white uppercase" @click.prevent="deleteAdvertiser(eAdvertiser); showDeleteModal = 0">
          Delete
        </button>
      </div>
    </div>
  </div>
  <!-- END OF MODAL DELETE USER -->
</template>
<style></style>