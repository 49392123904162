<script setup>
import { ref, onMounted, computed } from "vue";
import { api } from '../../helpers';

const items      = ref([]);
const search     = ref('');
const sortFlag   = ref(true);

const currentAdvertiserObj = JSON.parse(localStorage.getItem('currentAdvertiser'));

const get_items = async () => {
  document.body.classList.add("loading");
    const response  = await api.get('/get_orders?advertiser_id=1');
    items.value     = response.data.data;
  document.body.classList.remove("loading");
};

const filteredItems = computed(() => {
  return items.value.filter((val) => {
    return val.name.toString().toLowerCase().includes(search.value)
  });
});

const sortList = (sortBy)=> {
    if (sortFlag.value) {
      items.value.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
      sortFlag.value = false;
    }else{
      items.value.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
      sortFlag.value = true;
    } 
};

onMounted(async () => {
  get_items();
  if (currentAdvertiserObj) {
    
  } else {

  }
});
</script>

<template>
  <div id="billing-tabs" class="tabs">
    <div class="tab-triggers-container">
      <nav class="tab-triggers-nav">
        <a href="#" data-target="transaction-history" aria-selected="true" class="tab-trigger">
          Transaction History
        </a>
        <RouterLink to="/payment-methods" class="tab-trigger">
          Payment Methods
        </RouterLink>
        <RouterLink to="/billing-profiles" class="tab-trigger">
          Billing Profiles
        </RouterLink>
      </nav>
    </div>
    <div id="transaction-history" aria-expanded="true" class=" aria-expanded:block">
      <div class="mb-4 flex gap-4 items-center">
        <div class="pr-2">
         
        </div>
        <label for="" class="grow max-w-[200px]">
          <select name="select" class="w-full">
            <option value="all">STATUS: All</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
          </select>
        </label>
        <label for="" class="flex grow items-center">
          <span class="font-bold mr-2 min-w-max">Filter by Campaign</span>
          <select name="select" class="grow mt-0">
            <option value="all">
              All Campaigns
            </option>
          </select>
        </label>
      </div>
      <div class="table-container">
        <table>
          <tr class="thead-tr">
            <th class="w-1/12">
              Transaction ID <button class="sort" @click="sortList('id')"
                onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-4/12">
              Transaction Date <button class="sort"  @click="sortList('_paid_date')"
                onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"><i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-4/12">
              Payment Date <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"  @click="sortList('_paid_date')">
                <i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-3/12">
              PO Number
            </th>
            <th class="w-3/12">
              Status <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"   >
                <i
                  class="fa-duotone fa-sort"></i></button>
            </th>
            <th class="w-3/12">
              Total <button class="sort" onclick="this.querySelector('i').classList.toggle('fa-flip-vertical');"  @click="sortList('_order_total')">
                <i
                  class="fa-duotone fa-sort"></i></button>
            </th>
          </tr>
          <tr class="tbody-tr"  v-for="item in items" :key="item.id">
            <td class="">
              #{{ item.id }}
            </td>
            <td class="">
            {{item._paid_date}}
            </td>
            <td class="">
              {{item._paid_date}}
            </td>
            <td class="">
              {{item.new_po_number}}
            </td>
            <td class="">
              Paid
            </td>
            <td class="">
             ${{ item._order_total}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    
  </div>
 
</template>
<style></style>