import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_CUSTOM_API,
  params: {
    t: new Date().getTime()
  }
});

const wpApi = axios.create({
  baseURL: process.env.VUE_APP_WP_API,
});

// If logged in, add Authentication Header to the API call
/*
const token = localStorage.getItem('userToken');

if (token) {
 
  const addTokenHeader = (config) => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  };

  api.interceptors.request.use(addTokenHeader);
  wpApi.interceptors.request.use(addTokenHeader);
}
*/
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('userToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

wpApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem('userToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export {
  api,
  wpApi,
};

export default api;
